<template>
  <app-card
    :footer="true"
    custom-classes="chat-widget-wrap"
  >
    <div class="chat-widget">
      <vue-perfect-scrollbar
        id="chat-scroll"
        :settings="settings"
        style="max-height:275px">
        <div class="chat-body pa-4">
          <template v-for="(message, index) in messagesData">
            <div
              :key="index"
              :class="{'flex-row-reverse': isChatRowReverse(message, index), 'new-message': firstUnreadMessage && firstUnreadMessage.id === message.id }"
              class="chat-block mb-2">
              <div
                v-if="firstUnreadMessage && firstUnreadMessage.id === message.id"
                class="new-message-badge">Nieuw</div>
              <template v-if="isShowBuble(message)">
                <div class="chat-bubble-wrap">
                  <span class="fs-14 fw-normal grey--text text-xs-right d-block mb-1">{{ message.createdBy.name }}</span>
                  <div class="fs-14 chat-bubble odd grey lighten-4">{{ message.body }}</div>
                  <span class="fs-12 grey--text text-xs-left d-block mt-1 fw-normal">{{ moment(message.createdAt).format('lll') }}</span>
                </div>
              </template>
              <template v-else>
                <div class="mr-3 mt-3 pos-relative">
                  <img
                    v-if="message.createdBy.avatar && message.createdBy.avatar.url && isAvatarValid"
                    :src="message.createdBy.avatar && message.createdBy.avatar.url"
                    width="40"
                    height="40"
                    class="rounded-circle"
                    @error="imageUrlAlt"
                  >
                  <img
                    v-else
                    :src="placeHolderAvatar"
                    width="40"
                    height="40"
                    class="rounded-circle"
                  >
                  <!-- ONLINE BADGE -->
                  <!-- <span class="v-badge success rounded floating"/> -->
                </div>
                <div class="chat-bubble-wrap">
                  <span class="fs-14 grey--text d-block mb-1 fw-normal">{{ message.createdBy.name }}</span>
                  <div class="fs-14 chat-bubble even primary lighten-4">{{ message.body }}</div>
                  <span class="fs-12 grey--text text-xs-right d-block mt-1 fw-normal">{{ moment(message.createdAt).format('lll') }}</span>
                </div>
                <v-btn
                  v-if="$store.getters.getEnv.envVersion.siteVersion === 'ROLE_ADMIN'"
                  :disabled="loadingRemoveMessage"
                  class="delete-mesage-btn"
                  flat
                  icon
                  color="red"
                  @click="removeChatMessageClick(message.id)">
                  <v-icon size="20">remove_circle</v-icon>
                </v-btn>
              </template>
            </div>
          </template>
          <div
            v-if="typing"
            class="chat-block mb-2">
            <img
              src="/static/avatars/user-14.jpg"
              alt="user-profile"
              width="40"
              height="40"
              class="rounded-circle mr-3 mt-3">
            <div class="chat-bubble even primary lighten-4 animated bounce">typing....</div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <div
      v-if="canSendMessage"
      slot="footer"
      class="send-message-box">
      <div class="d-custom-flex">
        <v-text-field
          id="sendMessage"
          v-model="newMessage"
          name="send-message"
          solo
          class="fs-16"
          label="Bericht toevoegen"
          @keyup.enter="sendMessage"
        />
        <v-btn
          :disabled="loading"
          dark
          class="ml-3"
          large
          color="pink"
          @click="sendMessage">
          <v-icon color="white">fas fa-paper-plane</v-icon>
        </v-btn>
      </div>
    </div>
  </app-card>
</template>

<script>
import moment from 'moment'
import Request from '../../helpers/request'

export default {
  mixins: [Request],
  props: {
    chatId: {
      type: Number || null,
      default: () => { return null }
    },
    rideId: {
      type: Number || null,
      default: () => { return null }
    },
    canSendMessage: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data () {
    return {
      isAvatarValid: true,
      placeHolderAvatar: require('../../assets/images/profile-male@2x.png'),
      loading: false,
      loadingRemoveMessage: false,
      moment,
      messagesData: [],
      settings: {
        maxScrollbarLength: 160
      },
      newMessage: '',
      typing: false,
      firstUnreadMessage: null
    }
  },
  updated () {
    if (this.newMessage === '') {
      this.scrollToEnd()
    }
  },

  async mounted () {
    this.loadData()

    this.interval = setInterval(function () {
      this.loadData()
    }.bind(this), 15000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    isChatRowReverse (message, index) {
      return !(index === 0 || message.createdBy.id === this.messagesData[0].createdBy.id)
    },
    isShowBuble (message) {
      return parseInt(message.createdBy.id) === parseInt(localStorage.getItem('dmSecretaryId')) || parseInt(message.createdBy.id) === this.$store.getters.getUser.id
    },
    imageUrlAlt () {
      this.isAvatarValid = false
    },
    getFirstUnreadMessage (messages) {
      return messages.find(message => message.unread)
    },
    async loadData () {
      if (this.chatId) {
        try {
          const { data } = await this.getChatMessages(this.chatId)

          if (data) {
            this.messagesData = data.reverse()

            if (data.length) {
              this.firstUnreadMessage = this.getFirstUnreadMessage(data)
            }
          }
        } catch (error) {
          console.log('🚀 ~ file: RideCard.vue ~ line 401 ~ mounted ~ error', error)
        }
      }
    },
    getChatMessages (chatId) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/chats/${chatId}/messages`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    postChatMessages (chatId) {
      const body = {
        data: {
          body: this.newMessage
        }
      }

      return new Promise((resolve, reject) => {
        this.request('POST', `/chats/${chatId}/messages`, body, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    removeChatMessage (messageId) {
      return new Promise((resolve, reject) => {
        this.request('DELETE', `/chats/${this.chatId}/messages/${messageId}`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    async removeChatMessageClick (messageId) {
      try {
        this.loadingRemoveMessage = true

        await this.removeChatMessage(messageId)
        await this.loadData()
        this.loadingRemoveMessage = false
      } catch (error) {
        this.loadingRemoveMessage = false
        console.log(error)
      }
    },
    createChat () {
      return new Promise((resolve, reject) => {
        this.request('POST', `/chats/ride/${this.rideId}`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    async sendMessage () {
      if (this.newMessage !== '') {
        this.loading = true
        let _chatId = this.chatId || null

        try {
          if (!_chatId) {
            const chat = await this.createChat()

            _chatId = chat.id
            this.chatId = chat.id
          }

          await this.postChatMessages(_chatId)

          const { data } = await this.getChatMessages(_chatId)

          this.messagesData = data.reverse()

          this.firstUnreadMessage = this.getFirstUnreadMessage(data)

          this.newMessage = ''
          this.scrollToEnd()
          this.loading = false
        } catch (error) {
          this.loading = false
        }
      }
    },
    scrollToEnd () {
      var container = document.getElementById('chat-scroll')
      if (container !== null) {
        var scrollHeight = container.scrollHeight
        container.scrollTop = scrollHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .new-message {
    position: relative;
    border-top: 1px solid red;
    margin-top: 25px;
    padding-top: 25px;
  }

  .new-message-badge {
    color: red;
    position: absolute;
    top: -12px;
    right: 0;
    background-color: #ffffff;
    width: 67px;
    padding-left: 14px;
    font-size: 14px;
  }

  .delete-mesage-btn {
    align-self: center;
  }
</style>
