<template>
  <div>
    <notifications
      group="rides"
      position="top right"
      animation-type="velocity"
    />

    <ride-overview-table
      v-if="brandList.length"
      :loading="loading"
      :statuses-list="statusesList"
      :brand-list="brandList"
      :region-list="regionList"
      :rides-list="ridesList"
      :get-rides-with-filters="getRidesWithFilters"
      :total-rides="totalRides"
      :show-loading="showLoading"
      :filter-props="filterProps"
      @openEditDialog="(val, copy) => openEditDialogClick(val, copy)"
      @openDeleteDialog="val => openDeleteDialog(val)"
      @restoreRide="val => restoreRide(val)"
      @createRide="createRide()"

      @updateFilterProps="updateFilterProps"
    />

    <delete-confirmation-dialog
      ref="deleteElectConfirmationDialog"
      heading="Are you sure?"
      message="Are you sure you want to delete this elected driver?"
      @onConfirm="deleteElectDialogConfirmation"
    />

    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Are you sure?"
      message="Are you sure you want to delete this product permanently?"
      @onConfirm="deleteDialogConfirmation"
    />

    <delete-confirmation-dialog
      ref="deleteChatConfirmationDialog"
      heading="Are you sure?"
      message="Are you sure you want to delete chat?"
      @onConfirm="deleteChatDialogConfirmation"
    />

    <delete-confirmation-dialog
      ref="deleteSubscriptionConfrimationDialog"
      heading="Are you sure?"
      message=""
      @onConfirm="deleteDialogConfirmationSubscription"
    />

    <edit-dialog
      :show.sync="editDialog.show"
      :loading="buttonLoader"
      :loader="buttonLoader"
      :is-ride-page="true"
      :is-confirm-cancel="isConfirmCancel"
      :readonly="editDialog.data.hasOwnProperty('id')"
      custom-class="ride-dialog"
      max-width="100%"
      @dialogConfirm="() => { editDialog.saveFunctionModal() }"
      @dialogClose="editDialog.closeFunction"
      @dialogCloseSave="() => { editDialog.saveFunctionModal(true) }">
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="copy-icon"
              v-on="on"
              @click="openEditDialogClick(editDialog.data, true)"
            >
              file_copy
            </v-icon>
          </template>
          <span>Copy</span>
        </v-tooltip>
        <v-container
          grid-list-xl
          class="ride-container">
          <v-layout
            row
            wrap>
            <v-flex
              md4
              class="box-card">
              <div class="field-wrapper">
                <div class="custom-label black--text">Main</div>
                <v-layout
                  row
                  wrap>
                  <v-flex xs6>
                    <v-select
                      :items="brandList"
                      v-model="editDialog.data.brand"
                      item-value="id"
                      item-text="name"
                      label="Select brand"
                      outline
                      hide-details
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    class="pos-relative">
                    <passengers-input
                      :disabled="!editDialog.data.brand || passengersInputDisabled"
                      v-model="editDialog.data.passengerId"
                      :brand-list="selectedBrandIdArr"
                      outline
                      hide-details
                      @changePassenger="(inputPassenger) => { getPassengerData(inputPassenger.id) }"/>
                    <div
                      v-if="editDialog.data.passengerId"
                      class="link-holder">
                      <router-link :to="`/tables/passengers/${editDialog.data.passengerId}`" >
                        <v-icon
                          size="40"
                          color="primary">
                          link
                        </v-icon>
                      </router-link>
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="editDialog.data.carPassengerName"
                      label="Car customer name"
                      outline
                      hide-details
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="editDialog.data.carPassengerPhone"
                      label="Car customer phone"
                      outline
                      hide-details
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editDialog.data.regions"
                      :items="regionList"
                      :item-text="(val) => { return `${val.name} id: ${val.id}`}"
                      multiple
                      outline
                      hide-details
                      label="Region"
                      item-value="id"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <tags-select
                      v-if="editDialog.data.tags && editDialog.show"
                      :selected-tags="editDialog.data.tags.map(tag => tag.id || tag)"
                      :on-change-selected="onChangeSelectedTags"/>
                  </v-flex>
                  <v-flex xs6>
                    <div>
                      <v-select
                        :items="statusesList"
                        :disabled="editDialog.data.statusSequence > getStatusByName('declaration_approved').sequence"
                        v-model="editDialog.data.status"
                        item-value="id"
                        item-text="title"
                        label="Select status"
                        outline
                        hide-details
                        @change="handleChangeStatus"
                      />
                      <v-autocomplete
                        :disabled="!editDialog.data.brand || disabledDriverType"
                        v-model="editDialog.data.driverType"
                        :items="typesList"
                        :item-text="(val) => { return val.title + ' id: ' + val.id }"
                        label="Type id"
                        item-value="id"
                        class="my-3"
                        outline
                        hide-details
                        clearable
                        @change="onChangeDriverType"
                      />
                      <v-text-field
                        v-if="isEdrivers"
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        v-model="editDialog.data.poNumber"
                        label="PO number"
                        disabled
                        outline
                        hide-details
                      />
                      <v-text-field
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        v-model="editDialog.data.declaredAt"
                        class="my-3"
                        label="Declared At"
                        outline
                        type="local-time"
                        readonly
                        disabled
                        hide-details
                      />
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div>
                      <v-text-field
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        :class="editDialog.data.promoCode && 'bg-input-red'"
                        v-model="editDialog.data.promoCode"
                        label="Promo Code"
                        outline
                        hide-details
                      />
                      <InputHotKeyWrapper>
                        <v-text-field
                          :max-length="TEXT_FIELD_MAX_LENGTH"
                          v-model="editDialog.data.promoAmount"
                          class="my-3"
                          label="Promo amount"
                          type="number"
                          outline
                          hide-details
                          @change="onChangePromoAmount"
                        />
                      </InputHotKeyWrapper>
                      <v-autocomplete
                        v-if="isRentabob"
                        :disabled="!editDialog.data.brand"
                        v-model="editDialog.data.advanceBookingDiscount"
                        :items="discountsList"
                        :item-text="(val) => { return val.percents + '%' }"
                        :class="`my-3 ${editDialog.data.advanceBookingDiscount && 'bg-input-green'}`"
                        label="Discount"
                        item-value="id"
                        outline
                        hide-details
                        clearable
                        @change="onChangeDiscount"
                      />
                      <v-checkbox
                        :disabled="!isDriveme && !isRentabob"
                        v-model="insuranceEnabled"
                        class="mt-0"
                        color="pink"
                        label="Insurance"
                        hide-details
                        @change="initInsuranceQuoted"
                      />
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-btn
                      v-if="editDialog.data.status === getStatusByName('draft').id"
                      :loading="nextStatusLoader"
                      color="pink"
                      class="text-white"
                      @click="setStatusToNew"
                    >
                      To New
                    </v-btn>
                    <v-btn
                      v-if="editDialog.data.status && showNextStatusButton && editDialog.data.status !== getStatusByName('draft').id"
                      :disabled="isNextStatus || editDialog.data.statusSequence > getStatusByName('declaration_approved').sequence"
                      :loading="nextStatusLoader"
                      color="pink"
                      class="text-white"
                      @click="nextStatus(true)"
                    >
                      To {{ nextStatusAlias }}
                    </v-btn>
                    <v-btn
                      v-if="editDialog.data.id && editDialog.data.status !== getStatusByName('retracted').id"
                      :loading="nextStatusLoader"
                      color="pink"
                      class="text-white"
                      @click="setStatusToRetract"
                    >
                      To Rectracted
                    </v-btn>
                  </v-flex>
                  <!-- <v-flex xs6>
                    <v-btn
                      color="primary"
                      @click="() => { editDialog.saveFunctionModal() }"
                    >
                      Save
                    </v-btn>
                  </v-flex> -->
                </v-layout>
              </div>
              <div class="field-wrapper">
                <div class="custom-label black--text">Notes</div>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    md6
                  >
                    <v-textarea
                      v-model="editDialog.data.remark"
                      label="Remark"
                      hide-details
                      outline
                    />
                  </v-flex>
                  <v-flex
                    md6
                  >
                    <v-textarea
                      v-model="editDialog.data.adminNote"
                      label="Admin note"
                      hide-details
                      outline
                    />
                  </v-flex>
                </v-layout>
                <div
                  v-if="rideNotes.length > 0 || passengerNotes.length > 0"
                  class="field-wrapper">
                  <div class="sort-direction">
                    <v-select
                      :items="sortDirectionList"
                      v-model="sortDirection"
                      label="Sort by direction"
                      outline
                      hide-details
                      @change="loadNotes"
                    />
                  </div>
                  <div
                    v-if="loadingNote"
                    class="loader-holder">
                    <rotate-square2 />
                  </div>
                  <v-layout
                    v-else
                    row
                    wrap>
                    <v-flex xs12>
                      <div class="driver-note-wrapper my-4">
                        <v-layout>
                          <v-flex
                            v-if="passengerNotes.length > 0"
                            md6>
                            <div>
                              <h5 class="mb-3">Note about customer</h5>
                              <div
                                v-for="(note, key) in passengerNotes"
                                :key="key"
                                md6>
                                <div class="custom-label black--text mb-1">Driver: {{ note.user.name }}</div>
                                <div class="driver-note-holder">
                                  <div class="note-header">
                                    <small class="black--text"><em>{{ momentDateTime(note.createdAt) }}</em></small>
                                    <div>
                                      <v-btn
                                        class="m-0"
                                        flat
                                        icon
                                        @click="openEditNoteDialog(note)"
                                      >
                                        <v-icon
                                          color="black"
                                          size="20">
                                          edit
                                        </v-icon>
                                      </v-btn>
                                      <v-btn
                                        class="m-0"
                                        flat
                                        icon
                                        @click="openRemoveNoteDialog(note.id)"
                                      >
                                        <v-icon
                                          color="pink"
                                          size="20">
                                          mdi-delete
                                        </v-icon>
                                      </v-btn>
                                    </div>
                                  </div>
                                  <p class="my-0 black--text">{{ note.text }}</p>
                                </div>
                              </div>
                            </div>
                            <div class="pagination-holder">
                              <v-pagination
                                v-model="passengerNotePage"
                                :length="passengerNoteTotalPage"
                                total-visible="2"
                                color="pink"
                                class="mb-4 mt-2"
                                @input="loadPassengerNotes"
                              />
                            </div>
                          </v-flex>
                          <v-flex
                            v-if="rideNotes.length > 0"
                            md6>
                            <div>
                              <h5 class="mb-3">Note about ride</h5>
                              <div
                                v-for="(note, key) in rideNotes"
                                :key="key"
                                md6>
                                <div class="custom-label black--text mb-1">Driver: {{ note.user.name }}</div>
                                <div class="driver-note-holder">
                                  <div class="note-header">
                                    <small class="black--text"><em>{{ momentDateTime(note.createdAt) }}</em></small>
                                    <div>
                                      <v-btn
                                        class="m-0"
                                        flat
                                        icon
                                        @click="openEditNoteDialog(note)"
                                      >
                                        <v-icon
                                          color="black"
                                          size="20">
                                          edit
                                        </v-icon>
                                      </v-btn>
                                      <v-btn
                                        class="m-0"
                                        flat
                                        icon
                                        @click="openRemoveNoteDialog(note.id)"
                                      >
                                        <v-icon
                                          color="pink"
                                          size="20">
                                          mdi-delete
                                        </v-icon>
                                      </v-btn>
                                    </div>
                                  </div>
                                  <p class="my-0 black--text">{{ note.text }}</p>
                                </div>
                              </div>
                            </div>
                            <div class="pagination-holder">
                              <v-pagination
                                v-model="driverNotePage"
                                :length="driverNoteTotalPage"
                                total-visible="2"
                                color="pink"
                                class="mb-4 mt-2"
                                @input="loadRideNotes"
                              />
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <v-layout
                  row
                  wrap>
                  <v-flex md6>
                    <v-textarea
                      v-model="editDialog.data.passengerNote"
                      :disabled="!editDialog.data.passengerId"
                      label="Note about customer"
                      hide-details
                      outline
                    />
                  </v-flex>
                  <v-flex md6>
                    <v-textarea
                      v-model="editDialog.data.rideNote"
                      label="Note about ride"
                      hide-details
                      outline
                    />
                  </v-flex>
                </v-layout>
                <div class="chat-holder">
                  <chat
                    v-if="editDialog.data.chat"
                    :chat-id="editDialog.data.chat && editDialog.data.chat.id"
                    :can-send-message="false"
                    class="mt-4"
                  />
                  <v-btn
                    v-if="editDialog.data.chat"
                    :loading="buttonLoader"
                    color="warning"
                    class="mr-3"
                    @click="openDeleteChatDialog(editDialog.data.id)"
                  >
                    Delete Chat
                  </v-btn>
                </div>
              </div>
              <div
                v-if="editDialog.data.passengerId || (editDialog.data.passenger && editDialog.data.passenger.options && subscribedDrivers.length > 0)"
                class="field-wrapper">
                <div class="custom-label black--text">Ride customer details</div>
                <v-layout v-if="editDialog.data.passengerId && cars.length > 0">
                  <v-flex xs12>
                    <v-select
                      v-model="editDialog.data.car"
                      :items="cars"
                      label="Select car"
                      persistent-hint
                      return-object
                      single-line
                      outline
                      hide-details
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        <div class="custom-select-value">
                          <div>{{ data.item.licensePlate }} {{ data.item.brand }} {{ data.item.model }}</div>
                        </div>
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        <div class="custom-select-value">
                          <div>{{ data.item.licensePlate }} {{ data.item.brand }} {{ data.item.model }}</div>
                        </div>
                      </template>
                      <template v-slot:append>
                        <v-icon color="black">expand_more</v-icon>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout
                  v-if="editDialog.data.passenger && editDialog.data.passenger.options && subscribedDrivers.length > 0"
                  row
                  wrap
                >
                  <v-flex
                    sm6>
                    <v-select
                      :items="carPositionList"
                      v-model="editDialog.data.passenger.options.position"
                      label="Position in car"
                      hide-details
                      outline
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-select
                      :items="driverDressList"
                      v-model="editDialog.data.passenger.options.driverDress"
                      label="Driver dress"
                      hide-details
                      outline
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md3
                  >
                    <group-switch
                      :value="editDialog.data.passenger.options.carryLuggage"
                      class="custom-choice-group-holder"
                      label="Carry luggage"
                      @onChange="onCarryLuggageChange" />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md3
                  >
                    <group-switch
                      :value="editDialog.data.passenger.options.informalLanguage"
                      class="custom-choice-group-holder"
                      label="Informal language"
                      @onChange="onInformalLanguageChange" />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md3
                  >
                    <group-switch
                      :value="editDialog.data.passenger.options.keepDoorsOpen"
                      class="custom-choice-group-holder"
                      label="Keep doors open"
                      @onChange="onKeepDoorsOpenChange" />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md3
                  >
                    <group-switch
                      :value="editDialog.data.passenger.options.playMusic"
                      class="custom-choice-group-holder"
                      label="Play music"
                      @onChange="onPlayMusicChange" />
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-flex
              md8
              class="box-card">
              <div class="field-wrapper">
                <div class="custom-label black--text">Route</div>
                <v-layout
                  class="justify-center"
                  row
                  wrap>
                  <v-flex
                    class="pos-relative first-field"
                    xs4
                  >
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="editDialog.data.startAt"
                      step="300"
                      min="2018-06-07T00:00"
                      type="datetime-local"
                      max="9999-12-31 23:59"
                      class="text-field-mobile"
                      label="Start at:"
                      outline
                      hide-details
                      @change="onChangeStartAt"
                    />
                    <div
                      v-if="editDialog.data.startAt"
                      class="action-holder right-pos start-arrow">
                      <v-btn
                        icon
                        class="btn-time-right"
                        @click="formatStartStopArrive"
                      >
                        <v-icon
                          color="black"
                          size="40">
                          arrow_drop_down
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  class="align-items-end">
                  <v-flex
                    class="pb-0"
                    xs4>
                    <stops-timeline
                      :stops="editDialog.stopsData"
                      :brand-name="selectedBrandName"
                      class="ride-stops-holder"
                      type="admin"
                      @onChange="onChangeStopsTimeline" />
                  </v-flex>
                  <v-flex
                    class="duration-wrapper pb-0"
                    xs8>
                    <div
                      v-for="(stop, key) in editDialog.stopsData"
                      v-if="isAdmin && stop.address"
                      :key="key"
                      class="duration-holder">
                      <div class="pos-relative duration-item">
                        <div
                          v-if="!editDialog.stopsData.arriveAt && editDialog.stopsData[0].arriveAt && key === 0"
                          class="action-holder arrive-pos">
                          <v-btn
                            icon
                            class="btn-time-left"
                            @click="setArriveTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_drop_up
                            </v-icon>
                          </v-btn>
                        </div>
                        <v-text-field
                          v-if="stop.address"
                          v-model="stop.arriveAt"
                          step="300"
                          type="datetime-local"
                          max="9999-12-31 23:59"
                          class="text-field-mobile custom-date-field"
                          label="Arrive at:"
                          outline
                          hide-details
                          @change="() => { if (key === editDialog.stopsData.length - 1) initEndRideWithPassengerQuoted() }"
                        />
                        <div
                          v-if="stop.arriveAt"
                          class="action-holder next-arrive h-auto">
                          <v-btn
                            icon
                            @click="key === (editDialog.stopsData.length - 1) ? setEndTimeFromArrive(key) : formatNextArriveTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_drop_down
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="action-holder middle-action">
                        <div
                          v-if="stop.prebookedWaitingTime && stop.departAt"
                          class="action-item">
                          <v-btn
                            icon
                            @click="formatArriveTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_left
                            </v-icon>
                          </v-btn>
                        </div>
                        <div
                          v-if="key !== 0 && stop.arriveAt && stop.departAt && key < editDialog.stopsData.length - 1"
                          class="action-item">
                          <v-btn
                            icon
                            class="btn-time-right"
                            @click="formatWaitingTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_right
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="pos-relative duration-item waiting-time-holder">
                        <InputHotKeyWrapper v-if="key > 0 && key < editDialog.stopsData.length - 1">
                          <v-text-field
                            v-model="stop.prebookedWaitingTime"
                            label="Waiting time (minutes):"
                            type="number"
                            outline
                            hide-details
                          />
                        </InputHotKeyWrapper>
                        <v-select
                          v-else-if="key < editDialog.stopsData.length - 1"
                          :items="prebookedWaitingTimeList"
                          v-model="editDialog.data.prebookedWaitingTime"
                          item-value="id"
                          item-text="name"
                          label="Select waiting time"
                          outline
                          hide-details
                          @change="updateWaitingTime"
                        />
                        <div
                          v-if="editDialog.stopsData[key] && editDialog.stopsData[key + 1]"
                          class="action-holder-travel-time h-auto">
                          <div class="action-item">
                            <v-btn
                              icon
                              @click="getTravelTime(key)"
                            >
                              <v-icon
                                color="pink"
                                size="20">
                                auto_fix_normal
                              </v-icon>
                            </v-btn>
                          </div>
                          <div>
                            <div
                              v-if="editDialog.stopsData[key].travelTime"
                              class="travel-time-value">
                              Travel time:
                              {{ editDialog.stopsData[key].minTravelTime }}
                              -
                              {{ editDialog.stopsData[key].maxTravelTime }}
                              minutes
                            </div>
                            <div
                              v-if="editDialog.stopsData[key].travelTime"
                              class="travel-time-value">
                              Distance:
                              {{ editDialog.stopsData[key].minDistance }}
                              <span v-if="!lodash.isEqual(editDialog.stopsData[key].minDistance, editDialog.stopsData[key].maxDistance)">
                                - {{ editDialog.stopsData[key].maxDistance }}
                              </span>
                              km
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="action-holder middle-action">
                        <div
                          v-if="key !== 0 && stop.arriveAt && stop.departAt && key < editDialog.stopsData.length - 1"
                          class="action-item">
                          <v-btn
                            icon
                            class="btn-time-left"
                            @click="formatWaitingTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_left
                            </v-icon>
                          </v-btn>
                        </div>
                        <div
                          v-if="(stop.prebookedWaitingTime || editDialog.data.prebookedWaitingTime) && stop.arriveAt"
                          class="action-item">
                          <v-btn
                            icon
                            @click="formatDepartTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_right
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="pos-relative duration-item">
                        <div class="action-holder depart-pos">
                          <v-btn
                            v-if="stop.arriveAt && key && editDialog.stopsData[key - 1].travelTime"
                            icon
                            class="btn-time-left"
                            @click="setDepartTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_drop_up
                            </v-icon>
                          </v-btn>
                        </div>
                        <v-text-field
                          v-if="stop.address && key < editDialog.stopsData.length - 1"
                          v-model="stop.departAt"
                          step="300"
                          type="datetime-local"
                          max="9999-12-31 23:59"
                          class="text-field-mobile custom-date-field"
                          label="Depart at:"
                          outline
                          hide-details
                          @change="() => { if (key === 0) initStartRideWithPassengerQuoted() }"
                        />
                        <div
                          v-if="stop.address && key < editDialog.stopsData.length - 1"
                          class="action-holder down-depart">
                          <v-btn
                            v-if="stop.departAt && key === (editDialog.stopsData.length - 1)"
                            icon
                            class="btn-time-left"
                            @click="setEndTime(key)"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_drop_down
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <v-layout class="justify-end end-field">
                      <v-flex
                        class="pos-relative pt-0"
                        xs6>
                        <div
                          v-if="editDialog.data.endAt"
                          class="action-holder last-action">
                          <v-btn
                            icon
                            class="btn-time-right"
                            @click="formatEndStopDepart"
                          >
                            <v-icon
                              color="black"
                              size="40">
                              arrow_drop_up
                            </v-icon>
                          </v-btn>
                        </div>
                        <div>
                          <div class="add-hour-buttons">
                            <v-btn
                              v-for="(item, key) in [1, 2, 3, 4, 5, 6, 7, 12]"
                              :key="key"
                              color="green"
                              dark
                              @click="() => { addHourEndAt(item) }"
                            >
                              +{{ item }}
                            </v-btn>
                          </div>
                          <v-text-field
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.endAt"
                            step="300"
                            min="2018-06-07T00:00"
                            type="datetime-local"
                            max="9999-12-31 23:59"
                            class="text-field-mobile"
                            label="End at:"
                            outline
                            hide-details
                            @change="onChangeEndAt"
                          />
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
              <div
                class="field-wrapper">
                <div class="custom-label black--text">Driver</div>
                <v-layout v-if="editDialog.data.driverId">
                  <v-flex
                    xs12
                    class="pos-relative">
                    <div class="row-layout align-items-center">
                      <drivers-input
                        :disabled="!editDialog.data.brand"
                        :brand-list="[editDialog.data.brand]"
                        v-model="editDialog.data.driverId"
                        :query-params="{ params: { sort: 'firstName', sortDir: 'asc' } }"
                        hide-details
                        outline
                        label="Driver"/>
                      <v-btn
                        icon
                        fab
                        dark
                        small
                        color="error"
                        @click="openElectDeleteDialog">
                        <v-icon dark>
                          remove
                        </v-icon>
                      </v-btn>
                    </div>
                    <div
                      v-if="editDialog.data.driverId"
                      class="link-holder">
                      <router-link :to="`/tables/drivers/${editDialog.data.driverId}`" >
                        <v-icon
                          size="40"
                          color="primary">
                          link
                        </v-icon>
                      </router-link>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else
                  row
                  wrap>
                  <v-flex
                    md7>
                    <drivers-input
                      v-if="(!editDialog.data.status || [getStatusByName('draft').id].includes(editDialog.data.status)) && (!editDialog.data.startAt || !editDialog.data.endAt || editDialog.stopsData.length < 2 || !editDialog.stopsData[0].address || !editDialog.stopsData[editDialog.stopsData.length - 1].address)"
                      :disabled="loading"
                      :exclude-ids="subscribedDriverIds"
                      :brand-list="[editDialog.data.brand]"
                      :query-params="{ params: { sort: 'firstName', sortDir: 'asc' } }"
                      hide-details
                      outline
                      label="Drivers"
                      @changeDriver="(inputDriver) => { editDialog.subscribeDriverIds = inputDriver.id }"/>
                    <drivers-input
                      v-else
                      :disabled="loading"
                      :exclude-ids="subscribedDriverIds"
                      :brand-list="[editDialog.data.brand]"
                      :query-params="{ params: { sort: 'firstName', sortDir: 'asc' } }"
                      hide-details
                      outline
                      multiple
                      label="Drivers"
                      @changeDriver="(inputDrivers) => { editDialog.subscribeDriverIds = inputDrivers.map(item => item.id) }"/>
                  </v-flex>
                  <v-flex
                    v-if="[getStatusByName('subscribed').id, getStatusByName('new').id].includes(editDialog.data.status)"
                    md5
                    class="d-flex">
                    <v-btn
                      :loading="subscribeDriverButtonLoader"
                      color="pink"
                      class="mr-3 subscribe-drivers"
                      dark
                      @click="onSubscribeDriver(editDialog.data.id, editDialog.subscribeDriverIds)"
                    >
                      Subscribe
                    </v-btn>

                    <drivers-subscribed
                      :region-list="regionList"
                      :regions-filter-parent="editDialog.data.regions"
                      :headers="headersDriversSubscribedDialog"
                      :platform-cost="platformCost"
                      :public-transport="publicTransport"
                      :on-elect-driver="onElectDriverClick"
                      :ride-id="editDialog.data.id"
                      :on-subscribe-driver="onSubscribeDriver"
                      :subscribe-driver-button-loader="subscribeDriverButtonLoader"
                      :subscribed-drivers="subscribedDrivers"
                      :open-delete-dialog-subscription="openDeleteDialogSubscription"
                      :button-loader="buttonLoader"
                      :available-date-parent="editDialog.data.startAt"
                      :brands="brandList"
                      :ride-brand-id="selectedBrandId"
                    />

                  </v-flex>
                </v-layout>
                <v-layout
                  column
                  wrap>
                  <v-flex xs12>
                    <v-data-table
                      :headers="(insuranceEnabled && editDialog.data.insurance) ? headers : headers.filter(item => item.text !== 'Insurance')"
                      :items="subscribedDrivers"
                      class="subscribe-drivers-table"
                      hide-actions >
                      <template
                        slot="items"
                        slot-scope="props">
                        <td>
                          {{ props.item.driver.id }}
                        </td>
                        <td>
                          {{ props.item.confirmedAt ? 'Yes' : 'No' }}
                        </td>
                        <td>
                          <div v-if="props.item.driver.photo != null">
                            <img
                              :src="props.item.driver.photo.url"
                              class="driver-image">
                          </div>
                        </td>
                        <td>
                          <router-link
                            :to="`/tables/drivers/${props.item.driver.id}`"
                          >
                            {{ props.item.driver.name }}
                          </router-link>
                        </td>
                        <td>
                          <v-checkbox
                            class="m-0 align-center"
                            hide-details
                            v-model="props.item.isFixed"/>
                        </td>
                        <td>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="tariff[props.item.id]"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              color="darkBlue"
                              type="number"
                              class="underline-input-black ride-cost-input"
                              hide-details
                              :suffix="props.item.price.type === 'fixed' ? '(fixed price)' : ''"
                            />
                          </InputHotKeyWrapper>
                        </td>
                        <td v-if="(insuranceEnabled && editDialog.data.insurance)">
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="insurance[props.item.id]"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              class="underline-input-black"
                              color="darkBlue"
                              type="number"
                            />
                          </InputHotKeyWrapper>
                        </td>
                        <td>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="platformCost[props.item.id]"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              class="underline-input-black"
                              color="darkBlue"
                              type="number"
                            />
                          </InputHotKeyWrapper>
                        </td>
                        <td>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="publicTransport[props.item.id]"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              class="underline-input-black"
                              color="darkBlue"
                              type="number"
                            />
                          </InputHotKeyWrapper>
                        </td>
                        <td>
                          <v-btn
                            v-if="editDialog.data.statusSequence < rideStatus.elected.sequence"
                            :loading="buttonLoader"
                            @click="onElectDriverClick({ rideId: editDialog.data.id, driverId: props.item.driver.id })"
                          >
                            Elect
                          </v-btn>
                          <b v-else-if="editDialog.data.driver && props.item.driver.id === editDialog.data.driver.id">
                            Elected
                          </b>
                        </td>
                        <td v-if="editDialog.data.statusSequence < rideStatus.elected.sequence">
                          <v-btn
                            :loading="buttonLoader"
                            color="warning"
                            @click="openDeleteDialogSubscription({ rideId: editDialog.data.id, subscriptionId: props.item.id })"
                          >
                            Unsubscribe
                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                            v-if="!props.item.confirmedAt"
                            :loading="buttonLoader"
                            @click="onConfirmDriver({ rideId: editDialog.data.id, subscriptionId: props.item.id })"
                          >
                            Confirm
                          </v-btn>
                        </td>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <div class="field-wrapper">
                <div class="custom-label black--text">Finance</div>
                <div class="mb-3">
                  <table
                    v-if="rideExpenses.length"
                    class="expense-table">
                    <thead>
                      <tr>
                        <th>
                          <v-checkbox
                            class="m-0 expense-checkbox"
                            color="white"
                            hide-details
                            v-model="expensesAllApproveCheckbox"
                            @change="approveAllExpenses"/>
                        </th>
                        <th>Expenses</th>
                        <th>Driver amount</th>
                        <th>Customer amount</th>
                        <th>File</th>
                        <th/>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, key) in rideExpenses"
                        :key="key">
                        <td>
                          <v-checkbox
                            v-model="rideExpenses[key].approved"
                            hide-details
                            class="m-0 expense-checkbox"
                            color="pink"
                            @change="(value) => approveExpense(value, rideExpenses[key].id)"
                          />
                        </td>
                        <td>{{ item.type.title }}</td>
                        <td>{{ item.driverAmount }}</td>
                        <td>{{ item.passengerAmount ? item.passengerAmount : item.driverAmount }}</td>
                        <td>
                          <a
                            v-if="item.file"
                            :href="item.file.url"
                            class="download-file"
                            target="_blank">{{ item.file.originalName }}</a>
                        </td>
                        <td>
                          <v-btn
                            :disabled="loadingExpense"
                            fab
                            color="pink"
                            class="btn-expense-action"
                            @click="editExpense(item)"
                          >
                            <v-icon
                              color="white"
                              size="20">
                              edit
                            </v-icon>
                          </v-btn>
                          <v-btn
                            :disabled="loadingExpense"
                            fab
                            color="pink"
                            class="btn-expense-action"
                            @click="removeExpense(item.id)"
                          >
                            <v-icon
                              color="white"
                              size="20">
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-if="editDialog.data.id"
                    class="text-right">
                    <v-btn
                      class="btn-expense"
                      color="secondary"
                      @click="openExpenseModal">Add expense</v-btn>
                  </div>
                  <hr>
                  <div class="finance-form" v-if="editDialog.data.price && selectedBrandName">
                    <div class="pa-0 py-1">
                      <v-layout row>
                        <v-flex xs2>
                          <v-checkbox
                            v-model="isFixedPriceCheckbox"
                            class="ma-0"
                            color="secondary"
                            label="Fixed price"
                            hide-details
                            @change="onChangeIsFixedPriceCheckbox"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <div class="finance-title">Quoted</div>
                        </v-flex>
                        <v-flex xs2>
                          <div class="finance-title">Declared</div>
                        </v-flex>
                        <v-flex xs3>
                          <div class="finance-title">Driver realized</div>
                        </v-flex>
                        <v-flex xs3>
                          <div class="finance-title">Customer invoice</div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.startRideToPassenger || priceEnabled.declared.startRideToPassenger || priceEnabled.driver.startRideToPassenger || priceEnabled.passenger.startRideToPassenger">
                      <v-layout row class="bg-lightGray">
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.startRideToPassenger }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.startRideToPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.startRideToPassenger"
                                :class="`fs-14 ${priceClass.quoted.startRideToPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.startRideToPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'startRideToPassenger')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'startRideToPassenger') }"
                                @change="() => { onChangeStartRideToPassengerQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.startRideToPassenger">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.startRideToPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.startRideToPassenger"
                            :class="`fs-14 ${priceClass.declared.startRideToPassenger}`"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.startRideToPassenger"
                            single-line
                            type="datetime-local"
                            max="9999-12-31 23:59"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'startRideToPassenger')"
                            @input="(e) => { onInputPrice(e, 'declared', 'startRideToPassenger') }"
                            @change="() => { onChangeStartRideToPassengerDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.startRideToPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.driver.startRideToPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.startRideToPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'startRideToPassenger')"
                                @input="(e) => { onInputPrice(e, 'driver', 'startRideToPassenger') }"
                                @change="() => { onChangeStartRideToPassengerDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.startRideToPassenger">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.startRideToPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.startRideToPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.passenger.startRideToPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.startRideToPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'startRideToPassenger')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'startRideToPassenger') }"
                                @change="() => { onChangeStartRideToPassengerPassenger('passenger') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.startRideToPassenger">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.startRideToPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.arrivalAtPickup || priceEnabled.declared.arrivalAtPickup || priceEnabled.driver.arrivalAtPickup || priceEnabled.passenger.arrivalAtPickup">
                      <v-layout row class="bg-lightGray">
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.arrivalAtPickup }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.arrivalAtPickup" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.arrivalAtPickup"
                                :class="`fs-14 ${priceClass.quoted.arrivalAtPickup}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.arrivalAtPickup"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'arrivalAtPickup')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'arrivalAtPickup') }"
                                @change="() => { onChangeArrivalAtPickupQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.arrivalAtPickup">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.arrivalAtPickup}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.arrivalAtPickup"
                            :class="`fs-14 ${priceClass.declared.arrivalAtPickup}`"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.arrivalAtPickup"
                            single-line
                            type="datetime-local"
                            max="9999-12-31 23:59"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'arrivalAtPickup')"
                            @input="(e) => { onInputPrice(e, 'declared', 'arrivalAtPickup') }"
                            @change="() => { onChangeArrivalAtPickupDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.arrivalAtPickup" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.driver.arrivalAtPickup}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.arrivalAtPickup"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'arrivalAtPickup')"
                                @input="(e) => { onInputPrice(e, 'driver', 'arrivalAtPickup') }"
                                @change="() => { onChangeArrivalAtPickupDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.arrivalAtPickup">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.arrivalAtPickup}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.arrivalAtPickup" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.passenger.arrivalAtPickup}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.arrivalAtPickup"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'arrivalAtPickup')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'arrivalAtPickup') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.arrivalAtPickup">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.arrivalAtPickup}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.startRideWithPassenger || priceEnabled.declared.startRideWithPassenger || priceEnabled.driver.startRideWithPassenger || priceEnabled.passenger.startRideWithPassenger">
                      <v-layout row class="bg-lightGray">
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.startRideWithPassenger }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.startRideWithPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.startRideWithPassenger"
                                :class="`fs-14 ${priceClass.quoted.startRideWithPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.startRideWithPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'startRideWithPassenger')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'startRideWithPassenger') }"
                                @change="() => { onChangeStartRideWithPassengerQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.startRideWithPassenger">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.startRideWithPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.startRideWithPassenger"
                            :class="`fs-14 ${priceClass.declared.startRideWithPassenger}`"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.startRideWithPassenger"
                            single-line
                            type="datetime-local"
                            max="9999-12-31 23:59"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'startRideWithPassenger')"
                            @input="(e) => { onInputPrice(e, 'declared', 'startRideWithPassenger') }"
                            @change="() => { onChangeStartRideWithPassengerDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.startRideWithPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.driver.startRideWithPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.startRideWithPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'startRideWithPassenger')"
                                @input="(e) => { onInputPrice(e, 'driver', 'startRideWithPassenger') }"
                                @change="() => { onChangeStartRideWithPassengerDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.startRideWithPassenger">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.startRideWithPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.startRideWithPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.passenger.startRideWithPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.startRideWithPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'startRideWithPassenger')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'startRideWithPassenger') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.startRideWithPassenger">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.startRideWithPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.endRideWithPassenger || priceEnabled.declared.endRideWithPassenger || priceEnabled.driver.endRideWithPassenger || priceEnabled.passenger.endRideWithPassenger">
                      <v-layout row class="bg-lightGray">
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.endRideWithPassenger }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.endRideWithPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.endRideWithPassenger"
                                :class="`fs-14 ${priceClass.quoted.endRideWithPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.endRideWithPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'endRideWithPassenger')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'endRideWithPassenger') }"
                                @change="() => { onChangeEndRideWithPassengerQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.endRideWithPassenger">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.endRideWithPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.endRideWithPassenger"
                            :class="`fs-14 ${priceClass.declared.endRideWithPassenger}`"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.endRideWithPassenger"
                            single-line
                            type="datetime-local"
                            max="9999-12-31 23:59"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'endRideWithPassenger')"
                            @input="(e) => { onInputPrice(e, 'declared', 'endRideWithPassenger') }"
                            @change="() => { onChangeEndRideWithPassengerDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.endRideWithPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.driver.endRideWithPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.endRideWithPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'endRideWithPassenger')"
                                @input="(e) => { onInputPrice(e, 'driver', 'endRideWithPassenger') }"
                                @change="() => { onChangeEndRideWithPassengerDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.endRideWithPassenger">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.endRideWithPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.endRideWithPassenger" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.passenger.endRideWithPassenger}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.endRideWithPassenger"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'endRideWithPassenger')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'endRideWithPassenger') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.endRideWithPassenger">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.endRideWithPassenger}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 mb-4 bg-grey" v-if="priceEnabled.quoted.arrivalAtHome || priceEnabled.declared.arrivalAtHome || priceEnabled.driver.arrivalAtHome || priceEnabled.passenger.arrivalAtHome">
                      <v-layout row class="bg-lightGray">
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.arrivalAtHome }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.arrivalAtHome" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.arrivalAtHome"
                                :class="`fs-14 ${priceClass.quoted.arrivalAtHome}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.arrivalAtHome"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'arrivalAtHome')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'arrivalAtHome') }"
                                @change="() => { onChangeArrivalAtHomeQuoted('quoted') }"
                                @click="() => { onClickArrivalAtHome('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.arrivalAtHome">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.arrivalAtHome}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.arrivalAtHome"
                            :class="`fs-14 ${priceClass.declared.arrivalAtHome}`"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.arrivalAtHome"
                            single-line
                            type="datetime-local"
                            max="9999-12-31 23:59"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'arrivalAtHome')"
                            @input="(e) => { onInputPrice(e, 'declared', 'arrivalAtHome') }"
                            @change="() => { onChangeArrivalAtHomeDeclared('declared') }"
                            @click="() => { onClickArrivalAtHome('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.arrivalAtHome" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.driver.arrivalAtHome}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.arrivalAtHome"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'arrivalAtHome')"
                                @input="(e) => { onInputPrice(e, 'driver', 'arrivalAtHome') }"
                                @change="() => { onChangeArrivalAtHomeDriver('driver') }"
                                @click="() => { onClickArrivalAtHome('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.arrivalAtHome">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.arrivalAtHome}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.arrivalAtHome" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="`fs-14 ${priceClass.passenger.arrivalAtHome}`"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.arrivalAtHome"
                                single-line
                                type="datetime-local"
                                max="9999-12-31 23:59"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'arrivalAtHome')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'arrivalAtHome') }"
                                @change="() => { onChangeArrivalAtHomePassenger('passenger') }"
                                @click="() => { onClickArrivalAtHome('passenger') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.arrivalAtHome">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.arrivalAtHome}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.publicTransport || priceEnabled.declared.publicTransport || priceEnabled.driver.publicTransport || priceEnabled.passenger.publicTransport">
                      <v-layout row>
                        <v-flex xs2>
                          <div div class="finance-label">{{ priceAlias.publicTransport }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.publicTransport" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  v-if="priceEnabled.quoted.publicTransport"
                                  :class="priceClass.quoted.publicTransport"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.publicTransport"
                                  type="number"
                                  single-line
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'publicTransport')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'publicTransport') }"
                                  @change="() => { onChangePublicTransportQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.publicTransport">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.publicTransport}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.publicTransport">
                            <v-text-field
                              :class="priceClass.declared.publicTransport"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.publicTransport"
                              type="number"
                              single-line
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'publicTransport')"
                              @input="(e) => { onInputPrice(e, 'declared', 'publicTransport') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.publicTransport" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.publicTransport"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.publicTransport"
                                  type="number"
                                  single-line
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'publicTransport')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'publicTransport') }"
                                  @change="() => { onChangePublicTransportDriver('driver') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.publicTransport">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.publicTransport}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.publicTransport" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.publicTransport"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.publicTransport"
                                  type="number"
                                  single-line
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'publicTransport')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'publicTransport') }"
                                  @change="() => { onChangePublicTransportPassenger('passenger') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.publicTransport">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.publicTransport}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.travelTime || priceEnabled.declared.travelTime || priceEnabled.driver.travelTime || priceEnabled.passenger.travelTime">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.travelTime }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.travelTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.travelTime"
                                :class="priceClass.quoted.travelTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.travelTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'travelTime')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'travelTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'quoted', 'travelTime') }"
                                @change="() => { onChangeTravelTimeQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.travelTime">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.travelTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.travelTime"
                            :class="priceClass.declared.travelTime"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.travelTime"
                            single-line
                            type="time"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'travelTime')"
                            @input="(e) => { onInputPrice(e, 'declared', 'travelTime') }"
                            @keydown="(e) => { onInputTimeField(e, 'declared', 'travelTime') }"
                            @change="() => { onChangeTravelTimeDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.travelTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.driver.travelTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.travelTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'travelTime')"
                                @input="(e) => { onInputPrice(e, 'driver', 'travelTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'driver', 'travelTime') }"
                                @change="() => { onChangeTravelTimeDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.travelTime">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.travelTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.travelTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.passenger.travelTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.travelTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'travelTime')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'travelTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'passenger', 'travelTime') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.travelTime">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.travelTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.travelKm || priceEnabled.declared.travelKm || priceEnabled.driver.travelKm || priceEnabled.passenger.travelKm">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.travelKm }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.travelKm" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.travelKm"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.travelKm"
                                  single-line
                                  min="0"
                                  step="1"
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'travelKm')"
                                  @keydown="(e) => { onKeyDownPrice(e, 'quoted', 'travelKm') }"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'travelKm') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                              
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.travelKm">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.travelKm}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.travelKm">
                            <v-text-field
                              :class="priceClass.declared.travelKm"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.travelKm"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'travelKm')"
                              @keydown="(e) => { onKeyDownPrice(e, 'declared', 'travelKm') }"
                              @input="(e) => { onInputPrice(e, 'declared', 'travelKm') }"
                              @change="() => { onChangeTravelKmDeclared('declared') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.travelKm" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.travelKm"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.travelKm"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'travelKm')"
                                  @keydown="(e) => { onKeyDownPrice(e, 'driver', 'travelKm') }"
                                  @input="(e) => { onInputPrice(e, 'driver', 'travelKm') }"
                                  @change="() => { onChangeTravelKmDriver('driver') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.travelKm">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.travelKm}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.travelKm" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.travelKm"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.travelKm"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'travelKm')"
                                  @keydown="(e) => { onKeyDownPrice(e, 'passenger', 'travelKm') }"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'travelKm') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.travelKm">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.travelKm}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.travelKmTariff || priceEnabled.declared.travelKmTariff || priceEnabled.driver.travelKmTariff || priceEnabled.passenger.travelKmTariff">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.travelKmTariff }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.travelKmTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.travelKmTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.travelKmTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'travelKmTariff')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'travelKmTariff') }"
                                  @change="() => { onChangeTravelKmTariffQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.travelKmTariff">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.travelKmTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.travelKmTariff">
                            <v-text-field
                              :class="priceClass.declared.travelKmTariff"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.travelKmTariff"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'travelKmTariff')"
                              @input="(e) => { onInputPrice(e, 'declared', 'travelKmTariff') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.travelKmTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.travelKmTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.travelKmTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'travelKmTariff')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'travelKmTariff') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.travelKmTariff">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.travelKmTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.travelKmTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.travelKmTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.travelKmTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'travelKmTariff')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'travelKmTariff') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.travelKmTariff">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.travelKmTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.prebookedWaitingTime || priceEnabled.declared.prebookedWaitingTime || priceEnabled.driver.prebookedWaitingTime || priceEnabled.passenger.prebookedWaitingTime">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.prebookedWaitingTime }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.prebookedWaitingTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.prebookedWaitingTime"
                                :class="priceClass.quoted.prebookedWaitingTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.prebookedWaitingTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'prebookedWaitingTime')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'prebookedWaitingTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'quoted', 'prebookedWaitingTime') }"
                                @change="() => { onChangePrebookedWaitingTimeQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.prebookedWaitingTime">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.prebookedWaitingTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.prebookedWaitingTime"
                            :class="priceClass.declared.prebookedWaitingTime"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.prebookedWaitingTime"
                            single-line
                            type="time"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'prebookedWaitingTime')"
                            @input="(e) => { onInputPrice(e, 'declared', 'prebookedWaitingTime') }"
                            @keydown="(e) => { onInputTimeField(e, 'declared', 'prebookedWaitingTime') }"
                            @change="() => { onChangePrebookedWaitingTimeDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.prebookedWaitingTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.driver.prebookedWaitingTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.prebookedWaitingTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'prebookedWaitingTime')"
                                @input="(e) => { onInputPrice(e, 'driver', 'prebookedWaitingTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'driver', 'prebookedWaitingTime') }"
                                @change="() => { onChangePrebookedWaitingTimeDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.prebookedWaitingTime">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.prebookedWaitingTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.prebookedWaitingTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.passenger.prebookedWaitingTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.prebookedWaitingTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'prebookedWaitingTime')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'prebookedWaitingTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'passenger', 'prebookedWaitingTime') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0"  v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.prebookedWaitingTime">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.prebookedWaitingTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.prebookedWaitingTimeTariff || priceEnabled.declared.prebookedWaitingTimeTariff || priceEnabled.driver.prebookedWaitingTimeTariff || priceEnabled.passenger.prebookedWaitingTimeTariff">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.prebookedWaitingTimeTariff }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.prebookedWaitingTimeTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.prebookedWaitingTimeTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.prebookedWaitingTimeTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'prebookedWaitingTimeTariff')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'prebookedWaitingTimeTariff') }"
                                  @change="() => { onChangePrebookedWaitingTimeTariffQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.prebookedWaitingTimeTariff">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.prebookedWaitingTimeTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.prebookedWaitingTimeTariff">
                            <v-text-field
                              :class="priceClass.declared.prebookedWaitingTimeTariff"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.prebookedWaitingTimeTariff"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'prebookedWaitingTimeTariff')"
                              @input="(e) => { onInputPrice(e, 'declared', 'prebookedWaitingTimeTariff') }"
                              @change="() => { onChangePrebookedWaitingTimeTariffDeclared('declared') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.prebookedWaitingTimeTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.prebookedWaitingTimeTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.prebookedWaitingTimeTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'prebookedWaitingTimeTariff')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'prebookedWaitingTimeTariff') }"
                                  @change="() => { onChangePrebookedWaitingTimeTariffDriver('driver') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.prebookedWaitingTimeTariff">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.prebookedWaitingTimeTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.prebookedWaitingTimeTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.prebookedWaitingTimeTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.prebookedWaitingTimeTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'prebookedWaitingTimeTariff')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'prebookedWaitingTimeTariff') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.prebookedWaitingTimeTariff">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.prebookedWaitingTimeTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.extraWaitingTime || priceEnabled.declared.extraWaitingTime || priceEnabled.driver.extraWaitingTime || priceEnabled.passenger.extraWaitingTime">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.extraWaitingTime }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.extraWaitingTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.extraWaitingTime"
                                :class="priceClass.quoted.extraWaitingTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.extraWaitingTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'extraWaitingTime')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'extraWaitingTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'quoted', 'extraWaitingTime') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.extraWaitingTime">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.extraWaitingTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.extraWaitingTime"
                            :class="priceClass.declared.extraWaitingTime"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.extraWaitingTime"
                            single-line
                            type="time"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'extraWaitingTime')"
                            @input="(e) => { onInputPrice(e, 'declared', 'extraWaitingTime') }"
                            @keydown="(e) => { onInputTimeField(e, 'declared', 'extraWaitingTime') }"
                            @change="() => { onChangeExtraWaitingTimeDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.extraWaitingTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.driver.extraWaitingTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.extraWaitingTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'extraWaitingTime')"
                                @input="(e) => { onInputPrice(e, 'driver', 'extraWaitingTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'driver', 'extraWaitingTime') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.extraWaitingTime">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.extraWaitingTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.extraWaitingTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.passenger.extraWaitingTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.extraWaitingTime"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'extraWaitingTime')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'extraWaitingTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'passenger', 'extraWaitingTime') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0"  v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.extraWaitingTime">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.extraWaitingTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.extraWaitingTimeTariff || priceEnabled.declared.extraWaitingTimeTariff || priceEnabled.driver.extraWaitingTimeTariff || priceEnabled.passenger.extraWaitingTimeTariff">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.extraWaitingTimeTariff }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.extraWaitingTimeTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.extraWaitingTimeTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.extraWaitingTimeTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'extraWaitingTimeTariff')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'extraWaitingTimeTariff') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.extraWaitingTimeTariff">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.extraWaitingTimeTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.extraWaitingTimeTariff">
                            <v-text-field
                              :class="priceClass.declared.extraWaitingTimeTariff"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.extraWaitingTimeTariff"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'extraWaitingTimeTariff')"
                              @input="(e) => { onInputPrice(e, 'declared', 'extraWaitingTimeTariff') }"
                              @change="() => { onChangeExtraWaitingTimeTariffDeclared('declared') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.extraWaitingTimeTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.extraWaitingTimeTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.extraWaitingTimeTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'extraWaitingTimeTariff')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'extraWaitingTimeTariff') }"
                                  @change="() => { onChangeExtraWaitingTimeTariffDriver('driver') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.extraWaitingTimeTariff">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.extraWaitingTimeTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.extraWaitingTimeTariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.extraWaitingTimeTariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.extraWaitingTimeTariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'extraWaitingTimeTariff')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'extraWaitingTimeTariff') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.extraWaitingTimeTariff">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.extraWaitingTimeTariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.tripTime || priceEnabled.declared.tripTime || priceEnabled.driver.tripTime || priceEnabled.passenger.tripTime">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.tripTime }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.tripTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.tripTime"
                                :class="priceClass.quoted.tripTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.tripTime"
                                single-line
                                type="text"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'tripTime')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'tripTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'quoted', 'tripTime') }"
                                @change="() => { onChangeTripTimeQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.tripTime">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.tripTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.tripTime"
                            :class="priceClass.declared.tripTime"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.tripTime"
                            single-line
                            type="text"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'tripTime')"
                            @input="(e) => { onInputPrice(e, 'declared', 'tripTime') }"
                            @keydown="(e) => { onInputTimeField(e, 'declared', 'tripTime') }"
                            @change="() => { onChangeTripTimeDeclared('declared') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.tripTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.driver.tripTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.tripTime"
                                single-line
                                type="text"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'tripTime')"
                                @input="(e) => { onInputPrice(e, 'driver', 'tripTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'driver', 'tripTime') }"
                                @change="() => { onChangeTripTimeDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.tripTime">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.tripTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.tripTime" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.passenger.tripTime"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.tripTime"
                                single-line
                                type="text"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'tripTime')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'tripTime') }"
                                @keydown="(e) => { onInputTimeField(e, 'passenger', 'tripTime') }"
                                @change="() => { onChangeTripTimePassenger('passenger') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.tripTime">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.tripTime}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.minMinutes || priceEnabled.declared.minMinutes || priceEnabled.driver.minMinutes || priceEnabled.passenger.minMinutes">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.minMinutes }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.minMinutes" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                v-if="priceEnabled.quoted.minMinutes"
                                :class="priceClass.quoted.minMinutes"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.quoted.minMinutes"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'minMinutes')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'minMinutes') }"
                                @keydown="(e) => { onInputTimeField(e, 'quoted', 'minMinutes') }"
                                @change="() => { onChangeMinMinutesQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.minMinutes">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.minMinutes}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-if="priceEnabled.declared.minMinutes"
                            :class="priceClass.declared.minMinutes"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="editDialog.data.price.declared.minMinutes"
                            single-line
                            type="time"
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'minMinutes')"
                            @input="(e) => { onInputPrice(e, 'declared', 'minMinutes') }"
                            @keydown="(e) => { onInputTimeField(e, 'declared', 'minMinutes') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.minMinutes" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.driver.minMinutes"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.driver.minMinutes"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'minMinutes')"
                                @input="(e) => { onInputPrice(e, 'driver', 'minMinutes') }"
                                @keydown="(e) => { onInputTimeField(e, 'driver', 'minMinutes') }"
                                @change="() => { onChangeMinMinutesDriver('driver') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.minMinutes">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.minMinutes}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.minMinutes" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-text-field
                                :class="priceClass.passenger.minMinutes"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                v-model="editDialog.data.price.passenger.minMinutes"
                                single-line
                                type="time"
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'minMinutes')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'minMinutes') }"
                                @keydown="(e) => { onInputTimeField(e, 'passenger', 'minMinutes') }"
                                @change="() => { onChangeMinMinutesPassenger('passenger') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.minMinutes">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.minMinutes}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.tripKm || priceEnabled.declared.tripKm || priceEnabled.driver.tripKm || priceEnabled.passenger.tripKm">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.tripKm }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.tripKm" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.tripKm"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.tripKm"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'tripKm')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'tripKm') }"
                                  @change="() => { onChangeTripKmQuoted('quouted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.tripKm">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.tripKm}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.tripKm">
                            <v-text-field
                              :class="priceClass.declared.tripKm"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.tripKm"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'tripKm')"
                              @input="(e) => { onInputPrice(e, 'declared', 'tripKm') }"
                              @change="() => { onChangeTripKmDeclared('declared') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.tripKm" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.tripKm"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.tripKm"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'tripKm')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'tripKm') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.tripKm">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.tripKm}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.tripKm" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.tripKm"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.tripKm"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'tripKm')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'tripKm') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.tripKm">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.tripKm}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-grey" v-if="priceEnabled.quoted.distance || priceEnabled.declared.distance || priceEnabled.driver.distance || priceEnabled.passenger.distance">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.distance }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.distance" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.distance"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.distance"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'distance')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'distance') }"
                                  @change="() => { onChangeDistanceQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.distance">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.distance}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.distance">
                            <v-text-field
                              :class="priceClass.declared.distance"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.distance"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'distance')"
                              @input="(e) => { onInputPrice(e, 'declared', 'distance') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.distance" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.distance"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.distance"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'distance')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'distance') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.distance">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.distance}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.distance" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.distance"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.distance"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'distance')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'distance') }"
                                  @change="() => { onChangeDistancePassenger('passenger') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.distance">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.distance}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.discountType || priceEnabled.declared.discountType || priceEnabled.driver.discountType || priceEnabled.passenger.discountType">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.discountType }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.discountType" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-select
                                v-if="priceEnabled.quoted.discountType"
                                :class="priceClass.quoted.discountType"
                                v-model="editDialog.data.price.quoted.discountType"
                                :items="Object.values(DISCOUNT_TYPE)"
                                :item-text="(val) => { return val }"
                                single-line
                                outline
                                hide-details
                                :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'discountType')"
                                @input="(e) => { onInputPrice(e, 'quoted', 'discountType') }"
                                @change="() => { onChangeDiscountTypeQuoted('quoted') }"
                                tabindex="1"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.discountType">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.discountType}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <v-select
                            v-if="priceEnabled.declared.discountType"
                            :class="priceClass.declared.discountType"
                            v-model="editDialog.data.price.declared.discountType"
                            :items="Object.values(DISCOUNT_TYPE)"
                            :item-text="(val) => { return val }"
                            single-line
                            outline
                            hide-details
                            :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'discountType')"
                            @input="(e) => { onInputPrice(e, 'declared', 'discountType') }"
                            tabindex="2"
                          />
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.discountType" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-select
                                :class="priceClass.driver.discountType"
                                v-model="editDialog.data.price.driver.discountType"
                                :items="Object.values(DISCOUNT_TYPE)"
                                :item-text="(val) => { return val }"
                                single-line
                                outline
                                hide-details
                                :readonly=!manualPriceActive.driver || isChangeDriverPrice('driver', 'discountType')"
                                @input="(e) => { onInputPrice(e, 'driver', 'discountType') }"
                                tabindex="3"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.discountType">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.discountType}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.discountType" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <v-select
                                :class="priceClass.passenger.discountType"
                                v-model="editDialog.data.price.passenger.discountType"
                                :items="Object.values(DISCOUNT_TYPE)"
                                :item-text="(val) => { return val }"
                                single-line
                                outline
                                hide-details
                                :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'discountType')"
                                @input="(e) => { onInputPrice(e, 'passenger', 'discountType') }"
                                tabindex="4"
                              />
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.discountType">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.discountType}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.discountAmount || priceEnabled.declared.discountAmount || priceEnabled.driver.discountAmount || priceEnabled.passenger.discountAmount">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.discountAmount }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.discountAmount" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.discountAmount"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.discountAmount"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'discountAmount')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'discountAmount') }"
                                  @change="() => { onChangeDiscountAmountQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.discountAmount">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.discountAmount}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.discountAmount">
                            <v-text-field
                              :class="priceClass.declared.discountAmount"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.discountAmount"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'discountAmount')"
                              @input="(e) => { onInputPrice(e, 'declared', 'discountAmount') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.discountAmount" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.discountAmount"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.discountAmount"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'discountAmount')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'discountAmount') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.discountAmount">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.discountAmount}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.discountAmount" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.discountAmount"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.discountAmount"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'discountAmount')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'discountAmount') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.discountAmount">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.discountAmount}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.tariff || priceEnabled.declared.tariff || priceEnabled.driver.tariff || priceEnabled.passenger.tariff">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.tariff }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.tariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.tariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.tariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'tariff')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'tariff') }"
                                  @change="() => { onChangeTariffQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.tariff">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.tariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.tariff">
                            <v-text-field
                              :class="priceClass.declared.tariff"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.tariff"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'tariff')"
                              @input="(e) => { onInputPrice(e, 'declared', 'tariff') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.tariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.tariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.tariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'tariff')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'tariff') }"
                                  @change="() => { onChangeTariffDriver('driver') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.tariff">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.tariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.tariff" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.tariff"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.tariff"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'tariff')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'tariff') }"
                                  @change="() => { onChangeTariffPassenger('passenger') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.tariff">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.tariff}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.intermediateStops || priceEnabled.declared.intermediateStops || priceEnabled.driver.intermediateStops || priceEnabled.passenger.intermediateStops">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.intermediateStops }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.intermediateStops" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.intermediateStops"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.intermediateStops"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'intermediateStops')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'intermediateStops') }"
                                  @change="() => { onChangeIntermediateStopsQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.intermediateStops">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.intermediateStops}}&euro;</span>
                              <span 
                                v-if="
                                  editDialog.data.price.quoted.expenses.discount && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking.intermediateStops && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking.intermediateStops.total
                                "
                                class="calculate-label secondaryGreen--text">
                                ({{editDialog.data.price.quoted.expenses.discount.advanceBooking.intermediateStops.total}}&euro;)
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.intermediateStops">
                            <v-text-field
                              :class="priceClass.declared.intermediateStops"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.intermediateStops"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'intermediateStops')"
                              @input="(e) => { onInputPrice(e, 'declared', 'intermediateStops') }"
                              @change="() => { onChangeIntermediateStopsDeclared('declared') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.intermediateStops" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.intermediateStops"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.intermediateStops"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'intermediateStops')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'intermediateStops') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.intermediateStops">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.intermediateStops}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.intermediateStops" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.intermediateStops"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.intermediateStops"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'intermediateStops')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'intermediateStops') }"
                                  @change="() => { onChangeIntermediateStopsPassenger('passenger') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.intermediateStops">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.intermediateStops}}&euro;</span>
                              <span 
                                v-if="
                                  editDialog.data.price.passenger.expenses.discount && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking.intermediateStops && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking.intermediateStops.total
                                "
                                class="calculate-label secondaryGreen--text">
                                ({{editDialog.data.price.passenger.expenses.discount.advanceBooking.intermediateStops.total}}&euro;)
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.intermediateStopCost || priceEnabled.declared.intermediateStopCost || priceEnabled.driver.intermediateStopCost || priceEnabled.passenger.intermediateStopCost">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.intermediateStopCost }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.intermediateStopCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.intermediateStopCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.intermediateStopCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'intermediateStopCost')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'intermediateStopCost') }"
                                  @change="() => { onChangeIntermediateStopCostQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.intermediateStopCost">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.intermediateStopCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.intermediateStopCost">
                            <v-text-field
                              :class="priceClass.declared.intermediateStopCost"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.intermediateStopCost"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'intermediateStopCost')"
                              @input="(e) => { onInputPrice(e, 'declared', 'intermediateStopCost') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.intermediateStopCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.intermediateStopCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.intermediateStopCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'intermediateStopCost')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'intermediateStopCost') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.intermediateStopCost">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.intermediateStopCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.intermediateStopCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.intermediateStopCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.intermediateStopCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'intermediateStopCost')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'intermediateStopCost') }"
                                  @change="() => { onChangeIntermediateStopCostPassenger('passenger') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.intermediateStopCost">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.intermediateStopCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.outsideAreaFee || priceEnabled.declared.outsideAreaFee || priceEnabled.driver.outsideAreaFee || priceEnabled.passenger.outsideAreaFee">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.outsideAreaFee }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.outsideAreaFee" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.outsideAreaFee"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.outsideAreaFee"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'outsideAreaFee')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'outsideAreaFee') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.outsideAreaFee">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.outsideAreaFee}}&euro;</span>
                              <span 
                                v-if="
                                  editDialog.data.price.quoted.expenses.discount && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking.outsideAreaFee && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking.outsideAreaFee.total
                                "
                                class="calculate-label secondaryGreen--text">
                                ({{editDialog.data.price.quoted.expenses.discount.advanceBooking.outsideAreaFee.total}}&euro;)
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.outsideAreaFee">
                            <v-text-field
                              :class="priceClass.declared.outsideAreaFee"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.outsideAreaFee"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'outsideAreaFee')"
                              @input="(e) => { onInputPrice(e, 'declared', 'outsideAreaFee') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.outsideAreaFee" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.outsideAreaFee"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.outsideAreaFee"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'outsideAreaFee')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'outsideAreaFee') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <!-- <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.outsideAreaFee">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.outsideAreaFee}}&euro;</span>
                            </v-flex> -->
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.outsideAreaFee" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.outsideAreaFee"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.outsideAreaFee"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'outsideAreaFee')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'outsideAreaFee') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.outsideAreaFee">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.outsideAreaFee}}&euro;</span>
                              <span 
                                v-if="
                                  editDialog.data.price.passenger.expenses.discount && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking.outsideAreaFee && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking.outsideAreaFee.total
                                "
                                class="calculate-label secondaryGreen--text">
                                ({{editDialog.data.price.passenger.expenses.discount.advanceBooking.outsideAreaFee.total}}&euro;)
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 bg-dark-grey" v-if="priceEnabled.quoted.tripCost || priceEnabled.declared.tripCost || priceEnabled.driver.tripCost || priceEnabled.passenger.tripCost">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.tripCost }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.tripCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.tripCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.tripCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'tripCost')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'tripCost') }"
                                  @change="() => { onChangeTripCostQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.tripCost">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.tripCost}}&euro;</span>
                              <span 
                                v-if="
                                  editDialog.data.price.quoted.expenses.discount && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking.tripCost && 
                                  editDialog.data.price.quoted.expenses.discount.advanceBooking.tripCost.total
                                "
                                class="calculate-label secondaryGreen--text">
                                ({{editDialog.data.price.quoted.expenses.discount.advanceBooking.tripCost.total}}&euro;)
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.tripCost">
                            <v-text-field
                              :class="priceClass.declared.tripCost"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.tripCost"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'tripCost')"
                              @input="(e) => { onInputPrice(e, 'declared', 'tripCost') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.tripCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.tripCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.tripCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'tripCost')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'tripCost') }"
                                  @change="() => { onChangeTripCostDriver('driver') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.tripCost">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.tripCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.tripCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.tripCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.tripCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'tripCost')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'tripCost') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.tripCost">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.tripCost}}&euro;</span>
                              <span 
                                v-if="
                                  editDialog.data.price.passenger.expenses.discount && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking.tripCost && 
                                  editDialog.data.price.passenger.expenses.discount.advanceBooking.tripCost.total
                                "
                                class="calculate-label secondaryGreen--text">
                                ({{editDialog.data.price.passenger.expenses.discount.advanceBooking.tripCost.total}}&euro;)
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.insurance || priceEnabled.declared.insurance || priceEnabled.driver.insurance || priceEnabled.passenger.insurance">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.insurance }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.insurance" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.insurance"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.insurance"
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'insurance')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'insurance') }"
                                  @change="() => { onChangeInsuranceQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.insurance">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.insurance}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.insurance">
                            <v-text-field
                              :class="priceClass.declared.insurance"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.insurance"
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'insurance')"
                              @input="(e) => { onInputPrice(e, 'declared', 'insurance') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.insurance" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.insurance"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.insurance"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'insurance')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'insurance') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.insurance">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.insurance}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.insurance" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.insurance"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.insurance"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'insurance')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'insurance') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.insurance">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.insurance}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.platformCost || priceEnabled.declared.platformCost || priceEnabled.driver.platformCost || priceEnabled.passenger.platformCost">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.platformCost }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.platformCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.platformCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.platformCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'platformCost')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'platformCost') }"
                                  @change="() => { onChangePlatformCostQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.platformCost">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.platformCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.platformCost">
                            <v-text-field
                              :class="priceClass.declared.platformCost"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.platformCost"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'platformCost')"
                              @input="(e) => { onInputPrice(e, 'declared', 'platformCost') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.platformCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.platformCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.platformCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'platformCost')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'platformCost'); priceWatchDisabled = true }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.platformCost">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.platformCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.platformCost" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.platformCost"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.platformCost"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'platformCost')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'platformCost') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.platformCost">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.platformCost}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.promoAmount || priceEnabled.declared.promoAmount || priceEnabled.driver.promoAmount || priceEnabled.passenger.promoAmount">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.promoAmount }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.promoAmount" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.promoAmount"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.promoAmount"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'promoAmount')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'promoAmount') }"
                                  @change="() => { onChangePromoAmountQuoted('quoted') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.promoAmount">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.promoAmount}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.promoAmount">
                            <v-text-field
                              :class="priceClass.declared.promoAmount"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.promoAmount"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'promoAmount')"
                              @input="(e) => { onInputPrice(e, 'declared', 'promoAmount') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.promoAmount" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.promoAmount"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.promoAmount"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'promoAmount')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'promoAmount') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.promoAmount">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.promoAmount}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.promoAmount" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.promoAmount"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.promoAmount"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'promoAmount')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'promoAmount') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.promoAmount">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.promoAmount}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1" v-if="priceEnabled.quoted.alreadyPaid || priceEnabled.declared.alreadyPaid || priceEnabled.driver.alreadyPaid || priceEnabled.passenger.alreadyPaid">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label">{{ priceAlias.alreadyPaid }}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.quoted.alreadyPaid" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.quoted.alreadyPaid"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.quoted.alreadyPaid"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.quoted || isChangeDriverPrice('quoted', 'alreadyPaid')"
                                  @input="(e) => { onInputPrice(e, 'quoted', 'alreadyPaid') }"
                                  tabindex="1"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.quoted.expenses && editDialog.data.price.quoted.expenses.alreadyPaid">
                              <span class="calculate-label">{{editDialog.data.price.quoted.expenses.alreadyPaid}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>
                          <InputHotKeyWrapper v-if="priceEnabled.declared.alreadyPaid">
                            <v-text-field
                              :class="priceClass.declared.alreadyPaid"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="editDialog.data.price.declared.alreadyPaid"
                              single-line
                              type="number"
                              outline
                              hide-details
                              :disabled="!manualPriceActive.declared || isChangeDriverPrice('declared', 'alreadyPaid')"
                              @input="(e) => { onInputPrice(e, 'declared', 'alreadyPaid') }"
                              tabindex="2"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.driver.alreadyPaid" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.driver.alreadyPaid"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.driver.alreadyPaid"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.driver || isChangeDriverPrice('driver', 'alreadyPaid')"
                                  @input="(e) => { onInputPrice(e, 'driver', 'alreadyPaid') }"
                                  tabindex="3"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.driver.expenses && editDialog.data.price.driver.expenses.alreadyPaid">
                              <span class="calculate-label">{{editDialog.data.price.driver.expenses.alreadyPaid}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout v-if="priceEnabled.passenger.alreadyPaid" class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <InputHotKeyWrapper>
                                <v-text-field
                                  :class="priceClass.passenger.alreadyPaid"
                                  :max-length="TEXT_FIELD_MAX_LENGTH"
                                  v-model="editDialog.data.price.passenger.alreadyPaid"
                                  single-line
                                  type="number"
                                  outline
                                  hide-details
                                  :disabled="!manualPriceActive.passenger || isChangeDriverPrice('passenger', 'alreadyPaid')"
                                  @input="(e) => { onInputPrice(e, 'passenger', 'alreadyPaid') }"
                                  tabindex="4"
                                />
                              </InputHotKeyWrapper>
                            </v-flex>
                            <v-flex xs4 class="pa-0" v-if="editDialog.data.price.passenger.expenses && editDialog.data.price.passenger.expenses.alreadyPaid">
                              <span class="calculate-label">{{editDialog.data.price.passenger.expenses.alreadyPaid}}&euro;</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1 mt-4">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label"><b>VAT</b></div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.quoted.total && editDialog.data.price.quoted.total.vat">
                                <b class="mr-1"></b> {{ editDialog.data.price.quoted.total.vat }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>

                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.driver.total && editDialog.data.price.driver.total.vat">
                                <b class="mr-1"></b> {{ editDialog.data.price.driver.total.vat }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.passenger.total && editDialog.data.price.passenger.total.vat">
                                <b class="mr-1"></b> {{ editDialog.data.price.passenger.total.vat }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label"><b>Excl. VAT</b></div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.quoted.total && editDialog.data.price.quoted.total.vatExcl">
                                <b class="mr-1"></b> {{ editDialog.data.price.quoted.total.vatExcl }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>

                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.driver.total && editDialog.data.price.driver.total.vatExcl">
                                <b class="mr-1"></b> {{ editDialog.data.price.driver.total.vatExcl }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.passenger.total && editDialog.data.price.passenger.total.vatExcl">
                                <b class="mr-1"></b> {{ editDialog.data.price.passenger.total.vatExcl }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label"><b>Discount</b></div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.quoted.total && editDialog.data.price.quoted.total.discount">
                                <b class="mr-1"></b> -{{ editDialog.data.price.quoted.total.discount }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>

                        </v-flex>
                        <v-flex xs3>
                          <!-- <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.driver.total && editDialog.data.price.driver.total.discount">
                                <b class="mr-1"></b> {{ editDialog.data.price.driver.total.discount }}
                              </div>
                            </v-flex>
                          </v-layout> -->
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.passenger.total && editDialog.data.price.passenger.total.discount">
                                <b class="mr-1"></b> -{{ editDialog.data.price.passenger.total.discount }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="pa-1">
                      <v-layout row>
                        <v-flex xs2>
                          <div class="finance-label"><b>Total</b></div>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.quoted.total && editDialog.data.price.quoted.total.total">
                                <b class="mr-1"></b> {{ editDialog.data.price.quoted.total.total }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs2>

                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.driver.total && editDialog.data.price.driver.total.total">
                                <b class="mr-1"></b> {{ editDialog.data.price.driver.total.total }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout class="ma-0" align-center>
                            <v-flex xs8 class="pa-0">
                              <div v-if="editDialog.data.price.passenger.total && editDialog.data.price.passenger.total.total">
                                <b class="mr-1"></b> {{ editDialog.data.price.passenger.total.total }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <v-layout v-if="editDialog.data.passengerId">
                  <v-flex xs6>
                    <v-select
                      :items="addressType"
                      v-model="editDialog.data.passenger.invoiceType"
                      label="Select address"
                      outline
                      hide-details
                      @change="handleChangeInvoiceType"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="editDialog.data.billingAddress"
                      label="Billing address"
                      outline
                      hide-details
                    />
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap>
                  <v-flex xs6>
                      <v-text-field
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        v-model="editDialog.data.invoicedAt"
                        step="300"
                        type="datetime-local"
                        max="9999-12-31 23:59"
                        class="text-field-mobile full-width"
                        label="Invoiced at:"
                        outline
                        hide-details
                      />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="editDialog.data.reverseInvoicedAt"
                      step="300"
                      type="datetime-local"
                      max="9999-12-31 23:59"
                      class="text-field-mobile full-width"
                      label="Reverse invoiced at:"
                      outline
                      hide-details
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="editDialog.data.referenceNumber"
                      label="Reference number"
                      outline
                      hide-details
                    />
                  </v-flex>
                  <v-flex xs6>
                    
                  </v-flex>
                  <v-flex xs6>
                    <sent-messages
                      v-if="editDialog.data.id"
                      :ride-id="editDialog.data.id"
                    />

                    <v-btn
                      color="secondary"
                      class="full-width ma-0"
                      :disabled="sendConfPassengerDisabled"
                      :loading="sendConfPassengerLoader"
                      @click="sendFirstConfirmationPassenger">Send first confirmation to customer</v-btn>

                    <v-btn
                      color="secondary"
                      class="ma-0 white--text full-width"
                      :disabled="emailEstimatedPriceDisabled"
                      :loading="emailEstimatedPriceLoader"
                      @click="emailEstimatedPrice">
                      Email estimated price
                    </v-btn>

                    <v-btn
                      color="secondary"
                      class="full-width ma-0"
                      :disabled="sendConfPassengerDisabled"
                      :loading="sendConfPassengerLoader"
                      @click="sendConfirmationPassenger">Send update to customer</v-btn>
                    <v-btn
                      color="secondary"
                      class="full-width ma-0"
                      :disabled="sendConfDriverDisabled"
                      :loading="sendConfDriverLoader"
                      @click="sendConfirmationDriver">Send confirmation to the driver</v-btn>

                    <v-btn
                      color="secondary"
                      class="full-width ma-0"
                      :disabled="sendConf3rdPersonLoader"
                      :loading="sendConf3rdPersonLoader"
                      @click="() => { sendConf3rdPersonDialog = true }">Send confirmation to a 3rd person</v-btn>

                    <v-dialog
                      max-width="700"
                      v-model="sendConf3rdPersonDialog">
                      <v-card class="elevation-0 pa-3">
                        <v-card-title>
                          <span class="headline">Send confirmation to a 3rd person</span>
                        </v-card-title>

                        <v-card-title class="pa-1">
                          <v-layout row wrap>
                            <v-flex
                              class="pa-3"
                              xs6>
                              <v-text-field
                                v-model="confirmation3rdPerson.firstName"
                                label="First name"
                                hide-details
                              />
                            </v-flex>
                            <v-flex
                              class="pa-3"
                              xs6>
                              <v-text-field
                                v-model="confirmation3rdPerson.lastName"
                                label="Last name"
                                hide-details
                              />
                            </v-flex>
                            <v-flex
                              class="pa-3"
                              xs6>
                              <v-text-field
                                v-model="confirmation3rdPerson.email"
                                label="Email"
                                hide-details
                              />
                            </v-flex>
                          </v-layout>
                        </v-card-title>

                        <v-card-actions class="pa-3 justify-end">
                          <v-btn
                            color="primary"
                            @click="sendConfirmation3rdPerson">Send</v-btn>
                          <v-btn
                            color="secondary"
                            @click="() => { sendConf3rdPersonDialog = false }">Cancel</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs6>
                    <v-btn class="upload-file secondary ma-0">
                      <label
                        for="file">Upload invoice</label>
                      <input
                        id="file"
                        ref="myFiles"
                        :disabled="previewFilesLoading"
                        type="file"
                        class="custom-file-input"
                        accept="application/pdf"
                        multiple
                        @change="previewFiles">
                    </v-btn>
                    <div>{{ editDialog.data.imageName }}</div>
                    <div class="export-action">
                      <v-btn
                        :disabled="generateInvoiceDisabled"
                        :color="editDialog.data.statusSequence === rideStatus.declared.sequence || editDialog.data.statusSequence === rideStatus.declaration_approved.sequence ? 'pink' : 'secondary'"
                        class="m-0 white--text"
                        @click="generateInvoice">Generate ride invoice</v-btn>
                      <v-btn
                        v-if="!isRentabob"
                        :disabled="generateReverseInvoiceDisabled"
                        color="secondary"
                        class="m-0 white--text"
                        @click="generateReverseInvoice">Generate reverse invoice</v-btn>
                      <v-btn
                        color="secondary"
                        class="m-0"
                        @click="exportCSV">Export CSV</v-btn>
                    </div>
                    <div
                      v-if="editDialog && editDialog.data.passenger && editDialog.data.passenger.invoicingType"
                      class="mt-3">
                      Invoice period: <b>{{ invoicePeriodOptions[editDialog.data.passenger.invoicingType] }}</b>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex
                    v-if="editDialog.showPaymentMethods && editDialog.data.passenger && !loading"
                    xs12
                    md6>
                    <p>Payment methods</p>
                    <user-payment
                      :alternate-payment-method="editDialog.data.alternatePaymentMethod"
                      :alternate-payment-description="editDialog.data.alternatePaymentDescription"
                      :active-mandate="editDialog.data.mandate"
                      :active-pay-on-invoce="editDialog.data.payOnInvoice"
                      :pay-invoice-enabled="editDialog.data.passenger && editDialog.data.passenger.payOnInvoice"
                      :can-add-new-payment-method="false"
                      :user-id="editDialog.data.passenger.user.id"
                      :can-delete="false"
                      @onSelectPayInvoice="onSelectPayInvoice"
                      @onSelectMandate="onSelectMandate"
                      @onSelectAlternate="onSelectAlternate" />
                  </v-flex>
                  <v-flex
                    xs12
                    md6>
                    <v-layout column>
                      <v-flex
                        v-if="editDialog.invoices.length"
                        xs12>
                        <p>Invoices and payments</p>
                        <invoice-table
                          :invoices="editDialog.invoices"
                          :invoice-delete="(invoice) => { invoice.type === 'combined' ? removeInvoice(invoice.id) : removeReverseInvoice(invoice.id) }"
                          :loading="loading"
                          :invoice-for-pay="invoiceForPay"
                          :update-invoice-for-pay="(newInvoiceForPay) => { this.invoiceForPay = newInvoiceForPay }"
                          selectable-one
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>

                </v-layout>
                <v-layout class="wrap">
                  <v-flex
                    xs6
                    class="pa-0">
                    <v-btn
                      :disabled="isDisabledPayment"
                      :color="invoiceStep.isGenerated && invoiceStep.isSent && !invoiceStep.isPaid ? 'pink' : 'secondary'"
                      :class="invoiceStep.isGenerated && invoiceStep.isSent && !invoiceStep.isPaid ? 'text-white' : ''"
                      @click="addPaymentClick"
                    >Add payment</v-btn>
                  </v-flex>
                  <v-flex
                    xs6
                    class="pa-0">
                    <v-btn
                      :disabled="paymentLoader || !editDialog.data.mandate || !invoiceForPay || isDisabledPayment"
                      :color="invoiceStep.isGenerated && invoiceStep.isSent && !invoiceStep.isPaid ? 'pink' : 'secondary'"
                      :class="invoiceStep.isGenerated && invoiceStep.isSent && !invoiceStep.isPaid ? 'text-white' : ''"
                      @click="payInvoices"
                    >Pay with selected method</v-btn>
                  </v-flex>
                  <v-flex
                    xs6
                    class="pa-0">
                    <v-btn
                      :disabled="isDisabledPayment"
                      color="secondary"
                      @click="setPaymentStatus">Mark as paid</v-btn>
                  </v-flex>
                  <v-flex
                    xs6
                    class="pa-0">
                    <send-invoice-button
                      :color="invoiceStep.isGenerated && !invoiceStep.isSent ? 'pink' : 'secondary'"
                      :button-classes="invoiceStep.isGenerated && !invoiceStep.isSent ? 'text-white' : ''"
                      :disabled="!invoiceForPay"
                      :invoice-id="invoiceForPay && invoiceForPay.id"
                      :invoice-type="invoiceForPay && invoiceForPay.type"
                      :after-action="() => { fetchInvoices(); }" />
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>

          <v-dialog
            v-model="noteDialog"
            max-width="500px"
            transition="dialog-bottom-transition"
          >
            <div class="note-dialog">
              <v-textarea
                v-model="editNoteObject.text"
                outline
                rows="6"
                class="custom-textarea"
                placeholder="Laat hier je bericht achter"
              />
              <v-btn
                class="btn-submit white--text"
                color="pink"
                block
                @click="editNote()"
              >
                Notitie toevoegen
                <v-icon>arrow_forward</v-icon>
              </v-btn>
            </div>
          </v-dialog>
          <v-dialog
            v-model="removeNoteDialog"
            max-width="500px"
            transition="dialog-bottom-transition"
          >
            <div class="note-dialog py-4">
              <h2 class="text-center mt-0 mb-4">Weet je zeker dat?</h2>

              <div class="note-actions">
                <v-btn
                  color="green"
                  text
                  @click="cancelRemoveNoteDialog"
                >
                  Annuleren
                </v-btn>

                <v-btn
                  color="red"
                  text
                  @click="deleteNote"
                >
                  Bevestigen
                </v-btn>
              </div>
            </div>
          </v-dialog>
          <v-dialog
            v-model="isOpenExpenseModal"
            max-width="500px"
            transition="dialog-bottom-transition"
          >
            <div class="note-dialog py-4">
              <h2 class="text-center mt-0 mb-4">Add expense</h2>

              <div>
                <v-radio-group v-model="expense.type">
                  <v-text-field
                    v-model="expense.id"
                    type="hidden"
                    hide-details
                  />
                  <v-radio
                    v-for="(type, key) in expensesTypes"
                    :key="key"
                    :label="`${type.title}`"
                    :value="`${type.id}`"
                    color="pink"
                  />
                </v-radio-group>
                <InputHotKeyWrapper>
                  <v-text-field
                    v-model="expense.driverAmount"
                    label="Amount driver"
                    type="number"
                    outline
                    hide-details
                    class="mb-3"
                  />
                </InputHotKeyWrapper>
                <InputHotKeyWrapper>
                  <v-text-field
                    v-model="expense.passengerAmount"
                    label="Amount customer"
                    type="number"
                    outline
                    hide-details
                    class="mb-3"
                  />
                </InputHotKeyWrapper>
                <v-textarea
                  v-model="expense.note"
                  label="Expense note"
                  hide-details
                  outline
                  class="mb-3"
                />
                <v-text-field
                  v-model="expense.file.name"
                  label="Select..."
                  prepend-icon="attach_file"
                  @click="onPickFile"
                />
                <input
                  ref="fileInput"
                  type="file"
                  style="display: none"
                  accept="image/*,.pdf"
                  @change="onFilePicked">
                <v-switch
                  v-model="expense.isApproved"
                  label="Approve"
                  color="pink"
                />
              </div>

              <div class="note-actions">
                <v-btn
                  color="pink"
                  text
                  @click="addExpense"
                >
                  Add expense
                </v-btn>
              </div>
            </div>
          </v-dialog>
          <v-dialog
            v-model="isOpenPaymentModal"
            max-width="500px"
            transition="dialog-bottom-transition"
          >
            <div class="note-dialog py-4">
              <h2 class="text-center mt-0 mb-4">Add payment</h2>

              <div>
                <div class="custom-label black--text size-18">Payment</div>
                <InputHotKeyWrapper>
                  <v-text-field
                    v-model="paymentAmount"
                    type="number"
                    label="Amount"
                    class="mb-4"
                  />
                </InputHotKeyWrapper>
                <v-text-field
                  v-model="paymentDescription"
                  label="Description"
                  class="mb-4"
                />
                <div>
                  <v-btn
                    :disabled="createPaymentLoader || !editDialog.data.mandate || !paymentAmount || !paymentDescription"
                    color="green"
                    class="mr-3"
                    @click="pay"
                  >Pay</v-btn>
                </div>
              </div>
            </div>
          </v-dialog>
          <v-dialog
            v-model="isOpenPriceChangesModal"
            max-width="700px"
            persistent
          >
            <div class="note-dialog price-dialog py-4">
              <h2 class="text-center mt-0 mb-4">Price will be changed</h2>

              <div>
                <div v-for="from in Object.keys(inputPriceChange)" :key="from">
                  <div v-if="editDialog.data.price && inputPriceChange[from] && editDialog.data.price[from]">
                    <div v-for="[key, value] in Object.entries(inputPriceChange[from])" :key="key">
                      <v-layout row wrap v-if="value && !lodash.isEqual(value.value, editDialog.data.price[from][key])" class="py-1 hover-line">
                        <v-flex xs5 class="pa-2">{{ priceAlias[key] }} ({{ from }})</v-flex>
                        <v-flex xs3>
                          <div :class="`${value.type} br-div py-2 mx-2 text-center`">{{ moment(value.value, 'YYYY-MM-DDTHH:mm', true).isValid() || moment(value.value, 'YYYY-MM-DDTHH:mm:ss', true).isValid() ? moment(value.value).format('DD.MM.YYYY HH:mm') : value.value }}</div>
                        </v-flex>
                        <v-flex xs1>
                          <v-icon
                            color="black"
                            size="25">
                            arrow_forward
                          </v-icon>
                        </v-flex>
                        <v-flex xs3>
                          <div :class="`${PRICE_BORDER_COLOR.auto} br-div py-2 mx-2 text-center`">{{ moment(editDialog.data.price[from][key], 'YYYY-MM-DDTHH:mm', true).isValid() || moment(editDialog.data.price[from][key], 'YYYY-MM-DDTHH:mm:ss', true).isValid()  ? moment(editDialog.data.price[from][key]).format('DD.MM.YYYY HH:mm') : editDialog.data.price[from][key] }}</div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="pt-3 d-flex">
                  <v-btn
                    color="primary"
                    class="ma-0 mr-1"
                    @click="editDialog.priceChangedConfirmFunction"
                  >Save</v-btn>
                  <v-btn
                    color="warning"
                    class="ma-0 ml-1"
                    dark
                    text
                    @click="editDialog.priceChangedCancelFunction"
                  >
                    Cancel
                  </v-btn>
                  <!-- <v-btn
                    color="warning"
                    class="mr-0"
                    dark
                    text
                    @click="() => { isOpenPriceChangesModal = false }"
                  >
                    Cancel
                  </v-btn> -->
                </div>
              </div>
            </div>
          </v-dialog>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>

import lodash from 'lodash'
import _debounce from 'lodash/debounce'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import PaymentMixin from '../../../helpers/payment'
import { PASSENGER_CAR_POSITIONS, TEXT_FIELD_MAX_LENGTH, DRIVER_DRESSES, BRAND, DISCOUNT_TYPE } from '../../../constants/common'
import { Datetime } from 'vue-datetime'
import { cleanDefaultFields } from 'Helpers/helpers'
import StopsTimeline from '../../../modules/StopsTimeline.vue'
import StatusList from '../../../mixins/StatusList.vue'
import RideOverviewTable from './RideOverviewTable.vue'
import RideOverviewMixin from './RideOverviewMixin.vue'
import Chat from 'Components/Widgets/Chat'
import GroupSwitch from 'Components/GroupSwitch'
import UserPayment from 'Components/Payment'
import TagsSelect from 'Components/TagsSelect'
import moment from 'moment'
import csvExport from '../../../helpers/csvExport'
import { googleMaps } from '../../../helpers/google'
import { createBase64File, parseDate } from '../../../helpers/helpers'
import DriversSubscribed from './DriversSubscribed.vue'
import SentMessages from './SentMessages.vue'
import SendInvoiceButton from 'Components/Buttons/SendInvoiceButton.vue'
import PassengersInput from '../../../modules/components/PassengersInput.vue'
import DriversInput from '../../../modules/components/DriversInput.vue'
import InvoicePdf from 'Components/Invoice/InvoicePdf'
import InvoiceTable from 'Components/Invoice/InvoiceTable'

const PRICE_BORDER_COLOR = {
  auto: 'br-purple',
  manual: 'br-yellow',
  manualAfter: 'br-blue',
}

const FILE = {
  name: null,
  content: null,
  type: 'expense',
  reference: null,
  url: null
}

export default {
  components: { 'datetime': Datetime, DriversSubscribed, SentMessages, StopsTimeline, RideOverviewTable, Chat, GroupSwitch, UserPayment, TagsSelect, SendInvoiceButton, PassengersInput, DriversInput, InvoicePdf, InvoiceTable },
  mixins: [Request, StatusList, RideOverviewMixin, Brands, PaymentMixin],
  data () {
    return {
      PRICE_BORDER_COLOR,
      moment,
      BRAND,
      DISCOUNT_TYPE,
      openDialogRide: null,
      carPositionList: PASSENGER_CAR_POSITIONS,
      driverDressList: DRIVER_DRESSES,
      TEXT_FIELD_MAX_LENGTH,
      ridesList: [],
      totalRides: 0,
      showLoading: false,
      paymentLoader: false,
      previewFilesLoading: false,
      isAdmin: false,
      statusesList: [],
      regionList: [],
      typesList: [],
      brandList: [],
      invoiceForPay: null,
      prebookedWaitingTimeList: [
        { name: '0-10 minuten', id: '00:10' },
        { name: '10-15 minuten', id: '00:15' },
        { name: '15-20 minuten', id: '00:20' },
        { name: '20-25 minuten', id: '00:25' }
      ],
      addressType: [
        'private',
        'business'
      ],
      driversList: [],
      driversSubscribeList: [],
      deleteDialogConfirmation: () => {},
      deleteElectDialogConfirmation: () => {},
      deleteChatDialogConfirmation: () => {},
      deleteDialogConfirmationSubscription: () => {},
      loading: true,
      nextStatusLoader: false,
      emailEstimatedPriceLoader: false,
      buttonLoader: false,
      generateInvoiceLoader: false,
      generateReverseInvoiceLoader: false,
      sendConf3rdPersonLoader: false,
      sendConfPassengerLoader: false,
      sendConfDriverLoader: false,
      filterProps: null,
      paymentAmount: null,
      paymentDescription: null,
      createPaymentLoader: false,
      editDialogCopyCompare: null,
      editDialog: {
        showPaymentMethods: true,
        dataInitial: {},
        show: false,
        data: {
          passengerId: null,
          price: null,
          advanceBookingDiscount: null
        },
        stopsData: [],
        drivers: [],
        invoices: [],
        subscribeDriverIds: [],
        openPriceChangedModal: () => {},
        closePriceChangedModal: () => {},
        priceChangedConfirmFunction: () => {},
        priceChangedCancelFunction: () => {},
        saveFunction: () => {},
        closeFunction: () => {}
      },
      subscribedDrivers: [],
      subscribeDriverButtonLoader: false,
      isDeletedFilter: 0,
      hasInvoiceFilter: 0,
      files: {},
      imageData: null,
      tariff: {},
      insurance: {},
      insuranceEnabled: false,
      platformCost: {},
      publicTransport: {},
      driverNotePage: 1,
      passengerNotePage: 1,
      driverNoteTotalPage: 0,
      passengerNoteTotalPage: 0,
      driverNotePerPage: 4,
      rideNotes: [],
      passengerNotes: [],
      sortDirectionList: ['asc', 'desc'],
      sortDirection: 'desc',
      noteDialog: false,
      removeNoteDialog: false,
      loadingNote: false,
      removeNoteId: null,
      editNoteObject: {},
      cars: [],
      expensesTypes: [],
      rideExpenses: [],
      expense: {
        isApproved: true,
        file: FILE
      },
      isNextStatus: false,
      showNextStatusButton: false,
      isOpenExpenseModal: false,
      isOpenPaymentModal: false,
      nextStatusAlias: 'draft',
      headers: [ { text: 'id', sortable: false }, { value: 'confirmedAt', text: 'Confirmed', sortable: false }, { text: 'Photo', sortable: false }, { text: 'Name', sortable: false }, { text: 'Fixed', sortable: false }, { text: 'Ride cost', sortable: false }, { text: 'Insurance', sortable: false }, { text: 'Platform cost', sortable: false }, { text: 'Public transport reimbursement', sortable: false }, { text: '', sortable: false }, { text: '', sortable: false }, { text: '', sortable: false } ],
      headersDriversSubscribedDialog: [ { text: '', sortable: false }, { text: 'Name ', sortable: false }, { text: 'Email', sortable: false }, { text: 'Phone', sortable: false }, { text: 'Address', sortable: false }, { text: 'City', sortable: false }, { text: 'Available', sortable: false }, { text: 'Brand', sortable: false }, { text: 'Type', sortable: false }, { text: 'Repeat', sortable: false }, { text: 'Certificates', sortable: false }, { text: 'Tariff', sortable: false }, { text: 'Num reviews', sortable: false }, { text: 'Av rating', sortable: false }, { text: 'Hours experience', sortable: false }, { text: 'Years experience', sortable: false }, { text: 'License since', sortable: false }, { text: 'Hours this year', sortable: false }, { text: 'Notes', sortable: false } ],
      invoicePeriodOptions: {
        per_ride: 'each ride',
        weekly: 'weekly',
        monthly: 'monthly'
      },
      invoiceStep: {
        isGenerated: false,
        isSent: false,
        isPaid: false
      },
      sendConf3rdPersonDialog: false,
      confirmation3rdPerson: {},
      isFixedPriceCheckbox: false,
      isFixedPriceEstimateCheckbox: false,
      subscribedDriverIds: [],
      declareData: {
        loading: false,
        isOpenModal: false,
        duration: '01:00',
        durationWaitingTime: '00:15',
        travelTime: null,
        travelKm: null,
        afterSaveFunction: () => {}
      },
      isDeclareSetStatus: false,
      loadingExpense: false,
      isDeclareSkip: false,
      isOpenPriceChangesModal: false,
      isRemoveElectDriver: false,
      emptyPriceObject: {
        quoted: {},
        declared: {},
        driver: {},
        passenger: {},
        type: 'regular'
      },
      passengersInputDisabled: false,
      inputPriceChange: {
        quoted: {},
        declared: {},
        driver: {},
        passenger: {}
      },
      priceWatchDisabled: false,
      appConfig: null,
      priceClassInit: {
        quoted: {},
        declared: {},
        driver: {},
        passenger: {}
      },
      isInitPriceDrivers: false,
      disabledDriverType: false,
      discountsList: []
    }
  },
  computed: {
    priceAlias () {
      return {
        startRideToPassenger: 'Start ride to customer',
        arrivalAtPickup: 'Arrival at pickup',
        startRideWithPassenger: 'Start ride with customer',
        endRideWithPassenger: 'End ride with customer',
        arrivalAtHome: this.isRentabob ? 'End shift' : 'Arrival at home',
        publicTransport: 'Public transport',
        travelTime: 'Travel time',
        travelKm: 'Travel km',
        travelKmTariff: 'Travel km tariff',
        prebookedWaitingTime: 'Prebooked waiting time',
        prebookedWaitingTimeTariff: 'Prebooked waiting time tariff',
        extraWaitingTime: 'Extra waiting time',
        extraWaitingTimeTariff: 'Extra waiting tariff',
        tripTime: 'Trip time',
        minMinutes: 'Minimum time',
        tripKm: 'Trip km',
        distance: 'Distance',
        discountType: 'Discount type',
        discountAmount: 'Discount amount',
        tariff: 'Tariff',
        tripCost: 'Trip cost',
        intermediateStops: 'Intermediate stops',
        intermediateStopCost: 'Intermediate stop cost',
        outsideAreaFee: 'Outside area fee',
        insurance: 'Insurance',
        platformCost: 'Platform cost',
        promoAmount: 'Promo amount',
        alreadyPaid: 'Already paid'
      }
    },
    isConfirmCancel () {
      if (this.editDialogCopyCompare) {
        return !lodash.isEqual(this.editDialogCopyCompare.data, this.editDialog.data)
      }
      return true
    },
    isDisabledPayment () {
      return [this.getStatusByName('paid').id, this.getStatusByName('paid_out').id].includes(this.editDialog.data.status)
    },
    generateInvoiceDisabled () {
      return this.generateInvoiceLoader || (this.openDialogRide && this.openDialogRide.status && this.openDialogRide.status.sequence < this.rideStatus.declared.sequence)
    },
    emailEstimatedPriceDisabled () {
      if (this.editDialog.data.id && (this.isEdrivers || this.isRentabob)) {
        // return !(this.openDialogRide && this.openDialogRide.status && [this.rideStatus.new.sequence, this.rideStatus.subscribed.sequence, this.rideStatus.draft.sequence].includes(this.openDialogRide.status.sequence))
        return false
      }

      return true
    },
    generateReverseInvoiceDisabled () {
      // if (this.isDriveme || this.isEdrivers) {
      //   const hasDuration = this.openDialogRide &&this.openDialogRide.duration && Boolean(parseFloat(this.openDialogRide.duration.minutes))
      //   const hasDurationOrTrip = this.openDialogRide && this.openDialogRide.price && (hasDuration || (this.openDialogRide.price.quoted && this.openDialogRide.price.quoted.tripCost))
      //   const isDeclaredStatusMore = this.openDialogRide && this.openDialogRide.status && this.openDialogRide.status.sequence >= this.rideStatus.declared.sequence
      //   const hasInvoices = this.editDialog.invoices && this.editDialog.invoices.length

      //   return this.generateReverseInvoiceLoader || !hasInvoices || !(isDeclaredStatusMore && hasDurationOrTrip)
        
      // }
      return this.generateReverseInvoiceLoader || (this.openDialogRide && this.openDialogRide.status && this.openDialogRide.status.sequence < this.rideStatus.declared.sequence)
    },
    selectedBrandName () {
      const brand = this.brandList.find(item => item.id === this.selectedBrandId  ? this.selectedBrandId : null)

      return brand ? brand.name : null
    },
    isDriveme () {
      return this.selectedBrandName === BRAND.DRIVE_ME
    },
    isEdrivers () {
      return this.selectedBrandName === BRAND.EDRIVERS
    },
    isRentabob () {
      return this.selectedBrandName === BRAND.RENTABOB
    },
    selectedBrandId () {
      if (this.editDialog.data.brand) {
        return typeof this.editDialog.data.brand === 'object' ? this.editDialog.data.brand.id : this.editDialog.data.brand
      }
      return null
    },
    selectedBrandIdArr () {
      return [this.selectedBrandId]
    },
    sendConfPassengerDisabled () {
      return this.sendConfPassengerLoader || (this.selectedBrandName !== BRAND.EDRIVERS && this.selectedBrandName !== BRAND.RENTABOB)
    },
    sendConfDriverDisabled () {
      return !this.editDialog.data.driver || this.sendConfDriverLoader || (this.selectedBrandName !== BRAND.EDRIVERS && this.selectedBrandName !== BRAND.RENTABOB)
    },
    expensesAllApproveCheckbox () {
      const isAllApprove = !this.rideExpenses.some(item => !item.approved)
      return isAllApprove
    },
    priceClass () {
      let result = {
        quoted: {},
        declared: {},
        driver: {},
        passenger: {}
      }

      if (this.editDialogCopyCompare) {
        result.quoted = this.getPriceClassItem('quoted')
        result.declared = this.getPriceClassItem('declared')
        result.driver = this.getPriceClassItem('driver')
        result.passenger = this.getPriceClassItem('passenger')
      }

      return result
    },
    priceEnabled () {
      const isHasBrand = (brands) => {
        return brands.includes(this.selectedBrandName)
      }
      const driveme = BRAND.DRIVE_ME
      const rentabob = BRAND.RENTABOB
      const edrivers = BRAND.EDRIVERS

      return {
        quoted: {
          publicTransport: isHasBrand([driveme]),
          travelTime: isHasBrand([]),
          travelKm: isHasBrand([edrivers, rentabob]),
          travelKmTariff: isHasBrand([edrivers, rentabob]),
          prebookedWaitingTime: isHasBrand([rentabob]),
          prebookedWaitingTimeTariff: isHasBrand([rentabob]),
          extraWaitingTime: isHasBrand([]),
          extraWaitingTimeTariff: isHasBrand([rentabob]),
          tripTime: isHasBrand([driveme, edrivers]),
          minMinutes: isHasBrand([driveme, edrivers]),
          tripKm: isHasBrand([]),
          distance: isHasBrand([rentabob]),
          platformCost: isHasBrand([driveme, edrivers, rentabob]),
          tariff: isHasBrand([driveme, edrivers]),
          intermediateStops: isHasBrand([rentabob]),
          intermediateStopCost: isHasBrand([rentabob]),
          outsideAreaFee: isHasBrand([rentabob]),
          tripCost: isHasBrand([driveme, edrivers, rentabob]),
          insurance: isHasBrand([driveme]),
          promoAmount: isHasBrand([driveme, edrivers, rentabob]),
          discountType: isHasBrand([edrivers]),
          discountAmount: isHasBrand([edrivers]),
          startRideToPassenger: isHasBrand([driveme, edrivers, rentabob]),
          arrivalAtPickup: isHasBrand([rentabob]),
          startRideWithPassenger: isHasBrand([driveme, edrivers, rentabob]),
          endRideWithPassenger: isHasBrand([driveme, edrivers, rentabob]),
          arrivalAtHome: isHasBrand([driveme, edrivers, rentabob]),
          alreadyPaid: isHasBrand([])
        },
        declared: {
          publicTransport: isHasBrand([]),
          travelTime: isHasBrand([]),
          travelKm: isHasBrand([edrivers, rentabob]),
          travelKmTariff: isHasBrand([]),
          prebookedWaitingTime: isHasBrand([]),
          prebookedWaitingTimeTariff: isHasBrand([]),
          extraWaitingTime: isHasBrand([]),
          extraWaitingTimeTariff: isHasBrand([]),
          tripTime: isHasBrand([driveme]),
          minMinutes: isHasBrand([]),
          tripKm: isHasBrand([]),
          distance: isHasBrand([rentabob]),
          platformCost: isHasBrand([]),
          tariff: isHasBrand([]),
          intermediateStops: isHasBrand([rentabob]),
          intermediateStopCost: isHasBrand([]),
          outsideAreaFee: isHasBrand([]),
          tripCost: isHasBrand([]),
          insurance: isHasBrand([]),
          promoAmount: isHasBrand([]),
          discountType: isHasBrand([]),
          discountAmount: isHasBrand([]),
          startRideToPassenger: isHasBrand([edrivers, rentabob]),
          arrivalAtPickup: isHasBrand([rentabob]),
          startRideWithPassenger: isHasBrand([edrivers, rentabob]),
          endRideWithPassenger: isHasBrand([edrivers, rentabob]),
          arrivalAtHome: isHasBrand([edrivers, rentabob]),
          alreadyPaid: isHasBrand([])
        },
        driver: {
          publicTransport: isHasBrand([driveme]),
          travelTime: isHasBrand([]),
          travelKm: isHasBrand([edrivers, rentabob]),
          travelKmTariff: isHasBrand([edrivers, rentabob]),
          prebookedWaitingTime: isHasBrand([rentabob]),
          prebookedWaitingTimeTariff: isHasBrand([rentabob]),
          extraWaitingTime: isHasBrand([]),
          extraWaitingTimeTariff: isHasBrand([]),
          tripTime: isHasBrand([driveme, edrivers, rentabob]),
          minMinutes: isHasBrand([driveme, edrivers, rentabob]),
          tripKm: isHasBrand([]),
          distance: isHasBrand([]),
          platformCost: isHasBrand([driveme, edrivers, rentabob]),
          tariff: isHasBrand([driveme, edrivers, rentabob]),
          intermediateStops: isHasBrand([]),
          intermediateStopCost: isHasBrand([]),
          tripCost: isHasBrand([driveme, edrivers, rentabob]),
          outsideAreaFee: isHasBrand([]),
          insurance: isHasBrand([]),
          promoAmount: isHasBrand([]),
          discountType: isHasBrand([]),
          discountAmount: isHasBrand([]),
          startRideToPassenger: isHasBrand([driveme, edrivers, rentabob]),
          arrivalAtPickup: isHasBrand([rentabob]),
          startRideWithPassenger: isHasBrand([driveme, edrivers, rentabob]),
          endRideWithPassenger: isHasBrand([driveme, edrivers, rentabob]),
          arrivalAtHome: isHasBrand([driveme, edrivers, rentabob]),
          alreadyPaid: isHasBrand([rentabob])
        },
        passenger: {
          publicTransport: isHasBrand([driveme]),
          travelTime: isHasBrand([]),
          travelKm: isHasBrand([edrivers, rentabob]),
          travelKmTariff: isHasBrand([edrivers, rentabob]),
          prebookedWaitingTime: isHasBrand([rentabob]),
          prebookedWaitingTimeTariff: isHasBrand([rentabob]),
          extraWaitingTime: isHasBrand([rentabob]),
          extraWaitingTimeTariff: isHasBrand([rentabob]),
          tripTime: isHasBrand([driveme, edrivers]),
          minMinutes: isHasBrand([driveme, edrivers]),
          tripKm: isHasBrand([]),
          distance: isHasBrand([rentabob]),
          platformCost: isHasBrand([driveme, edrivers, rentabob]),
          tariff: isHasBrand([driveme, edrivers]),
          intermediateStops: isHasBrand([rentabob]),
          intermediateStopCost: isHasBrand([rentabob]),
          outsideAreaFee: isHasBrand([rentabob]),
          tripCost: isHasBrand([driveme, edrivers, rentabob]),
          insurance: isHasBrand([driveme]),
          promoAmount: isHasBrand([driveme, edrivers, rentabob]),
          discountType: isHasBrand([edrivers]),
          discountAmount: isHasBrand([edrivers]),
          startRideToPassenger: isHasBrand([driveme, edrivers, rentabob]),
          arrivalAtPickup: isHasBrand([rentabob]),
          startRideWithPassenger: isHasBrand([driveme, edrivers, rentabob]),
          endRideWithPassenger: isHasBrand([driveme, edrivers, rentabob]),
          arrivalAtHome: isHasBrand([driveme, edrivers, rentabob]),
          alreadyPaid: isHasBrand([rentabob])
        }
      }
    },
    electedSubscribedDriverUser () {
      if (this.editDialog.data.driver && this.subscribedDrivers && this.subscribedDrivers.length) {
        return this.subscribedDrivers.find(item => item.driver && item.driver.id === this.editDialog.data.driver.id)
      }

      return null
    },
    manualPriceActive () {
      const isInvoicedOrMore = this.editDialog.data.statusSequence >= this.rideStatus.invoiced.sequence
      const isDriverInvoiced = this.editDialog.data.statusSequence === this.rideStatus.driver_invoiced.sequence
      const isPassengerInvoiced = this.editDialog.data.statusSequence === this.rideStatus.passenger_invoiced.sequence
      const isPaidOrLess = this.editDialog.data.statusSequence <= this.rideStatus.paid.sequence
      
      if (isInvoicedOrMore) {
        this.disabledDriverType = true
        return { quoted: false, declared: false, passenger: false, driver: false }
      }
      if (isDriverInvoiced) {
        this.disabledDriverType = false
        return { quoted: false, declared: false, passenger: true, driver: false }
      }
      if (isPassengerInvoiced) {
        this.disabledDriverType = true
        return { quoted: false, declared: false, passenger: false, driver: true }
      }
      if (isPaidOrLess) {
        this.disabledDriverType = false
        return { quoted: true, declared: true, passenger: true, driver: true }
      }
      this.disabledDriverType = false
      return { quoted: true, declared: true, passenger: true, driver: true }

    },
    priceActive () {
      // const quoted = !this.editDialog.data.statusSequence || this.editDialog.data.statusSequence < this.rideStatus.declared.sequence
      const quoted = this.manualPriceActive.quoted
      const declared = this.editDialog.data.statusSequence >= this.rideStatus.ended.sequence && this.manualPriceActive.declared
      const driver = this.editDialog.data.statusSequence >= this.rideStatus.declared.sequence && this.manualPriceActive.driver
      const passenger = this.editDialog.data.statusSequence >= this.rideStatus.declared.sequence && this.editDialog.data.statusSequence < this.rideStatus.declaration_approved.sequence && !this.isInitPriceDrivers && this.manualPriceActive.passenger

      return {
        quoted,
        declared,
        passenger,
        driver
      }
    }
  },
  watch: {
    tariff: {
      handler: function (newValue) {
        for (let item of this.subscribedDrivers) {
          if (this.tariff[item.id] && !lodash.isEqual((item.price && item.price.trip) || 0.00, this.tariff[item.id])) {
            this.$set(item, 'isFixed', true)
          }
        }
      },
      deep: true
    },
    driversSubscribeList: {
      handler: async function (newValue) {
        newValue.forEach(item => { item.disable = true })
      },
      deep: true
    },
    filterProps: {
      handler: async function (newValue, oldValue) {
        if (oldValue ? !lodash.isEqual(oldValue, newValue) : false) {
          this.getRides(newValue)
        }
      },
      deep: true
    },
    selectedBrandName: {
      handler: async function () {
        if (this.isEdrivers && !this.editDialog.data.prebookedWaitingTime) {
          this.editDialog.data.prebookedWaitingTime = this.prebookedWaitingTimeList[0] && this.prebookedWaitingTimeList[0].id
          this.prebookedWaitingTimeList[0] && await this.updateWaitingTime(this.prebookedWaitingTimeList[0].id)
        }
        if (!this.editDialog.data.id) {
          this.initPrebookedWaitingTimeQuoted()
        }
      },
      deep: true
    },
    subscribedDrivers (newValue, oldValue) {
      for (let item of newValue) {
        this.$set(this.tariff, item.id, (item.price && item.price.trip) || 0.00)
        this.$set(this.insurance, item.id, (item.price && item.price.expenses.insurance) || 0.00)
        this.$set(this.platformCost, item.id, (item.price && item.price.expenses.platformCost) || 0.00)
        this.$set(this.publicTransport, item.id, (item.price && item.price.expenses.publicTransport) || 0.00)
      }
    },
    'editDialog.data.advanceBookingDiscount': {
      handler: async function () {
        if (this.isRentabob) {
          // await this.initTripCostQuoted()
          // await this.initTripCostPassenger()
          await this.loadFinanceExpenses()
        }
      },
      deep: true
    },
    'editDialog.data.brand': {
      handler: async function (after, before) {
        const newBrandId = (after && after.id) || after
        const oldBrandId = (before && before.id) || before
        if (this.editDialogCopyCompare && !lodash.isEqual(oldBrandId, newBrandId)) {
          this.initInsuranceQuoted()

          this.buttonLoader = true
          this.editDialog.data.driverId = null
          this.editDialog.data.passengerId = null
          this.request('GET', '/driver-types?pageSize=9999', { params: { brands: this.selectedBrandId } }, async ({ data }) => {
            this.typesList = data.data
            this.editDialog.data.driverType = null

            if (newBrandId && oldBrandId && !lodash.isEqual(newBrandId, oldBrandId)) {
              await this.onChangeDriverType()
            }
          }, null, () => { this.buttonLoader = false })

          // this.initEstimate()
          if (!this.editDialog.data.id) {
            this.initPriceQuoted()
          }

          if (!this.editDialog.data.id) {
            this.differentStartAtEndAt()
          }

          this.buttonLoader = false
        }
      },
      deep: true
    },
    'editDialog.data.price': {
      handler: async function (after, before) {
        if (this.editDialogCopyCompare && this.editDialog.data.id && !this.priceWatchDisabled) {
          this.loadFinanceExpenses()
        }
      },
      deep: true
    },
    'editDialog.stopsData': {
      async handler (after, before) {
        if (this.editDialogCopyCompare) {
          if (after && this.editDialog.data.price && after.length !== this.editDialog.data.price.quoted.intermediateStops) {
            await this.initIntermediateStopsQuoted()
          }

          await this.initPublicTransportQuoted()

          if (this.isRentabob) {
            await this.initTariffQuoted()
            // if (!this.editDialog.data.price.quoted.distance) {
              await this.initTripCostQuoted()
            // }

            if (!this.editDialog.data.price.passenger.distance) {
              await this.initTripCostPassenger()
            }
          }

          await this.initTariffDriver()
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    const queryString = { ...this.$route.query, ...this.$store.getters.getAdminRideOverviewFilters }

    if (queryString) {
      this.$router.replace({ query: { ...queryString, rideId: this.$route.query.rideId || queryString.rideId } }).catch(err => err)
    }

    this.isAdmin = this.$store.getters.getUser.roles.includes('ROLE_ADMIN')
    let getStatusList = () => new Promise((resolve) => {
      this.request('GET', '/rides/statuses', {}, ({ data }) => {
        this.statusesList = data
        resolve()
      })
    })

    let getDiscountList = () => new Promise((resolve) => {
      this.request('GET', '/advance-booking-discounts', {}, ({ data }) => {
        this.discountsList = data.data
        resolve()
      })
    })

    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }

    await Promise.all([getStatusList(), getDiscountList(), this.getRegionList()]).then(() => {
      this.getRides(this.filterProps, (_rides) => {
        const rideId = this.$route.query && this.$route.query.rideId

        if (rideId) {
          this.openEditDialog(rideId)
        }
      })
    })

    this.request('GET', '/expenses-types', {}, ({ data }) => {
      this.expensesTypes = data.data
    })
  },
  methods: {
    onChangeStopsTimeline (stops) {
      this.editDialog.stopsData = stops
    },
    onClickArrivalAtHome (from) {
      if (this.editDialog.data.price[from] && !this.editDialog.data.price[from].arrivalAtHome && this.editDialog.data.price[from].endRideWithPassenger && !this.isChangeDriverPrice(from, 'arrivalAtHome')) {
        this.$set(this.editDialog.data.price[from], 'arrivalAtHome', this.editDialog.data.price[from].endRideWithPassenger)
        if (from === 'quoted') { this.onChangeArrivalAtHomeQuoted('quoted') }
        if (from === 'declared') { this.onChangeArrivalAtHomeDeclared('declared') }
        if (from === 'driver') { this.onChangeArrivalAtHomeDriver('driver') }
        if (from === 'passenger') { this.onChangeArrivalAtHomePassenger('passenger') }
      }
    },
    async addHourEndAt (hoursCount) {
      if (this.editDialog.data.startAt) {
        this.editDialog.data.endAt = moment(this.editDialog.data.startAt).add(hoursCount, 'hours').format('YYYY-MM-DDTHH:mm')
        await this.onChangeEndAt()
      }
    },
    isChangeDriverPrice (from, key) {
      if (from && key && this.editDialog.data.id && this.editDialog.data.price && this.editDialog.data.price[from] && this.priceClass[from]) {
        if (from === 'declared' && this.editDialog.data.price[from][key] && !this.priceClass[from][key]) {
          return true
        }
      }
      return false
    },
    initPriceClassItem (from) {
      const result = {}
      if (this.editDialog.data.price[from].changes && this.editDialog.data.price[from].changes.manual) {
        this.editDialog.data.price[from].changes.manual.map(item => {
          result[item] = PRICE_BORDER_COLOR.manualAfter
          this.$set(this.inputPriceChange[from], item, { value: this.editDialog.data.price[from][item], type: PRICE_BORDER_COLOR.manualAfter })
        })
      }

      if (this.editDialog.data.price[from].changes && this.editDialog.data.price[from].changes.auto) {
        this.editDialog.data.price[from].changes.auto.map(item => {
          result[item] = PRICE_BORDER_COLOR.auto
        })
      }

      return result
    },
    getPriceClassItem (from) {
      if (this.editDialogCopyCompare) {
        let item = {}
        const oldPrice = lodash.cloneDeep(this.editDialogCopyCompare.data.price)

        for (const [key, value] of Object.entries(this.editDialog.data.price[from])) {
          if (!Number.isFinite(value)) {
            value = value ? value : null
          }

          oldPrice[from][key] = oldPrice[from].hasOwnProperty(key) ? oldPrice[from][key] : null
          const isInputChange = this.inputPriceChange[from] && this.inputPriceChange[from][key] && lodash.isEqual(value, this.inputPriceChange[from][key].value)
          const isEqual = lodash.isEqual(value, oldPrice[from][key])

          if (!isEqual && isInputChange) {
            item[key] = PRICE_BORDER_COLOR.manual
          } else if (!isEqual) {
            item[key] = PRICE_BORDER_COLOR.auto
            // this.inputPriceChange[from][key] = null
          } else if (this.priceClassInit[from] && this.priceClassInit[from][key]) {
            item[key] = this.priceClassInit[from][key]
            // this.inputPriceChange[from][key] = null
          } else {
            item[key] = ''
            // this.inputPriceChange[from][key] = null
          }
        }
        return item
      }

      return {}
    },
    async onChangeIsFixedPriceCheckbox () {
      if (this.isDriveme) {
        await this.initTripCostDriver()
      }

      if (this.isDriveme || this.isEdrivers) {
        await this.initTripCostPassenger()
      }
    },
    async onChangeArrivalAtHomeQuoted (isManual = false) {
      await this.initTripTimeQuoted(isManual)
    },
    async onChangeDiscount (isManual = false) {
      if (this.isRentabob) {
        this.initExtraWaitingTimeTariffQuoted(isManual)
        this.initExtraWaitingTimeTariffPassenger(isManual)
        await this.initPrebookedWaitingTimeTariffQuoted(isManual)
        await this.initPrebookedWaitingTimeTariffPassenger(isManual)
      }
    },
    onChangeEndRideWithPassengerQuoted (isManual = false) {
      if (this.isEdrivers && !this.editDialog.data.declaredAt) {
        this.initEndRideWithPassengerDeclared(isManual)
      }
    },
    onChangeStartRideWithPassengerQuoted (isManual = false) {
      if (this.isEdrivers && !this.editDialog.data.declaredAt) {
        this.initStartRideWithPassengerDeclared(isManual)
      }
    },
    async onChangeStartRideToPassengerQuoted (isManual = false) {
      if ((this.isEdrivers || this.isRentabob) && !this.editDialog.data.declaredAt) {
        await this.initStartRideToPassengerDeclared(isManual)
      }

      if (this.isRentabob) {
        await this.initArrivalAtPickupQuoted(isManual)
        // await this.initExtraWaitingTimeQuoted(isManual)
      }

      await this.initTripTimeQuoted(isManual)
    },
    async onChangePrebookedWaitingTimeQuoted (isManual = false) {
      if (this.isRentabob) {
        this.initPrebookedWaitingTimeDeclared(isManual)
        await this.initArrivalAtPickupQuoted(isManual)
        // await this.initExtraWaitingTimeQuoted(isManual)
      }
    },
    onChangeTravelTimeQuoted (isManual = false) {
      if (this.isRentabob && !this.editDialog.data.declaredAt) {
        this.initTravelTimeDeclared(isManual)
      }
    },
    async onChangeArrivalAtHomeDeclared (isManual = false) {
      if (this.isEdrivers) {
        await this.initArrivalAtHomeDriver(isManual)
      }
    },
    onChangeEndRideWithPassengerDeclared (isManual = false) {
      if (this.isEdrivers) {
        this.initEndRideWithPassengerDriver(isManual)
      }
    },
    onChangeEndRideWithPassengerDriver (isManual = false) {
      this.initEndRideWithPassengerPassenger(isManual)
    },
    onChangeStartRideWithPassengerDeclared (isManual = false) {
      if (this.isEdrivers) {
        this.initStartRideWithPassengerDriver(isManual)
      }
    },
    onChangeStartRideWithPassengerDriver (isManual = false) {
      this.initStartRideWithPassengerPassenger(isManual)
    },
    onChangeDiscountAmountQuoted (isManual = false) {
      if (this.isEdrivers) {
        this.initDiscountAmountPassenger(isManual)
      }

      if (this.isEdrivers && this.editDialog.data.driverType) {
        this.initTariffQuoted(isManual)
      }
    },
    onChangeDiscountTypeQuoted (isManual = false) {
      if (this.isEdrivers) {
        this.initDiscountTypePassenger(isManual)
      }

      if (this.isEdrivers && this.editDialog.data.driverType) {
        this.initTariffQuoted(isManual)
      }
    },
    onChangePromoAmountQuoted (isManual = false) {
      this.initPromoAmountPassenger(isManual)
    },
    onChangeInsuranceQuoted (isManual = false) {
      if (this.isDriveme) {
        this.initInsurancePassenger(isManual)
      }
    },
    async onChangeIntermediateStopCostPassenger (isManual = false) {
      if (this.isRentabob) {
        // await this.initTripCostPassenger(isManual)
      }
    },
    async onChangeMinMinutesPassenger (isManual = false) {
      if ((this.isDriveme || this.isEdrivers) && !this.isFixedPriceCheckbox) {
        await this.initTripCostPassenger(isManual)
      }
    },
    async onChangeTariffPassenger (isManual = false, isForce = false) {
      if (this.isEdrivers || (this.isDriveme && !this.isFixedPriceCheckbox)) {
        await this.initTripCostPassenger(isManual, isForce)
      }
    },
    async onChangePublicTransportPassenger (isManual = false) {
      if (this.isDriveme && !this.isFixedPriceCheckbox) {
        await this.initTripCostPassenger(isManual)
      }
    },
    async onChangeTripTimePassenger (isManual = false) {
      if ((this.isDriveme || this.isEdrivers) && !this.isFixedPriceCheckbox) {
        await this.initTripCostPassenger(isManual)
      }
    },
    async onChangeMinMinutesDriver (isManual = false) {
      if (!(this.isFixedPriceCheckbox && this.isDriveme)) {
        await this.initTripCostDriver(isManual)
      }
    },
    async onChangeTariffDriver (isManual = false) {
      if (!(this.isFixedPriceCheckbox && this.isDriveme)) {
        await this.initTripCostDriver(isManual)
      }
    },
    async onChangeTripCostQuoted (isManual = false) {
      if (this.isFixedPriceCheckbox && this.isDriveme) {
        await this.initTripCostDriver(isManual)
      }

      if (this.isFixedPriceCheckbox && (this.isDriveme || this.isEdrivers)) {
        await this.initTripCostPassenger(isManual)
      }
    },
    async onChangeTripCostDriver (isManual = false) {
      // await this.initPlatformCostDriver(isManual)
    },
    async onChangeIntermediateStopsDeclared (isManual = false) {
      if (this.isRentabob) {
        await this.initIntermediateStopsPassenger(isManual)
      }
    },
    onChangePlatformCostQuoted (isManual = false) {
      this.initPlatformCostPassenger(isManual)
    },
    async onChangeStartRideToPassengerDeclared (isManual = false) {
      if (this.isEdrivers) {
        await this.initStartRideToPassengerDriver(isManual)
      }

      if (this.isRentabob) {
        await this.initExtraWaitingTimeDeclared(isManual)
      }
    },
    async onChangeStartRideToPassengerDriver (isManual = false) {
      if (this.isEdrivers) {
        await this.initTripTimeDriver(isManual)
      }
      if(this.isRentabob) {
        await this.initExtraWaitingTimeDriver(isManual)
      }

      await this.initStartRideToPassengerPassenger(isManual)
    },
    async onChangeStartRideToPassengerPassenger (isManual = false) {
      if (this.isEdrivers) {
        await this.initTripTimePassenger(isManual)
      }
    },
    async onChangeArrivalAtHomeDriver (isManual = false) {
      if (this.isEdrivers) {
        await this.initTripTimeDriver(isManual)
      }

      await this.initArrivalAtHomePassenger(isManual)
    },
    async onChangeArrivalAtHomePassenger (isManual = false) {
      if (this.isEdrivers) {
        await this.initTripTimePassenger(isManual)
      }
    },
    async onChangeTripTimeDeclared (isManual = false) {
      if ((!this.isFixedPriceCheckbox && this.isDriveme) || this.isRentabob) {
        await this.initTripTimeDriver(isManual)
      }
    },
    async onChangePrebookedWaitingTimeTariffQuoted (isManual = false) {
      if (this.isRentabob) {
        this.initPrebookedWaitingTimeTariffPassenger(isManual)
        // await this.initExtraWaitingTimeQuoted(isManual)
      }
    },
    async onChangePrebookedWaitingTimeTariffDeclared (isManual = false) {
      if (this.isRentabob) {
        this.initPrebookedWaitingTimeTariffDriver(isManual)
        await this.initExtraWaitingTimeDeclared(isManual)
      }
    },
    async onChangePrebookedWaitingTimeTariffDriver (isManual = false) {
      if (this.isRentabob) {
        await this.initExtraWaitingTimeDriver(isManual)
      }
    },
    async onChangePrebookedWaitingTimeDriver (isManual = false) {
      if (this.isRentabob) {
        this.initPrebookedWaitingTimePassenger(isManual)
        await this.initExtraWaitingTimeDriver(isManual)
      }
    },
    async onChangePrebookedWaitingTimeDeclared (isManual = false) {
      if (this.isRentabob) {
        await this.initPrebookedWaitingTimeDriver(isManual)
        await this.initExtraWaitingTimeDeclared(isManual)
      }
    },
    onChangeTravelKmTariffQuoted (isManual = false) {
      if (this.isEdrivers) {
        this.initTravelKmTariffPassenger(isManual)
      }
    },
    onChangeTravelKmDriver (isManual = false) {
      if (this.isEdrivers) {
        this.initTravelKmPassenger(isManual)
      }
    },
    onChangeTravelKmDeclared (isManual = false) {
      if (this.isEdrivers) {
        this.initTravelKmDriver(isManual)
      }

      if (this.isRentabob) {
        this.initTravelKmPassenger(isManual)
      }
    },
    onChangeTravelTimeDriver (isManual = false) {
      if (this.isEdrivers) {
        this.initTravelTimePassenger(isManual)
      }
    },
    async onChangeTripTimeDriver (isManual = false) {
      if (this.isEdrivers) {
        this.initTravelTimeDriver(isManual)
      }

      if (!(this.isFixedPriceCheckbox && this.isDriveme)) {
        await this.initTripCostDriver(isManual)
      }

      if (this.isDriveme) {
        await this.initTripTimePassenger(isManual)
      }
    },
    onChangeTravelTimeDeclared (isManual = false) {
      if (this.isRentabob) {
        this.initTravelTimeDriver(isManual)
      }
    },
    async onChangeIntermediateStopCostQuoted (isManual = false) {
      if (this.isRentabob) {
        // await this.initTripCostQuoted(isManual)
        await this.initIntermediateStopCostPassenger(isManual)
      }
    },
    async onChangeIntermediateStopsQuoted (isManual = false) {
      if (this.isRentabob) {
        // await this.initTripCostQuoted(isManual)
        await this.initIntermediateStopsDeclared(isManual)
      }
    },
    async onChangeIntermediateStopsPassenger (isManual = false) {
      if (this.isRentabob) {
        // await this.initTripCostPassenger(isManual)
      }
    },
    async onChangeTariffQuoted (isManual = false, isForce = false) {
      await this.initTariffPassenger(isManual, isForce)
      
      if (this.isDriveme || this.isEdrivers) {
        await this.initTripCostQuoted(isManual)
      }
    },
    async onChangePublicTransportQuoted (isManual = false) {
      await this.initPublicTransportDriver(isManual)
      if (this.isDriveme) {
        await this.initTripCostQuoted(isManual)
      }
    },
    async onChangePublicTransportDriver (isManual = false) {
      await this.initPublicTransportPassenger(isManual)

      if (this.isDriveme && !this.isFixedPriceCheckbox) {
        await this.initTripCostDriver(isManual)
      }
    },
    async onChangeMinMinutesQuoted (isManual = false) {
      if (this.isDriveme) {
        await this.initMinMinutesDriver(isManual)
      }

      if (this.isDriveme || this.isEdrivers) {
        await this.initMinMinutesPassenger(isManual)
      }

      if (this.isDriveme || this.isEdrivers) {
        await this.initTripCostQuoted(isManual)
      }
    },
    async onChangeTripTimeQuoted (isManual = false) {
      if (this.isDriveme || this.isEdrivers) {
        await this.initTripCostQuoted(isManual)
      }

      if (this.isEdrivers) {
        this.initTravelTimeQuoted(isManual)
      }

      if ((this.isDriveme || this.isRentabob) && !this.editDialog.data.declaredAt) {
        await this.initTripTimeDeclared(isManual)
      }
    },
    async onChangeExtraWaitingTimeDeclared (isManual = false) {
      if (this.isRentabob) {
        this.initExtraWaitingTimeDriver(isManual)
      }
    },
    async onChangeExtraWaitingTimeTariffDeclared (isManual = false) {
      if (this.isRentabob) {
        this.initExtraWaitingTimeTariffDriver(isManual)
        await this.initExtraWaitingTimeDeclared(isManual)
      }
    },
    async onChangeExtraWaitingTimeTariffDriver (isManual = false) {
      if (this.isRentabob) {
        await this.initExtraWaitingTimeDriver(isManual)
      }
    },
    async onChangeArrivalAtPickupQuoted (isManual = false) {
      if (this.isRentabob) {
        await this.initArrivalAtPickupDeclared(isManual)
        // await this.initExtraWaitingTimeQuoted(isManual)
      }
      this.initStartRideWithPassengerQuoted(isManual)
      this.initEndRideWithPassengerQuoted(isManual)
      await this.initArrivalAtHomeQuoted(isManual)
    },
    async onChangeArrivalAtPickupDeclared (isManual = false) {
      if (this.isRentabob) {
        await this.initExtraWaitingTimeDeclared(isManual)
      }
    },
    async onChangeArrivalAtPickupDriver (isManual = false) {
      if (this.isRentabob) {
        await this.initExtraWaitingTimeDriver(isManual)
      }
    },
    async onChangeTripKmQuoted (isManual = false) {
      if (this.isRentabob) {
        await this.initTripKmDeclared(isManual)
      }
    },
    async onChangeTripKmDeclared (isManual = false) {
      if (this.isRentabob) {
        await this.initTripKmDriver(isManual)
        await this.initTripKmPassenger(isManual)
      }
    },
    async onChangeDistanceQuoted (isManual = false) {
      if (this.isRentabob) {
        await this.initTripCostQuoted(isManual)
      }
    },
    async onChangeDistancePassenger (isManual = false) {
      if (this.isRentabob) {
        await this.initTripCostPassenger(isManual)
      }
    },
    convertMinutesToTimeFormat(minutes) {
      const time = moment().startOf('day').add(minutes, 'minutes');
      return time.format('HH:mm');
    },
    async onChangeStartAt () {
      await this.initStartRideToPassengerQuoted()
      
      if (this.isDriveme) {
        await this.initPublicTransportQuoted()
      }

      if (!this.editDialog.stopsData || !this.editDialog.stopsData[0].departAt) {
        this.initStartRideWithPassengerQuoted()
      }

      await this.initTripTimeQuoted()
      await this.initTariffDriver()

      if (this.isEdrivers) {
        this.initTravelTimeQuoted()
        this.initTravelTimeDriver()
      }

      if (this.isRentabob) {
        // await this.initTariffQuoted()
        // if (!this.editDialog.data.price.quoted.distance) {
          await this.initTripCostQuoted()
        // }

        if (!this.editDialog.data.price.passenger.distance) {
          await this.initTripCostPassenger()
        }
      }

      this.differentStartAtEndAt()
    },
    async onChangeEndAt () {
      if (!this.editDialog.stopsData || !this.editDialog.stopsData[this.editDialog.stopsData.length - 1].arriveAt) {
        this.initEndRideWithPassengerQuoted()
      }

      await this.initArrivalAtHomeQuoted()

      await this.initTripTimeQuoted()
      
      if (this.isEdrivers) {
        this.initTravelTimeQuoted()
        this.initTravelTimeDriver()
      }

      this.differentStartAtEndAt()
    },
    async differentStartAtEndAt () {
      if (this.isEdrivers) {
        const different = moment.duration(moment(this.editDialog.data.endAt).diff(moment(this.editDialog.data.startAt))).asHours()

        if (different < 1) {
          this.editDialog.data.endAt = moment(this.editDialog.data.startAt).add(1, 'hours').format('YYYY-MM-DDTHH:mm')
          await this.onChangeEndAt()
        }
        return different
      }
      return null
    },
    async initPriceQuoted () {
      this.initTripKmQuoted()
      await this.initStartRideToPassengerQuoted()
      this.initStartRideWithPassengerQuoted()
      this.initEndRideWithPassengerQuoted()
      await this.initArrivalAtHomeQuoted()
      await this.initPublicTransportQuoted()
      this.initTravelKmQuoted()
      this.initTravelKmTariffQuoted()
      await this.initPrebookedWaitingTimeQuoted()
      await this.initArrivalAtPickupQuoted()
      await this.initPrebookedWaitingTimeTariffQuoted()
      await this.initExtraWaitingTimeQuoted()
      this.initExtraWaitingTimeTariffQuoted()
      await this.initTripTimeQuoted()
      this.initTravelTimeQuoted()
      await this.initMinMinutesQuoted()
      this.initPlatformCostQuoted()
      await this.initTariffQuoted()
      await this.initIntermediateStopsQuoted()
      this.initOutsideAreaFeeQuoted()
      await this.initIntermediateStopCostQuoted()
      await this.initTripCostQuoted()
      this.initInsuranceQuoted()
      this.initPromoAmountQuoted()
      this.initDiscountTypeQuoted()
      this.initDiscountAmountQuoted()
      this.initAlreadyPaidQuoted()
    },
    async initPriceDeclared () {
      this.initTripKmDeclared()
      await this.initStartRideToPassengerDeclared()
      await this.initArrivalAtPickupDeclared()
      this.initStartRideWithPassengerDeclared()
      this.initEndRideWithPassengerDeclared()
      this.initArrivalAtHomeDeclared()
      this.initPublicTransportDeclared()
      this.initTravelKmDeclared()
      this.initTravelKmTariffDeclared()
      this.initPrebookedWaitingTimeDeclared()
      this.initPrebookedWaitingTimeTariffDeclared()
      this.initExtraWaitingTimeTariffDeclared()
      await this.initExtraWaitingTimeDeclared()
      await this.initTripTimeDeclared()
      this.initTravelTimeDeclared()
      this.initMinMinutesDeclared()
      this.initPlatformCostDeclared()
      this.initTariffDeclared()
      await this.initIntermediateStopsDeclared()
      this.initOutsideAreaFeeDeclared()
      this.initIntermediateStopCostDeclared()
      this.initTripCostDeclared()
      this.initInsuranceDeclared()
      this.initPromoAmountDeclared()
      this.initDiscountTypeDeclared()
      this.initDiscountAmountDeclared()
      this.initAlreadyPaidDeclared()
    },
    async initPriceDriver () {
      this.isInitPriceDrivers = true
      this.initTripKmDriver()
      await this.initStartRideToPassengerDriver()
      await this.initArrivalAtPickupDriver()
      this.initStartRideWithPassengerDriver()
      this.initEndRideWithPassengerDriver()
      await this.initArrivalAtHomeDriver()
      await this.initPublicTransportDriver()
      this.initTravelKmDriver()
      this.initTravelKmTariffDriver()
      await this.initPrebookedWaitingTimeDriver()
      this.initPrebookedWaitingTimeTariffDriver()
      await this.initExtraWaitingTimeDriver()
      this.initExtraWaitingTimeTariffDriver()
      await this.initTripTimeDriver()
      this.initTravelTimeDriver()
      await this.initMinMinutesDriver()
      await this.initPlatformCostDriver()
      await this.initTariffDriver()
      this.initIntermediateStopsDriver()
      this.initOutsideAreaFeeDriver()
      this.initIntermediateStopCostDriver()
      await this.initTripCostDriver()
      this.initInsuranceDriver()
      this.initPromoAmountDriver()
      this.initDiscountTypeDriver()
      this.initDiscountAmountDriver()
      this.initAlreadyPaidDriver()
      this.isInitPriceDrivers = false
    },
    async initPricePassenger () {
      this.initTripKmPassenger()
      await this.initStartRideToPassengerPassenger()
      await this.initArrivalAtPickupPassenger()
      this.initStartRideWithPassengerPassenger()
      this.initEndRideWithPassengerPassenger()
      await this.initArrivalAtHomePassenger()
      await this.initPublicTransportPassenger()
      this.initTravelKmPassenger()
      this.initTravelKmTariffPassenger()
      this.initPrebookedWaitingTimePassenger()
      this.initPrebookedWaitingTimeTariffPassenger()
      this.initExtraWaitingTimePassenger()
      this.initExtraWaitingTimeTariffPassenger()
      await this.initTripTimePassenger()
      this.initTravelTimePassenger()
      await this.initMinMinutesPassenger()
      this.initPlatformCostPassenger()
      await this.initTariffPassenger()
      await this.initIntermediateStopsPassenger()
      this.initOutsideAreaFeePassenger()
      await this.initIntermediateStopCostPassenger()
      await this.initTripCostPassenger()
      this.initInsurancePassenger()
      this.initPromoAmountPassenger()
      this.initDiscountTypePassenger()
      this.initDiscountAmountPassenger()
      this.initAlreadyPaidPassenger()
    },
    //////////////////////////////////////
    async initPublicTransportQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'publicTransport') && (this.priceActive.quoted || isManual === 'quoted')) {
        const func = () => {
          return new Promise((resolve, reject) => {
            const body = {
              data: {
                stops: this.editDialog.stopsData.map(item => item.address),
                startAt: this.editDialog.data.startAt
              }
            }
            this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/public-transport`, body, async ({ data }) => {
              this.setPrice('quoted', 'publicTransport', data.publicTransport)
              await this.onChangePublicTransportQuoted(isManual)
              resolve(data)
            }, null, (error) => reject(error))
          })
        }

        if (this.isDriveme) {
          if (this.editDialog.stopsData && this.editDialog.stopsData.length >= 2 && this.selectedBrandName) {
            await func()
          }
        }
      }
      //If A->B ride, based on PT reimbursement calc, otherwise 0 DM
    },
    initPublicTransportDeclared (isManual = false) {},
    async initPublicTransportDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'publicTransport') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isDriveme) {
          this.setPrice('driver', 'publicTransport', this.editDialog.data.price.quoted.publicTransport)

          await this.onChangePublicTransportDriver(isManual)
        }
      }
      // From quoted DM
    },
    async initPublicTransportPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'publicTransport') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isDriveme) {
          this.setPrice('passenger', 'publicTransport', this.editDialog.data.price.driver.publicTransport)

          await this.onChangePublicTransportPassenger(isManual)
        }
      }
      // From driver invoice DM
    },
    //////////////////////////////////////
    initTravelTimeQuoted (isManual = false) {
      // if (!this.isChangeDriverPrice('quoted', 'travelTime') && (this.priceActive.quoted || isManual === 'quoted')) {
        // if (this.isEdrivers) {
        //   if (this.editDialog.data.endAt && this.editDialog.data.startAt && this.editDialog.data.price.quoted.tripTime) {
        //     const startEndDiff = moment.duration(moment(this.editDialog.data.endAt).diff(moment(this.editDialog.data.startAt))).asMinutes()
        //     const tripTimeMinutes = moment.duration(this.editDialog.data.price.quoted.tripTime).asMinutes()
        //     this.setPrice('quoted', 'travelTime', this.formatMinutesToString(startEndDiff - tripTimeMinutes))

        //     this.onChangeTravelTimeQuoted(isManual)
        //   }
        // }
      // }

      // [Ride end at] - [Ride start at] - [Trip time] ED
    },
    initTravelTimeDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'travelTime') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isRentabob) {
          if (!this.editDialog.data.declaredAt) {
            this.setPrice('declared', 'travelTime', this.editDialog.data.price.quoted.travelTime)
          } else {
            const travelTime = this.getHistoryStatusDiffAsTime(this.rideStatus.underway.alias, this.rideStatus.waiting.alias)
            this.setPrice('declared', 'travelTime', travelTime)
          }

          this.onChangeTravelTimeDeclared(isManual)
        }
      }

      // From ride.status.history RaB
    },
    initTravelTimeDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'travelTime') && (this.priceActive.driver || isManual === 'driver')) {
        // if (this.isEdrivers) {
        //   if (this.editDialog.data.endAt && this.editDialog.data.startAt && this.editDialog.data.price.driver.tripTime) {
        //     const startEndDiff = moment.duration(moment(this.editDialog.data.endAt).diff(moment(this.editDialog.data.startAt))).asMinutes()
        //     const tripTimeMinutes = moment.duration(moment(this.editDialog.data.price.driver.tripTime)).asMinutes()
        //     this.setPrice('driver', 'travelTime', this.formatMinutesToString(startEndDiff - tripTimeMinutes))
        //   } else {
        //     this.setPrice('driver', 'travelTime', null)
        //   }

        //   this.onChangeTravelTimeDriver(isManual)
        // }

        if (this.isRentabob) {
          this.setPrice('driver', 'travelTime', this.editDialog.data.price.declared.travelTime)
          this.onChangeTravelTimeDriver(isManual)
        }
      }
      // [Ride end at] - [Ride start at] - [Trip time] ED
      // From declaration RaB
    },
    initTravelTimePassenger (isManual = false) {
      // if (!this.isChangeDriverPrice('passenger', 'travelTime') && (this.priceActive.passenger || isManual === 'passenger')) {
      //   if (this.isEdrivers) {
      //     this.setPrice('passenger', 'travelTime', this.editDialog.data.price.driver.travelTime)
      //   }
      // }
      // From driver invoice ED
    },
    //////////////////////////////////////
    initTravelKmQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'travelKm') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isEdrivers) {
          this.setPrice('quoted', 'travelKm', 0)
        }
      }

      // 0 ED
    },
    initTravelKmDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'travelKm') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isEdrivers) {
          this.setPrice('declared', 'travelKm', 0)

          this.onChangeTravelKmDeclared(isManual)
        }
        if (this.isRentabob) {
          this.setPrice('declared', 'travelKm', this.editDialog.data.price.driver.travelKm)

          this.onChangeTravelKmDeclared(isManual)
        }
      }

      // from driver invoice RaB
      // 0 ED
    },
    initTravelKmDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'travelKm') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isEdrivers) {
          this.setPrice('driver', 'travelKm', this.editDialog.data.price.declared.travelKm)

          this.onChangeTravelKmDriver(isManual)
        }
      }
      // From declaration ED
    },
    initTravelKmPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'travelKm') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isEdrivers) {
          this.setPrice('passenger', 'travelKm', this.editDialog.data.price.driver.travelKm)
        }

        if (this.isRentabob) {
          this.setPrice('passenger', 'travelKm', this.editDialog.data.price.declared.travelKm)
        }
      }
      // From driver invoice ED
    },
    //////////////////////////////////////
    initTravelKmTariffQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'travelKmTariff') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isEdrivers) {
          this.setPrice('quoted', 'travelKmTariff', this.appConfig.price.edrivers.travelKmTariff.passenger)

          this.onChangeTravelKmTariffQuoted(isManual)
        }
      }

      // CONFIG:PRICE_CALC_PASSENGER_TRAVEL_COST_KM ED
    },
    initTravelKmTariffDeclared (isManual = false) {},
    initTravelKmTariffDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'travelKmTariff') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isEdrivers) {
          this.setPrice('driver', 'travelKmTariff', this.appConfig.price.edrivers.travelKmTariff.driver)
        }
      }
      // CONFIG:PRICE_CALC_DRIVER_TRAVEL_COST_KM ED
    },
    initTravelKmTariffPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'travelKmTariff') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isEdrivers) {
          this.setPrice('passenger', 'travelKmTariff', this.editDialog.data.price.quoted.travelKmTariff)
        }
      }
      // From quoted ED
    },
    //////////////////////////////////////
    async initPrebookedWaitingTimeQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'prebookedWaitingTime') && (this.priceActive.quoted || isManual === 'quoted')) {
        // if (this.isRentabob) {
        //   if (this.editDialog.data.prebookedWaitingTime) {
        //     this.setPrice('quoted', 'prebookedWaitingTime', this.editDialog.data.prebookedWaitingTime)

        //     await this.onChangePrebookedWaitingTimeQuoted(isManual)
        //   }
        // }
        if (this.isRentabob) {
          if (!this.editDialog.data.id && this.selectedBrandName && this.appConfig && this.appConfig.price[this.selectedBrandName]) {
            const time = this.convertMinutesToTimeFormat(this.appConfig.price[this.selectedBrandName].freeWaitingTimeMinutes)
            this.setPrice('quoted', 'prebookedWaitingTime', time)
          }
        }
      }

      // ride.waiting_time RaB
    },
    initPrebookedWaitingTimeDeclared (isManual = false) {
      // if (!this.isChangeDriverPrice('declared', 'prebookedWaitingTime') && (this.priceActive.declared || isManual === 'declared')) {
      //   if (this.isRentabob) {
      //     if (!this.editDialog.data.declaredAt) {
      //       this.setPrice('declared', 'prebookedWaitingTime', this.editDialog.data.price.quoted.prebookedWaitingTime)
      //     } else {
      //       const prebookedWaitingTime = this.getHistoryStatusDiffAsTime(this.rideStatus.waiting.alias, this.rideStatus.started.alias)
      //       this.setPrice('declared', 'prebookedWaitingTime', prebookedWaitingTime)
      //     }

      //     this.onChangePrebookedWaitingTimeDeclared(isManual)
      //   }
      // }

      // From ride.status.history RaB
    },
    async initPrebookedWaitingTimeDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'prebookedWaitingTime') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isRentabob) {
          this.setPrice('driver', 'prebookedWaitingTime', this.editDialog.data.price.quoted.prebookedWaitingTime)

          await this.onChangePrebookedWaitingTimeDriver(isManual)
        }
      }
      // From declaration RaB
    },
    initPrebookedWaitingTimePassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'prebookedWaitingTime') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isRentabob) {
          this.setPrice('passenger', 'prebookedWaitingTime', this.editDialog.data.price.driver.prebookedWaitingTime)
        }
      }
      // From driver invoice RaB
    },
    //////////////////////////////////////
    async initPrebookedWaitingTimeTariffQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'prebookedWaitingTimeTariff') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isRentabob) {
          if (this.editDialog.data.advanceBookingDiscount) {
            const discount = this.discountsList.find(item => item.id === this.editDialog.data.advanceBookingDiscount)
            this.setPrice('quoted', 'prebookedWaitingTimeTariff', discount.prebookedWaitingTimeTariff)
          } else {
            this.setPrice('quoted', 'prebookedWaitingTimeTariff', this.appConfig.price.rentabob.prebookedWaitingTimeTariff)
          }
          await this.onChangePrebookedWaitingTimeTariffQuoted(isManual)
        }
      }

      // CONFIG:PRICE_CALC_WAITING_TIME_PRICE RaB
    },
    initPrebookedWaitingTimeTariffDeclared (isManual = false) {},
    initPrebookedWaitingTimeTariffDriver (isManual = false) {
      // if (!this.isChangeDriverPrice('driver', 'prebookedWaitingTimeTariff') && (this.priceActive.driver || isManual === 'driver')) {
      //   if (this.isRentabob) {
      //     this.setPrice('driver', 'prebookedWaitingTimeTariff', this.editDialog.data.price.quoted.prebookedWaitingTimeTariff)
      //   }
      // }
      // From driver.tariff RaB
    },
    initPrebookedWaitingTimeTariffPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'prebookedWaitingTimeTariff') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isRentabob) {
          if (this.editDialog.data.advanceBookingDiscount) {
            const discount = this.discountsList.find(item => item.id === this.editDialog.data.advanceBookingDiscount)
            this.setPrice('passenger', 'prebookedWaitingTimeTariff', discount.prebookedWaitingTimeTariff)
          } else {
            this.setPrice('passenger', 'prebookedWaitingTimeTariff', this.editDialog.data.price.quoted.prebookedWaitingTimeTariff)
          }
        }
      }
      // From quoted RaB
    },
    async initExtraWaitingTimeQuoted (isManual = false) {
      // if (!this.isChangeDriverPrice('quoted', 'extraWaitingTime') && (this.priceActive.quoted || isManual === 'quoted')) {
      //   if (this.isRentabob) {
      //     const func = () => {
      //       return new Promise((resolve, reject) => {
      //         const body = {
      //           data: {
      //             extraWaitingTimeTariff: this.editDialog.data.price.quoted.extraWaitingTimeTariff || undefined,
      //             prebookedWaitingTimeTariff: this.editDialog.data.price.quoted.prebookedWaitingTimeTariff || undefined,
      //             prebookedWaitingTime: this.editDialog.data.price.quoted.prebookedWaitingTime || undefined,
      //             arrivalAtPickup: this.editDialog.data.price.quoted.arrivalAtPickup,
      //             startRideToPassenger: this.editDialog.data.price.quoted.startRideToPassenger,
      //           }
      //         }
      //         this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/extra-waiting-time`, body, async ({ data }) => {
      //           if (data.extraWaitingTime) {
      //             this.setPrice('quoted', 'extraWaitingTime', data.extraWaitingTime.time)
      //           }
      //           resolve(data)
      //         }, null, (error) => reject(error))
      //       })
      //     }

      //     if (this.editDialog.data.price.quoted.arrivalAtPickup && this.editDialog.data.price.quoted.startRideToPassenger) {
      //       await func()
      //     }
      //   }
      // }
    },
    async initExtraWaitingTimeDeclared (isManual = false) {

      if (!this.isChangeDriverPrice('declared', 'extraWaitingTime') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isRentabob) {
          const func = () => {
            return new Promise((resolve, reject) => {
              const body = {
                data: {
                  extraWaitingTimeTariff: this.editDialog.data.price.declared.extraWaitingTimeTariff || undefined,
                  prebookedWaitingTimeTariff: this.editDialog.data.price.declared.prebookedWaitingTimeTariff || undefined,
                  prebookedWaitingTime: this.editDialog.data.price.declared.prebookedWaitingTime || undefined,
                  arrivalAtPickup: this.editDialog.data.price.declared.arrivalAtPickup,
                  startRideToPassenger: this.editDialog.data.price.declared.startRideToPassenger,
                }
              }
              this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/extra-waiting-time`, body, async ({ data }) => {
                if (data.extraWaitingTime) {
                  this.setPrice('declared', 'extraWaitingTime', data.extraWaitingTime.time)
                }
                await this.onChangeExtraWaitingTimeDeclared(isManual)
                resolve(data)
              }, null, (error) => reject(error))
            })
          }

          if (this.editDialog.data.price.declared.arrivalAtPickup && this.editDialog.data.price.declared.startRideToPassenger) {
            await func()
          }
        }
      }

      // RaB MAX(Start ride with passenger - MAX(Arrival at pickup, (Depart at of stop 1, else Ride start at, else NULL)) - MAX(ride.waiting_time, CONFIG:FREE_WAITING_TIME), 0)

      // A) MAX(ride.waiting_time, CONFIG:FREE_WAITING_TIME)
      // B) MAX(Arrival at pickup, (Depart at of stop 1, else Ride start at, else NULL))
      // MAX(Start ride with passenger - B - A, 0)
    },
    async initExtraWaitingTimeDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'extraWaitingTime') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isRentabob) {
          const func = () => {
            return new Promise((resolve, reject) => {
              const body = {
                data: {
                  extraWaitingTimeTariff: this.editDialog.data.price.driver.extraWaitingTimeTariff || undefined,
                  prebookedWaitingTimeTariff: this.editDialog.data.price.driver.prebookedWaitingTimeTariff || undefined,
                  prebookedWaitingTime: this.editDialog.data.price.driver.prebookedWaitingTime || undefined,
                  arrivalAtPickup: this.editDialog.data.price.driver.arrivalAtPickup,
                  startRideToPassenger: this.editDialog.data.price.driver.startRideToPassenger,
                }
              }
              this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/extra-waiting-time`, body, async ({ data }) => {
                if (data.extraWaitingTime) {
                  this.setPrice('driver', 'extraWaitingTime', data.extraWaitingTime.time)
                }
                // await this.onChangeExtraWaitingTimeDriver(isManual)
                resolve(data)
              }, null, (error) => reject(error))
            })
          }

          if (this.editDialog.data.price.driver.arrivalAtPickup && this.editDialog.data.price.driver.startRideToPassenger) {
            await func()
          }
        }
      // RaB From declaration
      }
    },
    initExtraWaitingTimePassenger (isManual = false) {},
    initExtraWaitingTimeTariffQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'extraWaitingTimeTariff') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isRentabob) {
          if (this.editDialog.data.advanceBookingDiscount) {
            const discount = this.discountsList.find(item => item.id === this.editDialog.data.advanceBookingDiscount)
            this.setPrice('quoted', 'extraWaitingTimeTariff', discount.waitingTimeTariff)
          } else {
            this.setPrice('quoted', 'extraWaitingTimeTariff', this.appConfig.price.rentabob.extraWaitingTimeTariff)
          }
        }
      }
    },
    initExtraWaitingTimeTariffDeclared (isManual = false) {},
    initExtraWaitingTimeTariffDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'extraWaitingTimeTariff') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isRentabob) {
          this.setPrice('driver', 'extraWaitingTimeTariff', this.editDialog.data.price.quoted.extraWaitingTimeTariff)
        }
      }
      // RaB From declaration
    },
    initExtraWaitingTimeTariffPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'extraWaitingTimeTariff') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.editDialog.data.advanceBookingDiscount) {
          const discount = this.discountsList.find(item => item.id === this.editDialog.data.advanceBookingDiscount)
          this.setPrice('passenger', 'extraWaitingTimeTariff', discount.waitingTimeTariff)
        } else {
          this.setPrice('passenger', 'extraWaitingTimeTariff', this.editDialog.data.price.driver.extraWaitingTimeTariff)
        }
      }
    },
    //////////////////////////////////////
    async initTripTimeQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'tripTime') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (!this.isFixedPriceCheckbox && (this.isDriveme || this.isEdrivers)) {
          if (this.editDialog.data.price.quoted.startRideToPassenger && this.editDialog.data.price.quoted.arrivalAtHome) {
            const diffDate = moment(this.editDialog.data.price.quoted.arrivalAtHome).diff(moment(this.editDialog.data.price.quoted.startRideToPassenger))
            const tripTimeMinutes = moment.duration(diffDate).asMinutes()
            this.setPrice('quoted', 'tripTime', this.formatMinutesToString(Math.abs(tripTimeMinutes)))
          } else {
            this.setPrice('quoted', 'tripTime', null)
          }


          await this.onChangeTripTimeQuoted(isManual)
        }
      }

      // If not fixed price, [Start ride to passenger] - [Arrival at home] DM
      // If not fixed price, [Start ride to passenger] - [Arrival at home] ED
    },
    async initTripTimeDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'tripTime') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isDriveme || this.isRentabob) {
          if (!this.editDialog.data.declaredAt) {
            this.setPrice('declared', 'tripTime', this.editDialog.data.price.quoted.tripTime)
          } else {
            const tripTime = this.getHistoryStatusDiffAsTime(this.rideStatus.started.alias, this.rideStatus.ended.alias)
            this.setPrice('declared', 'tripTime', tripTime)
          }

          await this.onChangeTripTimeDeclared(isManual)
        }
      }
      // From ride.status.history DM
      // From ride.status.history RaB
    },
    async initTripTimeDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'tripTime') && (this.priceActive.driver || isManual === 'driver')) {
        if (!this.isFixedPriceCheckbox && this.isDriveme) {
          this.setPrice('driver', 'tripTime', this.editDialog.data.price.declared.tripTime)

          await this.onChangeTripTimeDriver(isManual)
        }

        if (!this.isFixedPriceCheckbox && this.isEdrivers) {
          if (this.editDialog.data.price.driver.startRideToPassenger && this.editDialog.data.price.driver.arrivalAtHome) {
            const startTime = moment.duration(moment(this.editDialog.data.price.driver.startRideToPassenger)).asMinutes()
            const endTime = moment.duration(moment(this.editDialog.data.price.driver.arrivalAtHome)).asMinutes()
            const tripTimeMinutes = endTime - startTime
            this.setPrice('driver', 'tripTime', this.formatMinutesToString(Math.abs(tripTimeMinutes)))
          } else {
            this.setPrice('driver', 'tripTime', null)
          }

          await this.onChangeTripTimeDriver(isManual)
        }

        if (this.isRentabob) {
          this.setPrice('driver', 'tripTime', this.editDialog.data.price.declared.tripTime)

          await this.onChangeTripTimeDriver(isManual)
        }
      }
      // If not fixed price, from declaration DM
      // If not fixed price, [Start ride to passenger] - [Arrival at home] ED
      // From declaration RaB
    },
    async initTripTimePassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'tripTime') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isDriveme) {
          this.setPrice('passenger', 'tripTime', this.editDialog.data.price.driver.tripTime)

          await this.onChangeTripTimePassenger(isManual)
        }

        if (!this.isFixedPriceCheckbox && this.isEdrivers) {
          if (this.editDialog.data.price.passenger.startRideToPassenger && this.editDialog.data.price.passenger.arrivalAtHome) {
            const startTime = moment.duration(moment(this.editDialog.data.price.passenger.startRideToPassenger)).asMinutes()
            const endTime = moment.duration(moment(this.editDialog.data.price.passenger.arrivalAtHome)).asMinutes()
            const tripTimeMinutes = endTime - startTime
            this.setPrice('passenger', 'tripTime', this.formatMinutesToString(Math.abs(tripTimeMinutes)))
          } else {
            this.setPrice('passenger', 'tripTime', null)
          }


          await this.onChangeTripTimePassenger(isManual)
        }
      }
      // From driver invoice DM
      // From driver invoice ED
    },
    //////////////////////////////////////
    async initMinMinutesQuoted (isManual = false, isForce = false) {
      if (!this.isChangeDriverPrice('quoted', 'minMinutes') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isEdrivers && this.editDialog.data.driverType) {
          const driverType = this.typesList.find(item => item.id === this.editDialog.data.driverType)
          this.setPrice('quoted', 'minMinutes', driverType.minHoursPerRide.time)
          await this.onChangeMinMinutesQuoted(isManual)
        } else if ((this.isDriveme || this.isEdrivers) && this.electedSubscribedDriverUser) {
          this.setPrice('quoted', 'minMinutes', this.electedSubscribedDriverUser.price.quoted.minMinutes.time)
          await this.onChangeMinMinutesQuoted(isManual)
        } else if (this.isDriveme || this.isEdrivers) {
          this.setPrice('quoted', 'minMinutes', this.appConfig.price[this.selectedBrandName].minMinutes.time)
          await this.onChangeMinMinutesQuoted(isManual)
        }
      }

      // If elected, copy from subscription, else CONFIG:PRICE_CALC_MIN_MINUTES_PER_RIDE DM
      // If driver type is set, copy from driver type, else if elected, copy from subscription, else CONFIG:PRICE_CALC_MIN_MINUTES_PER_RIDE ED
    },
    initMinMinutesDeclared (isManual = false) {},
    async initMinMinutesDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'minMinutes') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isDriveme) {
          this.setPrice('driver', 'minMinutes', this.editDialog.data.price.quoted.minMinutes)
          await this.onChangeMinMinutesDriver(isManual)
        }

        if (this.isEdrivers) {
          this.setPrice('driver', 'minMinutes', this.appConfig.price.edrivers.minMinutes.time)
          await this.onChangeMinMinutesDriver(isManual)
        }

        if (this.isRentabob && this.electedSubscribedDriverUser) {
          this.setPrice('driver', 'minMinutes', this.electedSubscribedDriverUser.driver.minHoursPerRide.time)
          await this.onChangeMinMinutesDriver(isManual)
        }
      }
      // From quoted DM
      // CONFIG:PRICE_CALC_DRIVER_MIN_MINUTES_PER_RIDE ED
      // From driver.minimum RaB
    },
    async initMinMinutesPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'minMinutes') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isDriveme || this.isEdrivers) {
          this.setPrice('passenger', 'minMinutes', this.editDialog.data.price.quoted.minMinutes)

          await this.onChangeMinMinutesPassenger(isManual)
        }
      }
      // From quoted DM
      // From quoted ED
    },
    //////////////////////////////////////
    initTripKmQuoted (isManual = false) {},
    initTripKmDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'tripKm') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isRentabob) {
          this.setPrice('declared', 'tripKm', this.editDialog.data.price.quoted.tripKm)
        }
      }
    },
    initTripKmDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'tripKm') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isRentabob) {
          this.setPrice('driver', 'tripKm', this.editDialog.data.price.declared.tripKm)
        }
      }
    },
    initTripKmPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'tripKm') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isRentabob) {
          this.setPrice('driver', 'tripKm', this.editDialog.data.price.declared.tripKm)
        }
      }
    },
    initDistanceQuoted (isManual = false) {},
    initDistanceDeclared (isManual = false) {},
    initDistanceDriver (isManual = false) {},
    initDistancePassenger (isManual = false) {},
    initPlatformCostQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'platformCost') && (this.priceActive.quoted || isManual === 'quoted') && this.selectedBrandName) {
        this.setPrice('quoted', 'platformCost', this.appConfig.price[this.selectedBrandName].platform.passenger)
        this.onChangePlatformCostQuoted(isManual)
      }

      // CONFIG:PRICE_CALC_PLATFORM_COST DM
      // CONFIG:PRICE_CALC_PLATFORM_COST ED
      // CONFIG:PRICE_CALC_PLATFORM_COST RaB
    },
    initPlatformCostDeclared (isManual = false) {},
    async initPlatformCostDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'platformCost') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.selectedBrandName) {
          this.setPrice('driver', 'platformCost', this.appConfig.price[this.selectedBrandName].platform.driver)
        }

      }

      // CONFIG:PRICE_CALC_DRIVER_PLATFORM_COST DM
      // CONFIG:PRICE_CALC_DRIVER_PLATFORM_COST ED
      // CONFIG:PRICE_CALC_DRIVER_PLATFORM_COST RaB
    },
    initPlatformCostPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'platformCost') && (this.priceActive.passenger || isManual === 'passenger')) {
        this.setPrice('passenger', 'platformCost', this.editDialog.data.price.quoted.platformCost)
      }
      // From quoted DM
      // From quoted ED
      // From quoted RaB
    },
    //////////////////////////////////////
    async initTariffQuoted (isManual = false, isForce = false) {
      if (!this.isChangeDriverPrice('quoted', 'tariff') && (this.priceActive.quoted || isManual === 'quoted' || isForce)) {
        if (this.isDriveme || this.isEdrivers) {
          if (this.editDialog.data.driverType) {
            let priceCalcDiscount = null
            const driverType = this.typesList.find(item => item.id === this.editDialog.data.driverType)
            priceCalcDiscount = driverType.price
            if (this.isEdrivers && this.editDialog.data.price.quoted.discountType && this.editDialog.data.price.quoted.discountAmount && priceCalcDiscount) {
              switch (this.editDialog.data.price.quoted.discountType) {
                case 'percents':
                  priceCalcDiscount = priceCalcDiscount - (priceCalcDiscount * parseFloat(this.editDialog.data.price.quoted.discountAmount) / 100)
                  break;
                case 'amount':
                  priceCalcDiscount = priceCalcDiscount - this.editDialog.data.price.quoted.discountAmount
                  break;
                default:
                  break;
              }
            }
            this.setPrice('quoted', 'tariff', priceCalcDiscount ? parseFloat(priceCalcDiscount).toFixed(2) : null)
          } else if (this.electedSubscribedDriverUser) {
            this.setPrice('quoted', 'tariff', this.electedSubscribedDriverUser.price.quoted.tariff)
          } else {
            this.setPrice('quoted', 'tariff', this.appConfig.price[this.selectedBrandName].minMaxTariff.mid)
          }

          await this.onChangeTariffQuoted(isManual, isForce)
        }

        // if (this.isRentabob && this.editDialog.stopsData) {
        //   const func = () => {
        //     return new Promise((resolve, reject) => {
        //       const body = {
        //         data: {
        //           stops: this.editDialog.stopsData.map(item => item.address),
        //           startAt: this.editDialog.data.startAt
        //         }
        //       }
        //       this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/tariff`, body, async ({ data }) => {
        //         this.setPrice('quoted', 'tariff', data.tariff)

        //         await this.onChangeTariffQuoted(isManual)
        //         resolve(data)
        //       }, null, (error) => reject(error))
        //     })
        //   }

        //   await func()
        // }
      }

      // If driver type is set, copy from driver type, else if elected, copy from subscription, else CONFIG:PRICE_CALC_MID_PRICE_PER_HOUR DM
      // If driver type is set, copy from driver type, else if elected, copy from subscription, else CONFIG:PRICE_CALC_MID_PRICE_PER_HOUR ED
      // Use tiers based on trip km RaB
    },
    initTariffDeclared (isManual = false) {},
    async initTariffDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'tariff') && (this.priceActive.driver || isManual === 'driver')) {
        const func = () => {
          return new Promise((resolve, reject) => {
            const body = {
              data: {
                driver: this.electedSubscribedDriverUser.driver.id,
                ride: this.editDialog.data.id
              }
            }
            this.request('POST', `/prices/calc/${this.selectedBrandName}/driver/tariff`, body, async ({ data }) => {
              this.setPrice('driver', 'tariff', data.tariff)

              await this.onChangeTariffDriver(isManual)
              resolve(data)
            }, null, (error) => reject(error))
          })
        }

        if (this.electedSubscribedDriverUser) {
          await func()
        }

        // if (this.isDriveme) {
        //   this.setPrice('driver', 'tariff', this.editDialog.data.price.quoted.tariff)
        // }

        // if ((this.isEdrivers || this.isRentabob) && this.electedSubscribedDriverUser) {
        //   this.setPrice('driver', 'tariff', this.electedSubscribedDriverUser.price.driver.tariff)
        // }
      }
      // From quoted DM
      // From driver.tariff ED
      // From driver.tariff RaB
    },
    async initTariffPassenger (isManual = false, isForce = false) {
      if (!this.isChangeDriverPrice('passenger', 'tariff') && (this.priceActive.passenger || isManual === 'passenger' || isForce)) {
        // const func = () => {
        //   return new Promise((resolve, reject) => {
        //     const body = {
        //       data: {
        //         stops: this.editDialog.stopsData.map(item => item.address),
        //         startAt: this.editDialog.data.startAt
        //       }
        //     }
        //     this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/tariff`, body, async ({ data }) => {
        //       this.setPrice('passenger', 'tariff', data.tariff)

        //       await this.onChangeTariffPassenger(isManual)
        //       resolve(data)
        //     }, null, (error) => reject(error))
        //   })
        // }

        // if (this.isRentabob && this.editDialog.stopsData) {
        //   await func()
        // }

        if (this.isEdrivers || this.isDriveme) {
          this.setPrice('passenger', 'tariff', this.editDialog.data.price.quoted.tariff)

          await this.onChangeTariffPassenger(isManual, this.isEdrivers && isForce)
        }
      }
      // From quoted DM
      // From quoted ED
      // From quoted RaB
    },
    //////////////////////////////////////
    async initIntermediateStopsQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'intermediateStops') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isRentabob && this.editDialog.stopsData && this.editDialog.stopsData.length >= 2) {
          this.setPrice('quoted', 'intermediateStops', this.editDialog.stopsData.length - 2)

          await this.onChangeIntermediateStopsQuoted(isManual)
        }
      }

      // From COUNT(ride.route.stops) ED
    },
    async initIntermediateStopsDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'intermediateStops') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isRentabob) {
          this.setPrice('declared', 'intermediateStops', this.editDialog.data.price.quoted.intermediateStops)

          await this.onChangeIntermediateStopsDeclared(isManual)
        }
      }
      // From quoted RaB
    },
    initIntermediateStopsDriver (isManual = false) {},
    async initIntermediateStopsPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'intermediateStops') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isRentabob) {
          this.setPrice('passenger', 'intermediateStops', this.editDialog.data.price.declared.intermediateStops)

          await this.onChangeIntermediateStopsPassenger(isManual)
        }
      }
      // From declaration RaB
    },
    //////////////////////////////////////
    async initIntermediateStopCostQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'intermediateStopCost') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isRentabob) {
          this.setPrice('quoted', 'intermediateStopCost', this.appConfig.price.rentabob.intermediateStopCost)

          await this.onChangeIntermediateStopCostQuoted(isManual)
        }
      }

      // CONFIG:PRICE_STOP_COST ED
    },
    initIntermediateStopCostDeclared (isManual = false) {},
    initIntermediateStopCostDriver (isManual = false) {},
    async initIntermediateStopCostPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'intermediateStopCost') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isRentabob) {
          this.setPrice('passenger', 'intermediateStopCost', this.editDialog.data.price.quoted.intermediateStopCost)

          await this.onChangeIntermediateStopCostPassenger(isManual)
        }
      }
      // From quoted RaB
    },

    initOutsideAreaFeeQuoted (isManual = false) {
      
    },
    initOutsideAreaFeeDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'outsideAreaFee') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isRentabob) {
          this.setPrice('declared', 'outsideAreaFee', this.editDialog.data.price.driver.outsideAreaFee)

          // this.onChangeOutsideAreaFeeDeclared(isManual)
        }
      }
      // from driver invoice RaB
    },
    initOutsideAreaFeeDriver (isManual = false) {},
    initOutsideAreaFeePassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'outsideAreaFee') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isRentabob) {
          this.setPrice('passenger', 'outsideAreaFee', this.editDialog.data.price.quoted.outsideAreaFee)

          // await this.onChangeOutsideAreaFeePassenger(isManual)
        }
      }
      // from quoted RaB
    },
    //////////////////////////////////////
    async initTripCostQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'tripCost') && (this.priceActive.quoted || isManual === 'quoted')) {
        const func = () => {
          return new Promise(async (resolve, reject) => {
            let body = {
              data: {}
            }

            if (this.isDriveme) {
              body.data = {
                isAborted: false,
                driver: {},
                passenger: {},
                quoted: {
                  publicTransport: this.editDialog.data.price.quoted.publicTransport,
                  tariff: this.editDialog.data.price.quoted.tariff,
                  tripTime: this.editDialog.data.price.quoted.tripTime,
                  minMinutes: this.editDialog.data.price.quoted.minMinutes,
                }
              }
            } else if (this.isEdrivers) {
              body.data = {
                isAborted: false,
                driver: {},
                passenger: {},
                quoted: {
                  tariff: this.editDialog.data.price.quoted.tariff,
                  tripTime: this.editDialog.data.price.quoted.tripTime,
                  minMinutes: this.editDialog.data.price.quoted.minMinutes,
                }
              }
            }

            if (body.data.quoted) {
              await this.request('POST', `/prices/calc/${this.selectedBrandName}/trip-cost`, body, async ({ data }) => {
                this.setPrice('quoted', 'tripCost', data.quoted.tripCost)

                await this.onChangeTripCostQuoted(isManual)
                resolve(data)
              }, null, (error) => reject(error))
            } else {
              resolve(null)
            }
          })
        }

        if (this.isDriveme || this.isEdrivers) {
          await func().catch(() => {})
        }

        if (this.isRentabob && this.editDialog.stopsData && this.editDialog.stopsData.length >= 2) {
          const stopsDataValid = this.editDialog.stopsData.filter(stop => stop.address && stop.address.trim())
          if (stopsDataValid && stopsDataValid.length >= 2) {
            const func = () => {
              return new Promise(async (resolve, reject) => {
                const bodyStops = {
                  data: {
                    stops: stopsDataValid.map(item => item.address),
                    startAt: this.editDialog.data.startAt,
                    // discount: { advanceBooking: this.editDialog.data.advanceBookingDiscount }
                  }
                }
                // const bodyDistance = {
                //   data: {
                //     distance: this.editDialog.data.price.quoted.distance
                //   }
                // }
                // const body = this.editDialog.data.price.quoted.distance ? bodyDistance : bodyStops
                await this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/tariff`, bodyStops, async ({ data }) => {
                  this.setPrice('quoted', 'tripCost', data.tariff)
                  this.setPrice('quoted', 'distance', data.distance)
                  this.editDialog.data.advanceBookingDiscount = data.discount.advanceBooking ? data.discount.advanceBooking.id : null
                  await this.onChangeTripCostQuoted(isManual)
                  resolve(data)
                }, null, (error) => reject(error))
              })
            }

            await func()
          }
        }
      }

      // If elected with fixed price, copy from subscription, else MAX([Trip time],[Minimum minutes])*[Tariff] + [Public transport] DM
      // If fixed price 0, else MAX([Trip time],[Minimum minutes])*[Tariff] ED
      // ride.trip_km * [Tariff] + ([Stops]-2)*[Stop cost] RaB
    },
    initTripCostDeclared (isManual = false) {},
    async initTripCostDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'tripCost') && (this.priceActive.driver || isManual === 'driver')) {
        const func = () => {
          return new Promise(async (resolve, reject) => {
            let body = {
              data: {}
            }

            if (this.isDriveme) {
              body.data = {
                quoted: {},
                passenger: {},
                driver: {
                  publicTransport: this.editDialog.data.price.driver.publicTransport,
                  tariff: this.editDialog.data.price.driver.tariff,
                  tripTime: this.editDialog.data.price.driver.tripTime,
                  minMinutes: this.editDialog.data.price.driver.minMinutes,
                }
              }
            } else if (this.isEdrivers) {
              body.data = {
                quoted: {},
                passenger: {},
                driver: {
                  tariff: this.editDialog.data.price.driver.tariff,
                  tripTime: this.editDialog.data.price.driver.tripTime,
                  minMinutes: this.editDialog.data.price.driver.minMinutes,
                }
              }
            } else if (this.isRentabob) {
              body.data = {
                quoted: {},
                passenger: {},
                driver: {
                  tariff: this.editDialog.data.price.driver.tariff,
                  tripTime: this.editDialog.data.price.driver.tripTime,
                  minMinutes: this.editDialog.data.price.driver.minMinutes,
                }
              }
            }

            if (body.data.driver) {
              await this.request('POST', `/prices/calc/${this.selectedBrandName}/trip-cost`, body, async ({ data }) => {
                this.setPrice('driver', 'tripCost', data.driver.tripCost)
                resolve(data)
                await this.onChangeTripCostDriver(isManual)
              }, null, (error) => reject(error))
            } else {
              resolve(null)
            }
          })
        }

        if (this.isFixedPriceCheckbox && this.isDriveme) {
          this.setPrice('driver', 'tripCost', this.editDialog.data.price.quoted.tripCost)
        } else {
          await func()
        }
      }
      // If fixed price from quoted, else MAX([Trip time],[Minimum minutes])*[Tariff] + [Public transport] DM
      // MAX([Trip time], [Minimum minutes]) * [Tariff] ED
      // MAX([Trip time], [Minimum minutes]) * [Tariff] RaB
    },
    async initTripCostPassenger (isManual = false, isForce = false) {
      console.log('initTripCostPassenger')
      if (!this.isChangeDriverPrice('passenger', 'tripCost') && (this.priceActive.passenger || isManual === 'passenger' || isForce)) {
        if (this.isDriveme || this.isEdrivers) {
          const func = () => {
            return new Promise(async (resolve, reject) => {
              let body = {
                data: {}
              }

              if (this.isDriveme) {
                body.data = {
                  isAborted: false,
                  quoted: {},
                  driver: {},
                  passenger: {
                    publicTransport: this.editDialog.data.price.passenger.publicTransport,
                    tariff: this.editDialog.data.price.passenger.tariff,
                    tripTime: this.editDialog.data.price.passenger.tripTime,
                    minMinutes: this.editDialog.data.price.passenger.minMinutes,
                  }
                }
              } else if (this.isEdrivers) {
                body.data = {
                  isAborted: false,
                  quoted: {},
                  driver: {},
                  passenger: {
                    tariff: this.editDialog.data.price.passenger.tariff,
                    tripTime: this.editDialog.data.price.passenger.tripTime,
                    minMinutes: this.editDialog.data.price.passenger.minMinutes,
                  }
                }
              }

              if (body.data.passenger) {
                await this.request('POST', `/prices/calc/${this.selectedBrandName}/trip-cost`, body, ({ data }) => {
                  this.setPrice('passenger', 'tripCost', data.passenger.tripCost || this.editDialog.data.price.quoted.tripCost)
                  resolve(data)
                }, null, (error) => reject(error))
              } else {
                resolve(null)
              }
            })
          }

          if (this.isFixedPriceCheckbox) {
            this.setPrice('passenger', 'tripCost', this.editDialog.data.price.quoted.tripCost)
          } else {
            await func()
          }
        }

        if (this.isRentabob) {
          const stopsDataValid = this.editDialog.stopsData.filter(stop => stop.address && stop.address.trim())
          if (stopsDataValid && stopsDataValid.length >= 2) {
            const func = () => {
              return new Promise(async (resolve, reject) => {
                const bodyStops = {
                  data: {
                    stops: stopsDataValid.map(item => item.address),
                    startAt: this.editDialog.data.startAt,
                    // discount: { advanceBooking: this.editDialog.data.advanceBookingDiscount }
                  }
                }
                const bodyDistance = {
                  data: {
                    distance: this.editDialog.data.price.passenger.distance
                  }
                }
                const body = this.editDialog.data.price.passenger.distance ? bodyDistance : bodyStops
                await this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/tariff`, body, async ({ data }) => {
                  this.setPrice('passenger', 'tripCost', data.tariff)
                  this.setPrice('passenger', 'distance', data.distance)
                  this.editDialog.data.advanceBookingDiscount = data.discount.advanceBooking ? data.discount.advanceBooking.id : null
                  // await this.onChangeTripCostPassenger(isManual)
                  resolve(data)
                }, null, (error) => reject(error))
              })
            }
            if (this.editDialog.stopsData || this.editDialog.data.price.passenger.distance) {
              await func()
            }
          }
        }
      }

      // If fixed price from quoted, else MAX([Trip time],[Minimum minutes])*[Tariff] + [Public transport] DM
      // If fixed price from quoted, else MAX([Trip time],[Minimum minutes])*[Tariff] ED
      // [Tariff] + ([Stops]-2)*[Stop cost] // RaB
    },
    //////////////////////////////////////
    initInsuranceQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'insurance') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isDriveme) {
          this.setPrice('quoted', 'insurance', this.insuranceEnabled ? this.appConfig.price.driveme.insurance : 0.00)

          this.onChangeInsuranceQuoted(isManual)
        }
      }

      // If ride.has_insurance, CONFIG:PRICE_CALC_INSURANCE_COST, else 0 DM
    },
    initInsuranceDeclared (isManual = false) {},
    initInsuranceDriver (isManual = false) {},
    initInsurancePassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'insurance') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isDriveme) {
          this.setPrice('passenger', 'insurance', this.editDialog.data.price.quoted.insurance)
        }
      }
      // From quoted DM
      // From quoted ED
      // From quoted RaB
    },
    //////////////////////////////////////
    initPromoAmountQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'promoAmount') && (this.priceActive.quoted || isManual === 'quoted')) {
        this.setPrice('quoted', 'promoAmount', this.editDialog.data.promoAmount)

        this.onChangePromoAmountQuoted(isManual)
      }

      // ride.promo_amount DM
      // ride.promo_amount ED
      // ride.promo_amount RaB
    },
    initPromoAmountDeclared (isManual = false) {},
    initPromoAmountDriver (isManual = false) {},
    initPromoAmountPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'promoAmount') && (this.priceActive.passenger || isManual === 'passenger')) {
        this.setPrice('passenger', 'promoAmount', this.editDialog.data.price.quoted.promoAmount)
      }
      // From quoted DM
      // From quoted ED
      // From quoted RaB
    },
    //////////////////////////////////////
    initDiscountTypeQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'discountType') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isEdrivers) {
          if (this.editDialog.data.driverType) {
            const passengerDiscounts = this.editDialog.data.passenger && this.editDialog.data.passenger.driverTypeDiscounts && this.editDialog.data.passenger.driverTypeDiscounts.length ? this.editDialog.data.passenger.driverTypeDiscounts : null
            const currentDiscount =  passengerDiscounts ? passengerDiscounts.find(item => item.driverType.id === this.editDialog.data.driverType) : null
            this.setPrice('quoted', 'discountType', currentDiscount ? currentDiscount.type : null)
          } else {
            this.setPrice('quoted', 'discountType', 'percents')
          }

          this.onChangeDiscountTypeQuoted(isManual)
        }
      }
      
      // If driver type is set, copy from passenger driver type, else 0 ED
    },
    initDiscountTypeDeclared (isManual = false) {},
    initDiscountTypeDriver (isManual = false) {},
    initDiscountTypePassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'discountType') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isEdrivers) {
          this.setPrice('passenger', 'discountType', this.editDialog.data.price.quoted.discountType)
        }
      }
      // From quoted ED
    },
    //////////////////////////////////////
    initDiscountAmountQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'discountAmount') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isEdrivers) {
          if (this.editDialog.data.driverType) {
            const passengerDiscounts = this.editDialog.data.passenger && this.editDialog.data.passenger.driverTypeDiscounts && this.editDialog.data.passenger.driverTypeDiscounts.length ? this.editDialog.data.passenger.driverTypeDiscounts : null
            const currentDiscount =  passengerDiscounts ? passengerDiscounts.find(item => item.driverType.id === this.editDialog.data.driverType) : null
            this.setPrice('quoted', 'discountAmount', currentDiscount ? currentDiscount.amount : null)
          } else {
            this.setPrice('quoted', 'discountAmount', 1)
          }

          this.onChangeDiscountAmountQuoted(isManual)
        }
      }

      // If driver type is set, copy from passenger driver type, else 1 ED
    },
    initDiscountAmountDeclared (isManual = false) {},
    initDiscountAmountDriver (isManual = false) {},
    initDiscountAmountPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'discountAmount') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isEdrivers) {
          this.setPrice('passenger', 'discountAmount', this.editDialog.data.price.quoted.discountAmount)
        }
      }
      // From quoted ED
    },
    //////////////////////////////////////
    async initStartRideToPassengerQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'startRideToPassenger') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.editDialog.data.startAt) {
          this.setPrice('quoted', 'startRideToPassenger', this.editDialog.data.startAt)

          await this.onChangeStartRideToPassengerQuoted(isManual)
        }
      }

      // Ride start at DM
      // Ride start at ED
      // Ride start at RaB
    },
    async initStartRideToPassengerDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'startRideToPassenger') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isEdrivers || this.isRentabob) {
          if (!this.editDialog.data.declaredAt) {
            this.setPrice('declared', 'startRideToPassenger', this.editDialog.data.price.quoted.startRideToPassenger)
          } else {
            const startRideToPassenger = this.getCreatedDateTimeByHistory(this.rideStatus.underway.alias)
            this.setPrice('declared', 'startRideToPassenger', startRideToPassenger)
          }

          await this.onChangeStartRideToPassengerDeclared(isManual)
        }
      }
      // From ride.status.history ED
      // From ride.status.history RaB
    },
    async initStartRideToPassengerDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'startRideToPassenger') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isEdrivers || this.isRentabob) {
          this.setPrice('driver', 'startRideToPassenger', this.editDialog.data.price.declared.startRideToPassenger)
          await this.onChangeStartRideToPassengerDriver(isManual)
        }

        if (this.isDriveme) {
          const startRideToPassenger = this.getCreatedDateTimeByHistory(this.rideStatus.underway.alias)
          this.setPrice('driver', 'startRideToPassenger', startRideToPassenger)
          await this.onChangeStartRideToPassengerDriver(isManual)
        }
      }
      // From ride.status.history DM
      // From declaration ED
      // From ride.status.history RaB
    },
    async initStartRideToPassengerPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'startRideToPassenger') && (this.priceActive.passenger || isManual === 'passenger')) {
        this.setPrice('passenger', 'startRideToPassenger', this.editDialog.data.price.driver.startRideToPassenger)

        await this.onChangeStartRideToPassengerPassenger(isManual)
      }
      // From driver invoice DM
      // From driver invoice ED
      // From driver invoice RaB
    },
    async initArrivalAtPickupQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'arrivalAtPickup') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.isRentabob) {
          const func = () => {
            return new Promise((resolve, reject) => {
              const body = {
                data: {
                  startRideToPassenger: this.editDialog.data.price.quoted.startRideToPassenger,
                  prebookedWaitingTime: this.editDialog.data.price.quoted.prebookedWaitingTime
                }
              }
              this.request('POST', `/prices/calc/${this.selectedBrandName}/passenger/arrival-at-pickup`, body, async ({ data }) => {
                if (data.arrivalAtPickup) {
                  this.setPrice('quoted', 'arrivalAtPickup', data.arrivalAtPickup)
                }
                await this.onChangeArrivalAtPickupQuoted(isManual)
                resolve(data)
              }, null, (error) => reject(error))
            })
          }

          if (this.editDialog.data.price.quoted.startRideToPassenger) {
            await func()
          }
        }
      }

      // RaB Start ride with passenger - max(ride.waiting_time, CONFIG:FREE_WAITING_TIME)
    },
    async initArrivalAtPickupDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'arrivalAtPickup') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isRentabob) {
          if (!this.editDialog.data.declaredAt) {

            this.setPrice('declared', 'arrivalAtPickup', this.editDialog.data.price.quoted.arrivalAtPickup)
          } else {
            const arrivalAtPickup = this.getCreatedDateTimeByHistory(this.rideStatus.waiting.alias)
            this.setPrice('declared', 'arrivalAtPickup', arrivalAtPickup)
          }
          await this.onChangeArrivalAtPickupDeclared(isManual)
        }
      }
      // RaB From ride.status.history
    },
    async initArrivalAtPickupDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'arrivalAtPickup') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isRentabob) {
          this.setPrice('driver', 'arrivalAtPickup', this.editDialog.data.price.declared.arrivalAtPickup)
          await this.onChangeArrivalAtPickupDriver(isManual)
        }
      }
      // RaB From declaration
    },
    async initArrivalAtPickupPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'arrivalAtPickup') && (this.priceActive.passenger || isManual === 'passenger')) {
        if (this.isRentabob) {
          this.setPrice('passenger', 'arrivalAtPickup', this.editDialog.data.price.driver.arrivalAtPickup)
        }
      }

      // RaB From driver invoice
    },
    //////////////////////////////////////
    initStartRideWithPassengerQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'startRideWithPassenger') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.editDialog.stopsData && this.editDialog.stopsData[0].departAt && parseDate(this.editDialog.stopsData[0].departAt) > parseDate(this.editDialog.data.price.quoted.arrivalAtPickup)) {
          this.setPrice('quoted', 'startRideWithPassenger', this.editDialog.stopsData[0].departAt)
        } else if (this.editDialog.data.price.quoted.arrivalAtPickup) {
          this.setPrice('quoted', 'startRideWithPassenger', this.editDialog.data.price.quoted.arrivalAtPickup)
        } else if (this.editDialog.data.startAt) {
          this.setPrice('quoted', 'startRideWithPassenger', this.editDialog.data.startAt)
        } else {
          this.setPrice('quoted', 'startRideWithPassenger', null)
        }

        this.onChangeStartRideWithPassengerQuoted(isManual)
      }

      // Depart at of stop 1, else Ride start at, else NULL DM
      // Depart at of stop 1, else Ride start at, else NULL ED
      // Depart at of stop 1, else Ride start at, else NULL RaB
    },
    initStartRideWithPassengerDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'startRideWithPassenger') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isEdrivers) {
          if (!this.editDialog.data.declaredAt) {
            this.setPrice('declared', 'startRideWithPassenger', this.editDialog.data.price.quoted.startRideWithPassenger)
          } else {
            const startRideWithPassenger = this.getCreatedDateTimeByHistory(this.rideStatus.started.alias)
            this.setPrice('declared', 'startRideWithPassenger', startRideWithPassenger)
          }

          this.onChangeStartRideWithPassengerDeclared(isManual)
        }
      }
      // From ride.status.history ED
    },
    initStartRideWithPassengerDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'startRideWithPassenger') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isEdrivers || this.isRentabob) {
          this.setPrice('driver', 'startRideWithPassenger', this.editDialog.data.price.declared.startRideWithPassenger)

          this.onChangeStartRideWithPassengerDriver(isManual)
        }

        if (this.isDriveme) {
          const startRideWithPassenger = this.getCreatedDateTimeByHistory(this.rideStatus.started.alias)
          this.setPrice('driver', 'startRideWithPassenger', startRideWithPassenger)

          this.onChangeStartRideWithPassengerDriver(isManual)
        }
      }
      // From ride.status.history DM
      // From declaration ED
      // From ride.status.history RaB
    },
    initStartRideWithPassengerPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'startRideWithPassenger') && (this.priceActive.passenger || isManual === 'passenger')) {
        this.setPrice('passenger', 'startRideWithPassenger', this.editDialog.data.price.driver.startRideWithPassenger)
      }
      // From driver invoice DM
      // From driver invoice ED
      // From driver invoice RaB
    },
    //////////////////////////////////////
    initEndRideWithPassengerQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'endRideWithPassenger') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (this.editDialog.stopsData && this.editDialog.stopsData[this.editDialog.stopsData.length -1].arriveAt && parseDate(this.editDialog.stopsData[this.editDialog.stopsData.length -1].arriveAt) > parseDate(this.editDialog.data.price.quoted.arrivalAtPickup)) {
          this.setPrice('quoted', 'endRideWithPassenger', this.editDialog.stopsData[this.editDialog.stopsData.length -1].arriveAt)
        } else if (parseDate(this.editDialog.data.endAt) > parseDate(this.editDialog.data.price.quoted.arrivalAtPickup)) {
          this.setPrice('quoted', 'endRideWithPassenger', this.editDialog.data.endAt)
        } else if (this.editDialog.data.price.quoted.arrivalAtPickup) {
          this.setPrice('quoted', 'endRideWithPassenger', this.editDialog.data.price.quoted.arrivalAtPickup)
        } else {
          this.setPrice('quoted', 'endRideWithPassenger', null)
        }

        this.onChangeEndRideWithPassengerQuoted(isManual)
      }

      // Arrive at of stop N, else Ride end at, else NULL DM
      // Arrive at of stop N, else Ride end at, else NULL ED
      // Arrive at of stop N, else Ride end at, else NULL RaB
    },
    initEndRideWithPassengerDeclared (isManual = false) {
      if (!this.isChangeDriverPrice('declared', 'endRideWithPassenger') && (this.priceActive.declared || isManual === 'declared')) {
        if (this.isEdrivers) {
          if (!this.editDialog.data.declaredAt) {
            this.setPrice('declared', 'endRideWithPassenger', this.editDialog.data.price.quoted.endRideWithPassenger)
          } else {
            const endRideWithPassenger = this.getCreatedDateTimeByHistory(this.rideStatus.ended.alias)
            this.setPrice('declared', 'endRideWithPassenger', endRideWithPassenger)
          }

          this.onChangeEndRideWithPassengerDeclared(isManual)
        }
      }
      // From ride.status.history ED
    },
    initEndRideWithPassengerDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'endRideWithPassenger') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isEdrivers || this.isRentabob) {
          this.setPrice('driver', 'endRideWithPassenger', this.editDialog.data.price.declared.endRideWithPassenger)
          
          this.onChangeEndRideWithPassengerDriver(isManual)
        }

        if (this.isDriveme) {
          const endRideWithPassenger = this.getCreatedDateTimeByHistory(this.rideStatus.ended.alias)
          this.setPrice('driver', 'endRideWithPassenger', endRideWithPassenger)

          this.onChangeEndRideWithPassengerDriver(isManual)
        }
      }
      // From ride.status.history DM
      // From declaration ED
      // From ride.status.history RaB
    },
    initEndRideWithPassengerPassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'endRideWithPassenger') && (this.priceActive.passenger || isManual === 'passenger')) {
        this.setPrice('passenger', 'endRideWithPassenger', this.editDialog.data.price.driver.endRideWithPassenger)
      }
      // From driver invoice DM
      // From driver invoice ED
      // From driver invoice RaB
    },
    //////////////////////////////////////
    async initArrivalAtHomeQuoted (isManual = false) {
      if (!this.isChangeDriverPrice('quoted', 'arrivalAtHome') && (this.priceActive.quoted || isManual === 'quoted')) {
        if (parseDate(this.editDialog.data.endAt) > parseDate(this.editDialog.data.price.quoted.arrivalAtPickup)) {
          this.setPrice('quoted', 'arrivalAtHome', this.editDialog.data.endAt)
          await this.onChangeArrivalAtHomeQuoted(isManual)
        } else if (this.editDialog.data.price.quoted.arrivalAtPickup) {
          this.setPrice('quoted', 'arrivalAtHome', this.editDialog.data.price.quoted.arrivalAtPickup)
        }
      }

      // Ride end at DM
      // Ride end at ED
      // Ride end at RaB
    },
    initArrivalAtHomeDeclared (isManual = false) {
      // this.onChangeArrivalAtHomeDeclared(isManual)
      // From ride.status.history ED
    },
    async initArrivalAtHomeDriver (isManual = false) {
      if (!this.isChangeDriverPrice('driver', 'arrivalAtHome') && (this.priceActive.driver || isManual === 'driver')) {
        if (this.isEdrivers) {
          this.setPrice('driver', 'arrivalAtHome', this.editDialog.data.price.declared.arrivalAtHome)

          await this.onChangeArrivalAtHomeDriver(isManual)
        }
      }
      // From ride.status.history DM
      // From declaration ED
      // From ride.status.history RaB
    },
    async initArrivalAtHomePassenger (isManual = false) {
      if (!this.isChangeDriverPrice('passenger', 'arrivalAtHome') && (this.priceActive.passenger || isManual === 'passenger')) {
        this.setPrice('passenger', 'arrivalAtHome', this.editDialog.data.price.driver.arrivalAtHome)

        await this.onChangeArrivalAtHomePassenger(isManual)
      }
      // From driver invoice DM
      // From driver invoice ED
      // From driver invoice RaB
    },
    async initAlreadyPaidQuoted (isManual = false) {},
    async initAlreadyPaidDeclared (isManual = false) {},
    async initAlreadyPaidDriver (isManual = false) {},
    async initAlreadyPaidPassenger (isManual = false) {},
    onKeyDownPrice (e, from, key) {
      if (key === 'travelKm') {
        if (!/[0-9]/.test(e.key) && e.code !== 'Backspace' && e.code !== 'Tab' && e.code !== 'ArrowRight' && e.code !== 'ArrowLeft') {
          e.preventDefault()
        }
      }
    },
    //////////////////////////////////////
    onInputPrice (e, from, key) {
      this.$set(this.inputPriceChange[from], key, { value: e, type: PRICE_BORDER_COLOR.manual })
    },
    onInputTimeField (evt, from, key) {
      if (this.manualPriceActive[from]) {
        console.log(evt.key)
        if (key === 'tripTime' && !/^[\d*:]$/.test("" + evt.key) && evt.key !== 'Backspace' && evt.key !== 'ArrowLeft' && evt.key !== 'ArrowRight') {
          evt.preventDefault()
          return
        }
        if (!evt.target.value && evt.key && !isNaN(evt.key)) {
          this.$set(this.editDialog.data.price[from], key, `0${evt.key}:00`)
          this.$set(this.inputPriceChange[from], key, { value: `0${evt.key}:00`, type: PRICE_BORDER_COLOR.manual })
          this.$forceUpdate()
        }
      }
    },
    setPrice (from, key, value) {
      console.log(`${from}.${key} = ${value}`)
      this.$set(this.editDialog.data.price[from], key, value)
    },
    async getEstimatePrice () {
      if (this.selectedBrandName && this.editDialog.data.startAt && this.editDialog.data.endAt) {
        return new Promise((resolve, reject) => {
          this.request('GET', `/estimate-price/min-max/${this.selectedBrandName}`, { params: { startAt: this.editDialog.data.startAt, endAt: this.editDialog.data.endAt }}, ({ data }) => {
            resolve(data)
          }, null, (error) => reject(error))
        })
      }
    },
    async onChangeDriverType () {
      if (this.editDialogCopyCompare) {
        if (this.isEdrivers) {
          await this.initMinMinutesQuoted()
          this.initDiscountTypeQuoted()
          this.initDiscountAmountQuoted()
          await this.initTariffQuoted(false, true)
        }

        if (this.isDriveme) {
          await this.initMinMinutesQuoted()
          await this.initTariffQuoted()
        }
      }
      // if (this.editDialogCopyCompare) {
      //   const driverType = this.typesList.find(item => item.id === this.editDialog.data.driverType)
      //   let tariff = driverType ? driverType.price : null
      //   if (this.editDialog.data && this.editDialog.data.price) {
      //     this.setPriceByStatus('tariff', tariff, tariff)
      //   }
      //   this.onChangePassengerOrDriverTypeFunction()
      // }
    },
    onChangePromoAmount () {
      this.initPromoAmountQuoted()
    },
    getCreatedDateTimeByHistory (statusAlias) {
      if (this.editDialog.data.history) {
        const status = this.editDialog.data.history.find(historyItem => historyItem.status.alias === statusAlias)

        if (!status) {
          return 0
        }

        return status.createdAt
      }

      return 0
    },
    getHistoryStatusDiffAsTime (statusStart, statusEnd) {
      const startedStatus = this.editDialog.data.history.find(historyItem => historyItem.status.alias === statusStart)
      const endedStatus = this.editDialog.data.history.find(historyItem => historyItem.status.alias === statusEnd)

      if (!startedStatus || !endedStatus) {
        return 0
      }

      const durationStatuses = moment.duration(moment(endedStatus.createdAt).diff(moment(startedStatus.createdAt)))
      const time = moment.utc((durationStatuses.asSeconds() * 1000)).format('HH:mm')
      return time
    },
    getHistoryStatusDiffAsSeconds (statusStart, statusEnd) {
      const startedStatus = this.editDialog.data.history.find(historyItem => historyItem.status.alias === statusStart)
      const endedStatus = this.editDialog.data.history.find(historyItem => historyItem.status.alias === statusEnd)

      if (!startedStatus || !endedStatus) {
        return 0
      }

      const durationStatuses = moment.duration(moment(endedStatus.createdAt).diff(moment(startedStatus.createdAt)))
      return durationStatuses.asSeconds()
    },
    getTravelTimeByHistory () {
      const statusDiffAsSecondsDuration = this.getHistoryStatusDiffAsSeconds(this.rideStatus.underway.alias, this.rideStatus.started.alias)
      const travelTime = moment.utc((statusDiffAsSecondsDuration * 1000 * 2)).format('HH:mm')

      return travelTime
    },
    getCorrectPriceBodyFinance (price, field) {
      let body = {}
      for (const [key, value] of Object.entries(this.priceEnabled[field])) {
        body[key] = value ? price[field][key] : undefined
      }

      return body
    },
    async loadFinanceExpenses () {
      try {
        if (this.editDialog.data.id) {
          const body = {
            data: {
              quoted: {
                ...this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'quoted'),
                advanceBookingDiscount: this.editDialog.data.advanceBookingDiscount
              },
              declared: this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'declared'),
              driver: this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'driver'),
              passenger: {
                ...this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'passenger'),
                advanceBookingDiscount: this.editDialog.data.advanceBookingDiscount
              }
            }
          }

          const { data: newPrice } = await this.request('POST', `/prices/${this.editDialog.data.id}/preview`, body)
          
          this.priceWatchDisabled = true

          this.$nextTick().then(() => {
              this.$set(this.editDialog.data.price.quoted, 'expenses', newPrice.quoted)
              this.$set(this.editDialog.data.price.driver, 'expenses', newPrice.driver)
              this.$set(this.editDialog.data.price.passenger, 'expenses', newPrice.passenger)
              this.$set(this.editDialog.data.price.quoted, 'total', newPrice.quoted.total)
              this.$set(this.editDialog.data.price.driver, 'total', newPrice.driver.total)
              this.$set(this.editDialog.data.price.passenger, 'total', newPrice.passenger.total)
              this.$nextTick().then(() => {
                  this.priceWatchDisabled = false
              })
          })

          // this.$set(this.editDialog.data.price.driver, 'platformCost', newPrice.driver.platformCost)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async sendFirstConfirmationPassenger () {
      try {
        this.sendConfPassengerLoader = true

        await this.request('POST', `/rides/${this.editDialog.data.id}/message/first-passenger-confirmation`)

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Send first confirmation to customer success!'
        })

        this.sendConfPassengerLoader = false
      } catch (error) {
        this.sendConfPassengerLoader = false
      }
    },
    async sendConfirmationPassenger () {
      try {
        this.sendConfPassengerLoader = true

        await this.request('POST', `/rides/${this.editDialog.data.id}/message/passenger-ride-updated`)

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Send confirmation to customer success!'
        })

        this.sendConfPassengerLoader = false
      } catch (error) {
        this.sendConfPassengerLoader = false
      }
    },
    async sendConfirmationDriver () {
      try {
        this.sendConfDriverLoader = true

        await this.request('POST', `/rides/${this.editDialog.data.id}/message/driver-ride-updated`)

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Send confirmation to driver success!'
        })

        this.sendConfDriverLoader = false
      } catch (error) {
        this.sendConfDriverLoader = false
      }
    },
    async sendConfirmation3rdPerson () {
      try {
        this.sendConf3rdPersonLoader = true
        const body = {
          data: {
            firstName: this.confirmation3rdPerson.firstName,
            lastName: this.confirmation3rdPerson.lastName,
            email: this.confirmation3rdPerson.email
          }
        }

        await this.request('POST', `/rides/${this.editDialog.data.id}/message/notify-second-person`, body)

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: `Send confirmation to ${this.confirmation3rdPerson.email} success!`
        })

        this.sendConf3rdPersonDialog = false
        this.confirmation3rdPerson = {}
        this.sendConf3rdPersonLoader = false
      } catch (error) {
        this.sendConf3rdPersonLoader = false
      }
    },
    async emailEstimatedPrice () {
      this.emailEstimatedPriceLoader = true
      try {
        let body = { data: {} }
        if (this.editDialog.data && this.editDialog.data.price && this.editDialog.data.price.quoted) {
          body.data = {
            ...this.editDialog.data.price
          }
        }

        await this.request('POST', `/rides/${this.editDialog.data.id}/message/estimation`, body)
        this.emailEstimatedPriceLoader = false
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Estimation success'
        })
      } catch (error) {
        console.log(error)
        this.emailEstimatedPriceLoader = false
      }
    },
    async getDriverTypes () {
      return new Promise((resolve, reject) => {
        this.request('GET', '/driver-types?pageSize=9999', { params: { brands: this.selectedBrandId } }, ({ data }) => {
          this.typesList = data.data
          const driverType = this.editDialog.data.driver && this.editDialog.data.driver.types.find(item => item.brand.id == this.selectedBrandId)

          if (this.editDialog.data.currentDriverType) {
            this.editDialog.data.driverType = this.editDialog.data.currentDriverType.id
          } else if (this.editDialog.data.driver && this.editDialog.data.driver.types && driverType) {
            this.editDialog.data.driverType = driverType.id
          } else {
            this.editDialog.data.driverType = null
          }

          this.$forceUpdate()
          resolve(data)
        }, null, (error) => reject(error))
      })
      
    },
    onRemoveInvoiceClick (invoiceId) {
      let isRemove = confirm('Are you sure you want to delete this invoice ?')

      if (isRemove) {
        this.removeInvoice(invoiceId)
      }
    },
    onRemoveReverseInvoiceClick (invoiceId) {
      let isRemove = confirm('Are you sure you want to delete this invoice ?')

      if (isRemove) {
        this.removeReverseInvoice(invoiceId)
      }
    },
    removeInvoice (invoiceId) {
      this.request('DELETE', `/combined-invoices/${invoiceId}`, {}, async () => {
        this.getRidesWithFilters()
        const rideId = this.$route.query && this.$route.query.rideId

        if (rideId) {
          this.openEditDialog(rideId)
        }
      })
    },
    removeReverseInvoice (invoiceId) {
      this.request('DELETE', `/reverse-invoices/${invoiceId}`, {}, async () => {
        this.getRidesWithFilters()
        const rideId = this.$route.query && this.$route.query.rideId

        if (rideId) {
          this.openEditDialog(rideId)
        }
      })
    },
    addPaymentClick () {
      this.invoiceForPay = null
      this.paymentAmount = null
      this.paymentDescription = null
      this.isOpenPaymentModal = true
    },
    getCsvInvoiceData () {
      return new Promise((resolve, reject) => {
        this.request('GET', `/export/ride/${this.editDialog.data.id}/invoice/csv`, {}, ({ data }) => {
          resolve(data)
        }, null, (err) => {
          reject(err)
        })
      })
    },
    getPdfInvoiceData () {
      return new Promise((resolve, reject) => {
        const body = {
          data: {
            rides: [this.editDialog.data.id],
            brand: this.selectedBrandId,
            passenger: this.editDialog.data.passenger.id,
          }
        }
        this.request('POST', '/combined-invoices', body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Invoice is created'
          })
          resolve(data)
        }, null, (err) => {
          reject(err)
        })
      })
    },
    getPdfReverseInvoiceData () {
      return new Promise((resolve, reject) => {
        const body = {
          data: {
            rides: [this.editDialog.data.id],
            brand: this.selectedBrandId,
            driver: this.editDialog.data.driver.id,
            invoicedAt: this.editDialog.data.reverseInvoicedAt ? this.editDialog.data.reverseInvoicedAt : undefined
          }
        }
        this.request('POST', '/reverse-invoices', body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Invoice is created'
          })
          resolve(data)
        }, null, (err) => {
          reject(err)
        })
      })
    },
    async getRideInvoices (rideId) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/rides/${rideId}/invoices`, {}, ({ data }) => {
          let isGeneratedInclude = false
          data.data.forEach(item => {
            if (item.type === 'generated') {
              isGeneratedInclude = true
              this.invoiceStep.isGenerated = true

              if (item.sentAt) {
                this.invoiceStep.isSent = true
              }

              if (item.paidAt) {
                this.invoiceStep.isPaid = true
              }
            }
          })
          if (!isGeneratedInclude) {
            this.invoiceStep.isGenerated = false
            this.invoiceStep.isSent = false
            this.invoiceStep.isPaid = false
          }
          resolve(data.data)
        }, null, (err) => {
          reject(err)
        })
      })
    },
    async fetchInvoices () {
      this.editDialog.invoices = await this.getRideInvoices(this.editDialog.data.id)
    },
    async generateInvoice () {
      try {
        this.generateInvoiceLoader = true
        await this.getPdfInvoiceData()

        const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)

        this.editDialog.invoices = rideInvoices || []
        this.generateInvoiceLoader = false
        // this.setNextStatus(true, 'invoiced', 'paid_out')
      } catch (er) {
        this.generateInvoiceLoader = false
      }
    },
    async generateReverseInvoice () {
      try {
        this.generateReverseInvoiceLoader = true
        await this.getPdfReverseInvoiceData()

        const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)

        this.editDialog.invoices = rideInvoices || []
        this.generateReverseInvoiceLoader = false
      } catch (error) {
        this.generateReverseInvoiceLoader = false
      }
    },
    async pay () {
      this.createPaymentLoader = true

      try {
        let paymentBody = {
          ride: this.editDialog.data.id,
          mandate: parseInt(this.editDialog.data.mandate),
          amount: this.paymentAmount,
          description: this.paymentDescription
        }

        if (this.invoiceForPay) {
          paymentBody = {
            ...paymentBody,
            invoiceId: this.invoiceForPay.id,
            invoiceType: this.invoiceForPay.type
          }
        }

        await this.createPayment(paymentBody)

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Payment is created'
        })

        this.createPaymentLoader = false
        this.isOpenPaymentModal = false

        const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)

        this.editDialog.invoices = rideInvoices || []
      } catch (error) {
        this.createPaymentLoader = false
      }
    },
    async payInvoices () {
      try {
        let invoice = null
        if (this.invoiceForPay.type === 'reverse') {
          invoice = this.editDialog.invoices.find(invoiceItem => this.invoiceForPay && invoiceItem.id === this.invoiceForPay.id)
        } else {
          invoice = this.editDialog.invoices.find(invoiceItem => this.invoiceForPay && invoiceItem.id === this.invoiceForPay.id)
        }

        if (invoice.type === 'manual') {
          this.isOpenPaymentModal = true
          return
        }

        this.paymentLoader = true

        await this.createPayment({
          ride: this.editDialog.data.id,
          mandate: parseInt(this.editDialog.data.mandate),
          amount: parseFloat(invoice.price.total),
          description: `Facturen ${invoice.number}`,
          invoiceId: invoice.id,
          invoiceType: this.invoiceForPay.type
        })

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Payment is created'
        })

        const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)

        this.editDialog.invoices = rideInvoices || []
        this.invoiceForPay = null
        this.getRidesWithFilters()

        this.paymentLoader = false
      } catch (error) {
        this.paymentLoader = false
      }
    },
    async exportCSV () {
      try {
        const invoiceData = await this.getCsvInvoiceData()

        const csvData = {
          'invoice number': invoiceData.info.invoiceNumber,
          'passenger name': invoiceData.ride.passengerName,
          'passenger address': invoiceData.ride.address,
          'po number': invoiceData.ride.poNumber,
          'driver name': invoiceData.ride.driverName,
          'car brand': invoiceData.ride.carBrand,
          'car license plate': invoiceData.ride.carLicensePlate,
          'car model': invoiceData.ride.carModel,
          'car transmission type': invoiceData.ride.carTransmissionType,
          'ride id': invoiceData.ride.id,
          'ride start date': invoiceData.ride.startAt,
          'ride end date': invoiceData.ride.endAt,
          'ride start location': invoiceData.ride.startLocation.address,
          'intermediate stops': invoiceData.ride.intermediateStops.length ? invoiceData.ride.intermediateStops.map(stop => stop.address).join(', ') : '-',
          'ride end location': invoiceData.ride.endLocation.address,
          'declared duration': invoiceData.ride.declaredDuration ? invoiceData.ride.declaredDuration.time : '-',
          'declared waiting time': invoiceData.ride.declaredWaitingTime ? invoiceData.ride.declaredWaitingTime.time : '-',
          'ride costs': invoiceData.ride.rideCost,
          'waiting time costs': invoiceData.ride.prebookedWaitingTimeCost || '-',
          'platform cost': invoiceData.ride.platformCost || '-',
          'insurance cost': invoiceData.ride.insuranceCost || '-',
          'public transport cost': invoiceData.ride.publicTransportCost || '-',
          'extra cost': invoiceData.ride.extraCost || '-',
          'total exclude VAT': invoiceData.ride.totalExclVAT || '-',
          'VAT': invoiceData.ride.vat || '-',
          'total include VAT': invoiceData.ride.totalInclVAT || '-'
        }

        const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)
        this.editDialog.invoices = rideInvoices || []

        csvExport('ride-invoice', csvData)
      } catch (error) {

      }
    },
    async exportPDF () {
      try {
        const invoiceData = await this.getPdfInvoiceData()

        const link = document.createElement('a')
        link.href = invoiceData.url
        link.setAttribute('download', invoiceData.name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)
        this.editDialog.invoices = rideInvoices || []
      } catch (error) {

      }
    },
    onChangeSelectedTags (selectedTagIds) {
      this.editDialog.data.tags = selectedTagIds
    },
    onSelectMandate (value) {
      this.editDialog.data.mandate = value
    },
    onSelectPayInvoice (payOnInvoice) {
      this.editDialog.data.payOnInvoice = payOnInvoice
    },
    onSelectAlternate (alternate, description) {
      this.$set(this.editDialog.data, 'alternatePaymentMethod', alternate)
      this.$set(this.editDialog.data, 'alternatePaymentDescription', description)
    },
    onCarryLuggageChange (value) {
      this.editDialog.data.passenger.options.carryLuggage = value
    },
    onInformalLanguageChange (value) {
      this.editDialog.data.passenger.options.informalLanguage = value
    },
    onKeepDoorsOpenChange (value) {
      this.editDialog.data.passenger.options.keepDoorsOpen = value
    },
    onPlayMusicChange (value) {
      this.editDialog.data.passenger.options.playMusic = value
    },
    getRegionList () {
      new Promise((resolve, reject) => {
        let url = '/regions?pageSize=9999'

        this.request('GET', url, {}, ({ data }) => {
          this.regionList = data.data
          resolve()
        }, null, (error) => {
          reject(error)
        })
      })
    },
    async saveInvoiceFile (reference, file, fileName) {
      return new Promise((resolve, reject) => {
        let body = {
          data: {
            'name': fileName,
            'content': file,
            'type': 'invoice',
            'reference': reference
          }
        }

        this.request('POST', '/files', body, ({ data }) => {
          resolve(data)
        }, null, (error) => reject(error))
      })
    },
    async updateRideDrivers () {
      const rideDriversPromises = Object.keys(this.tariff).map((subscriptionId) => {
        const driver = this.subscribedDrivers.find(item => item.id === parseInt(subscriptionId))
        const isFixed = driver && driver.isFixed

        const body = {
          data: {
            options: {
              trip: isFixed ? this.tariff[subscriptionId] : undefined,
              insurance: this.insurance[subscriptionId],
              platformCost: this.platformCost[subscriptionId],
              publicTransport: this.publicTransport[subscriptionId]
            },
            passengerOptions: this.editDialog.data.passenger.options,
            isAborted: false // multiple request
          }
        }

        return new Promise((resolve) => {
          this.request('PATCH', `/ride-drivers/${subscriptionId}`, body, () => {
            resolve()
          }, null, () => { this.buttonLoader = false })
        })
      })

      await Promise.all(rideDriversPromises)
    },
    async previewFiles (event) {
      this.files = this.$refs.myFiles.files

      var input = event.target

      for (let file of input.files) {
        var reader = new FileReader()
        this.previewFilesLoading = true

        reader.onload = async (e) => {
          let imageData = e.target.result.replace(/^data:application.+;base64,/, '')
          let imageName = file.name

          await this.saveInvoiceFile(this.editDialog.data.id, imageData, imageName)

          const rideInvoices = await this.getRideInvoices(this.editDialog.data.id)

          this.editDialog.invoices = rideInvoices || []
          this.invoiceForPay = null
          this.previewFilesLoading = false
        }

        reader.readAsDataURL(file)
      }
    },
    async onSubscribeDriver (rideId, driverIds, isInvite = false) {
      if (!driverIds.length) {
        return null
      }

      const subscribeDrivers = driverIds.map((driverId) => {
        const body = {
          data: {
            isAborted: false, // multiple request
            ride: rideId,
            driver: driverId,
            confirm: isInvite
          }
        }

        return new Promise((resolve) => {
          this.request('POST', '/ride-drivers', body, () => {
            resolve()
          }, null, () => { this.buttonLoader = false })
        })
      })

      this.subscribeDriverButtonLoader = true

      await Promise.all([subscribeDrivers])

      this.getRidesWithFilters()

      const rideIdd = this.$route.query && this.$route.query.rideId

      if (rideIdd) {
        this.request('GET', `/rides/${rideIdd}`, {}, async ({ data }) => {
          const ride = { ...data }
          this.subscribeDriverButtonLoader = false
          this.subscribedDrivers = await this.getSubscribedDrivers(rideIdd)
          this.subscribedDriverIds = ride ? this.subscribedDrivers.map(ride => ride.driver.id) : []

          this.driversSubscribeList = this.driversList.filter(driver => !this.subscribedDriverIds.includes(driver.id))
          this.editDialog.subscribeDriverIds = []

          this.$notify({
            group: 'topRight',
            type: 'success',
            text: isInvite ? 'Drivers are invited' : 'Drivers are subscribed'
          })
          this.openEditDialog(rideIdd)
        })
      }
    },
    updateFilterProps (filterProps) {
      this.filterProps = filterProps
    },
    getRides (params = {}, successCB) {
      this.loading = true

      const _params = {
        pageSize: 200,
        ...params
      }

      let body = { params: _params }

      this.request('GET', `/rides`, body, ({ data }) => {
        this.loading = false
        this.ridesList = data.data
        this.totalRides = data.total

        if (successCB) {
          successCB(data.data)
        }
      })
    },
    getRidesWithFilters (filters = {}) {
      this.getRides({ ...this.filterProps, ...filters })
    },
    openDeleteDialog (item) {
      this.deleteDialogConfirmation = () => {
        this.request('DELETE', `/rides/${item.id}`, {}, ({ data }) => {
          this.$notify({
            group: 'rides',
            type: 'warning',
            text: 'Rit is verwijderd'
          })
          this.$refs.deleteConfirmationDialog.close()
          this.getRidesWithFilters()
        })
      }

      this.$refs.deleteConfirmationDialog.openDialog()
    },
    openElectDeleteDialog () {
      this.deleteElectDialogConfirmation = async () => {
        this.isRemoveElectDriver = true

        // this.editDialog.priceChangedConfirmFunction = () => {
        //   this.editDialog.closePriceChangedModal()
        //   this.editDialog.saveFunction(true)
        // }

        // this.editDialog.priceChangedCancelFunction = () => {
        //   this.editDialog.closePriceChangedModal()
        //   this.editDialog.saveFunction()
        // }

        this.$refs.deleteElectConfirmationDialog.close()
        // this.editDialog.openPriceChangedModal()
        this.editDialog.saveFunction(true)
      }

      this.$refs.deleteElectConfirmationDialog.openDialog()
    },
    restoreRide (item) {
      this.request('PATCH', `/trash/un-delete/ride/${item.id}`, {}, ({ data }) => {
        this.$notify({
          group: 'rides',
          type: 'warning',
          text: 'Ride is restored'
        })
        this.getRidesWithFilters()
      })
    },
    openDeleteDialogSubscription ({ rideId, subscriptionId }) {
      this.deleteDialogConfirmationSubscription = () => {
        this.buttonLoader = true
        this.request('DELETE', `/ride-drivers/${subscriptionId}`, {}, ({ data }) => {
          this.$refs.deleteSubscriptionConfrimationDialog.close()
          this.getRides(this.filterProps, async (rides) => {
            const ride = rides.filter(ride => ride.id.toString() === rideId.toString())[0]
            this.subscribedDrivers = await this.getSubscribedDrivers(rideId)
            this.subscribedDriverIds = ride ? this.subscribedDrivers.map(subDriver => subDriver.driver.id) : []

            this.driversSubscribeList = this.driversList.filter(driver => !this.subscribedDriverIds.includes(driver.id))
            this.editDialog.subscribeDriverIds = []

            if (!this.subscribedDriverIds.length) {
              this.getRidesWithFilters()
              this.openEditDialog(rideId)
            }

            this.$notify({
              group: 'topRight',
              type: 'warning',
              text: 'Deleted'
            })
            this.buttonLoader = false
          })
        }, null, () => { this.buttonLoader = false })
      }

      this.$refs.deleteSubscriptionConfrimationDialog.openDialog()
    },
    getSubscribedDrivers (rideId, body = {}) {
      return new Promise ((resolve, reject) => {
        this.request('GET', `/rides/${rideId}/subscribed-drivers?pageSize=200`, body, ({ data }) => {
          if (data.data && data.data.length) {
            data.data.forEach(item => {
              item.isFixed = item.price.type === 'fixed'
            })
          }
          resolve(data.data)
        }, (loader) => { this.subscribeDriverButtonLoader = loader })
      })
    },
    updatePrice (rideId, body = {}) {
      return new Promise ((resolve, reject) => {
        for (const [keyFrom, valueFrom] of Object.entries(this.priceClass)) {
          if (body.data[keyFrom]) {
            if (body.data[keyFrom].changes) {
              if (!body.data[keyFrom].changes.manual || !body.data[keyFrom].changes.manual.length) {
                body.data[keyFrom].changes.manual = []
              }

              if (!body.data[keyFrom].changes.auto || !body.data[keyFrom].changes.auto.length) {
                body.data[keyFrom].changes.auto = []
              }
            } else {
              body.data[keyFrom].changes = {
                manual: [],
                auto: []
              }
            }
          }

          for (const [keyField, valueField] of Object.entries(valueFrom)) {
            if (valueField && (valueField === PRICE_BORDER_COLOR.manualAfter || valueField === PRICE_BORDER_COLOR.manual)) {
              body.data[keyFrom].changes.manual.push(keyField)
            } else if (valueField && valueField === PRICE_BORDER_COLOR.auto) {
              body.data[keyFrom].changes.auto.push(keyField)
            }
          }
        }

        const isChangeDriver = body.data.driver.changes.manual.length || body.data.driver.changes.auto.length
        const isChangePassenger= body.data.passenger.changes.manual.length || body.data.passenger.changes.auto.length
        if (this.editDialog.data.price && this.editDialog.data.price.passenger && this.editDialog.data.price.passenger.expenses) {
          const isTripCostLessTripTimePrice = parseFloat(body.data.passenger.tripCost) < parseFloat(this.editDialog.data.price.passenger.expenses.tripTime)
          if (body.data.passenger.changes.auto.includes('tripCost') && isTripCostLessTripTimePrice) {
            this.$notify({
              duration: 10000,
              group: 'topRight',
              type: 'warning',
              text: "This is a debug message, and if you see it, it means something is wrong with the customer's invoice price. Please remember the steps that led to this issue (as well as the name and version of the operating system and browser) and contact the development department."
            })
          }
        }

        this.request('PUT', `/prices/${rideId}`, body, ({ data }) => {
          if (isChangeDriver || isChangePassenger) {
            const pdfBody = this.editDialog.invoices.filter(invoice => !invoice.sentAt && invoice.active).map((invoice, index) => ({
              key: index + 1,
              id: invoice.id,
              type: invoice.type
            }))

            if (pdfBody.length) {
              this.request('POST', '/invoice-pdfs/bulk', { data: pdfBody }, () => {
                this.$notify({
                  group: 'topRight',
                  type: 'success',
                  text: 'Invoices is rewrited'
                })
              });
            }
          }
          resolve(data)
        }, null, (error) => { this.buttonLoader = false; reject(error) })
      })
    },
    onElectDriverClick ({ rideId, driverId }) {
      // this.editDialog.priceChangedConfirmFunction = () => {
      //   this.editDialog.closePriceChangedModal()
      //   this.onElectDriver({ rideId, driverId, isPriceChanged: true })
      // }

      // this.editDialog.priceChangedCancelFunction = () => {
      //   this.editDialog.closePriceChangedModal()
      //   this.onElectDriver({ rideId, driverId, isPriceChanged: false })
      // }

      // this.editDialog.openPriceChangedModal()

      this.onElectDriver({ rideId, driverId, isPriceChanged: true })
    },
    onElectDriver ({ rideId, driverId, isPriceChanged }) {
      this.buttonLoader = true

      let body = {
        data: {
          driver: driverId
        }
      }

      this.request('POST', `/rides/${rideId}/elect`, body, async ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Driver is elected'
        })

        const status = {
          data: {
            status: 3
          }
        }

        this.request('PATCH', `/rides/${rideId}`, status, async ({ data }) => {
          this.buttonLoader = false
          // this.subscribedDrivers = []

          this.getRidesWithFilters()

          const rideId = this.$route.query && this.$route.query.rideId

          if (rideId) {
            if (isPriceChanged) {
              if (data.driver) {
                this.editDialog.data = {
                  ...this.editDialog.data,
                  driver: data.driver,
                  driverId: data.driver.id
                }
              }
              this.subscribedDrivers = await this.getSubscribedDrivers(rideId)
              // const electSubscription = this.subscribedDrivers.find(item => item.driver && item.driver.id === driverId)

              if (this.isEdrivers && !this.editDialog.data.driverType) {
                await this.initMinMinutesQuoted()
              }

              if (this.isDriveme) {
                await this.initMinMinutesQuoted()
              }

              if ((this.isDriveme || this.isEdrivers) && !this.editDialog.data.driverType) {
                await this.initTariffQuoted()
              }

              const bodyPrice = {
                data: {
                  quoted: this.normalizePriceData({ ...this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'quoted')}),
                  declared: this.normalizePriceData({ ...this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'declared')}),
                  driver: this.normalizePriceData({ ...this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'driver')}),
                  passenger: this.normalizePriceData({ ...this.getCorrectPriceBodyFinance(this.editDialog.data.price, 'passenger')}),
                  type: this.isFixedPriceCheckbox ? 'fixed' : 'regular'
                }
              }
              await this.updatePrice(rideId, bodyPrice)
            }

            this.openEditDialog(rideId)
          }
        }, null, () => { this.buttonLoader = false })

        this.editDialog.data.driverId = driverId
      }, null, () => { this.buttonLoader = false })
    },
    async onConfirmDriver ({ rideId, subscriptionId }) {
      try {
        this.buttonLoader = true

        await this.request('PATCH', `/ride-drivers/${subscriptionId}`, {
          data: {
            confirmed: true
          }
        })

        this.subscribedDrivers = await this.getSubscribedDrivers(rideId)
      } catch (error) {
        this.buttonLoader = false
      }

      this.buttonLoader = false
    },
    getRide (rideId) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/rides/${rideId}`, {}, ({ data }) => {
          resolve(data)
        }, null, (error) => reject(error))
      })
    },
    checkDeclaredAfterSomeHours () {
      const isEnabledDeclaredColumn = this.priceEnabled.declared.startRideToPassenger && this.priceEnabled.declared.startRideWithPassenger && this.priceEnabled.declared.endRideWithPassenger && this.priceEnabled.declared.arrivalAtHome
      if (this.editDialog.data.endAt && this.editDialog.data.statusSequence < this.rideStatus.declared.sequence && isEnabledDeclaredColumn && this.priceActive.declared) {
        const duration = moment.duration(moment(Date.now()).diff(moment(this.editDialog.data.endAt)))
        const hours = duration.asHours()
        if (hours >= 4) {
          this.setPrice('declared', 'startRideToPassenger', this.editDialog.data.startAt)
          this.setPrice('declared', 'startRideWithPassenger', this.editDialog.data.startAt)
          this.setPrice('declared', 'endRideWithPassenger', this.editDialog.data.endAt)
          this.setPrice('declared', 'arrivalAtHome', this.editDialog.data.endAt)
        }
      }
    },
    openDeleteChatDialog (chatId) {
      this.deleteChatDialogConfirmation = () => {
        this.buttonLoader = true
        this.request('DELETE', `/chats/ride/${chatId}`, {}, ({ data }) => {
          this.$refs.deleteChatConfirmationDialog.close()
          this.$notify({
            group: 'topRight',
            type: 'warning',
            text: 'Chat is deleted'
          })

          this.editDialog.data.chat = null
          this.getRidesWithFilters()
        }, null, () => { this.buttonLoader = false })
      }

      this.$refs.deleteChatConfirmationDialog.openDialog()
    },
    async openEditDialogClick (_val, copy = false) {
      let val = { ..._val }

      if (copy) {
        try {
          const rideDetail = await this.getRide(_val.id)
          const { data: copiedRide } = await this.request('POST', `/rides/${val.id}/clone`)

          val = copiedRide
          this.priceClass.driver = {}

          const bodyPrice = {
            data: {
              quoted: this.normalizePriceData(rideDetail.price.quoted),
              declared: this.normalizePriceData(rideDetail.price.declared),
              driver: { changes: {} },
              passenger: { changes: {} },
              type: this.isFixedPriceCheckbox ? 'fixed' : 'regular'
            }
          }

          await this.openEditDialog(val.id)
          await this.updatePrice(val.id, bodyPrice)
        } catch (err) {
          return err
        }
      }

      this.$router.replace({ query: {
        ...this.$route.query,
        rideId: val.id
      } }).catch(err => err)

      this.openEditDialog(val.id)
    },
    normalizePriceData (obj) {
      if (obj.tripTime && typeof obj.tripTime === 'object') {
        obj.tripTime = this.formatMinutesToString(obj.tripTime.minutes)
      }

      if (obj.travelTime && typeof obj.travelTime === 'object') {
        obj.travelTime = this.formatMinutesToString(obj.travelTime.minutes)
      }

      if (obj.prebookedWaitingTime && typeof obj.prebookedWaitingTime === 'object') {
        obj.prebookedWaitingTime = this.formatMinutesToString(obj.prebookedWaitingTime.minutes)
      }

      if (obj.extraWaitingTime && typeof obj.extraWaitingTime === 'object') {
        obj.extraWaitingTime = this.formatMinutesToString(obj.extraWaitingTime.minutes)
      }

      if (obj.minMinutes && typeof obj.minMinutes === 'object') {
        obj.minMinutes = this.formatMinutesToString(obj.minMinutes.minutes)
      }

      if (obj.startRideToPassenger && typeof obj.startRideToPassenger === 'object') {
        obj.startRideToPassenger = obj.startRideToPassenger.date
      }

      if (obj.arrivalAtPickup && typeof obj.arrivalAtPickup === 'object') {
        obj.arrivalAtPickup = obj.arrivalAtPickup.date
      }

      if (obj.startRideWithPassenger && typeof obj.startRideWithPassenger === 'object') {
        obj.startRideWithPassenger = obj.startRideWithPassenger.date
      }

      if (obj.endRideWithPassenger && typeof obj.endRideWithPassenger === 'object') {
        obj.endRideWithPassenger = obj.endRideWithPassenger.date
      }

      if (obj.arrivalAtHome && typeof obj.arrivalAtHome === 'object') {
        obj.arrivalAtHome = obj.arrivalAtHome.date
      }

      obj.startRideToPassenger = obj.startRideToPassenger && moment(obj.startRideToPassenger).format('YYYY-MM-DD' + 'T' + 'HH:mm')
      obj.arrivalAtPickup = obj.arrivalAtPickup && moment(obj.arrivalAtPickup).format('YYYY-MM-DD' + 'T' + 'HH:mm')
      obj.startRideWithPassenger = obj.startRideWithPassenger && moment(obj.startRideWithPassenger).format('YYYY-MM-DD' + 'T' + 'HH:mm')
      obj.endRideWithPassenger = obj.endRideWithPassenger && moment(obj.endRideWithPassenger).format('YYYY-MM-DD' + 'T' + 'HH:mm')
      obj.arrivalAtHome = obj.arrivalAtHome && moment(obj.arrivalAtHome).format('YYYY-MM-DD' + 'T' + 'HH:mm')

      return obj
    },
    emptyPriceData () {
      return {
        publicTransport: null,
        travelTime: null,
        travelKm: null,
        travelKmTariff: null,
        prebookedWaitingTime: null,
        prebookedWaitingTimeTariff: null,
        extraWaitingTime: null,
        extraWaitingTimeTariff: null,
        tripTime: null,
        minMinutes: null,
        tripKm: null,
        distance: null,
        platformCost: null,
        tariff: null,
        intermediateStops: null,
        intermediateStopCost: null,
        tripCost: null,
        insurance: null,
        promoAmount: null,
        discountType: null,
        discountAmount: null,
        startRideToPassenger: null,
        arrivalAtPickup: null,
        startRideWithPassenger: null,
        endRideWithPassenger: null,
        arrivalAtHome: null,
        alreadyPaid: null,
      }
    },
    isChangePriceData (data, base, from) {
      if (data && data[from]) {
        for (const [key, value] of Object.entries(data[from])) {
          // if old value !== null & new value !== old value
          if (this.inputPriceChange[from] && this.inputPriceChange[from][key] && !lodash.isEqual(this.inputPriceChange[from][key].value, value)) {
            return true
          }
        }
      }

      return false
    },
    async openEditDialog (rideId) {
      this.editDialog.data.id = null
      this.inputPriceChange = {
        quoted: {},
        declared: {},
        driver: {},
        passenger: {}
      }
      this.subscribedDrivers = []
      this.tariff = {}

      this.editDialog.closeFunction = async () => {
        const query = { ...this.$route.query }
        delete query.rideId

        this.$store.dispatch('setAdminRideOverviewFilters', query)

        this.$router.replace({ query }).catch(err => err)
        this.subscribedDrivers = []
        this.editDialogCopyCompare = null
        this.isFixedPriceCheckbox = false
        this.insuranceEnabled = false
        this.editDialog.show = false
        this.buttonLoader = false
        this.editDialog.data.tags = null
      }

      this.editDialogCopyCompare = null
      this.editDialog.show = true
      this.buttonLoader = true
      this.emailEstimate = {}
      this.subscribedDriverIds = []
      const ride = await this.getRide(rideId).catch(() => { this.buttonLoader = false })
      this.openDialogRide = ride
      this.rideNotes = []
      this.passengerNotes = []
      this.driverNotePage = 1
      this.passengerNotePage = 1
      this.driverNoteTotalPage = 0
      this.passengerNoteTotalPage = 0
      this.showLoading = true
      this.invoiceForPay = null
      this.showNextStatusButton = true
      this.editDialog.data = this.normaliseEditFields({ ...ride })
      this.editDialog.dataInitial = this.normaliseEditFields({ ...ride })
      this.editDialog.showPaymentMethods = true
      this.editDialog.data.poNumber = this.editDialog.data.poNumber ? this.editDialog.data.poNumber : this.editDialog.data.passenger.poNumber
      this.editDialog.data.price = this.editDialog.data.price ? {
        expenses: ride.price && ride.price.expenses ? ride.price.expenses : {
          platformCost: null,
          insurance: null,
          publicTransportCost: null
        },
        ...ride.price,
        quoted: ride.price && ride.price.quoted ? { ...this.normalizePriceData(ride.price.quoted) } : this.emptyPriceData(),
        declared: ride.price && ride.price.declared ? { ...this.normalizePriceData(ride.price.declared) } : this.emptyPriceData(),
        driver: ride.price && ride.price.driver ? { ...this.normalizePriceData(ride.price.driver) } : this.emptyPriceData(),
        passenger: ride.price && ride.price.passenger ? { ...this.normalizePriceData(ride.price.passenger) } : this.emptyPriceData(),        
        type: ride.price && ride.price.type && ride.price.quoted  ? ride.price.type : 'regular'
      } :  {
        quoted: this.emptyPriceData(),
        declared: this.emptyPriceData(),
        driver: this.emptyPriceData(),
        passenger: this.emptyPriceData(),
        type: 'regular'
      }

      if (!this.editDialog.data.price && ride.status && ride.status.sequence >= this.rideStatus.declared.sequence) {
        this.editDialog.data.price = {
          quoted: {},
          declared: {},
          driver: {},
          passenger: {},
          type: 'regular',
        }
      }

      this.priceClassInit.quoted = this.initPriceClassItem('quoted')
      this.priceClassInit.declared = this.initPriceClassItem('declared')
      this.priceClassInit.driver = this.initPriceClassItem('driver')
      this.priceClassInit.passenger = this.initPriceClassItem('passenger')

      const clearChangesPriceData = (from) => {
        if (this.editDialog.data.price[from].changes) {
          this.editDialog.data.price[from].changes.auto = []
          this.editDialog.data.price[from].changes.manual = []
        }
      }
      clearChangesPriceData('quoted')
      clearChangesPriceData('declared')
      clearChangesPriceData('driver')
      clearChangesPriceData('passenger')

      if (this.editDialog.data.price && this.editDialog.data.price.driver && this.editDialog.data.price.driver.insurance) {
        delete this.editDialog.data.price.driver.insurance
      }

      this.isFixedPriceCheckbox = this.editDialog.data.price && this.editDialog.data.price.type === 'fixed'

      this.insuranceEnabled = Boolean(this.editDialog.data.insurance)

      this.subscribeDriverButtonLoader = false
      if (ride.stops.length) {
        this.editDialog.stopsData = ride.stops.map(stop => {
          return Object.assign({}, stop)
        })
      }

      this.subscribedDrivers = []

      const rideInvoices = await this.getRideInvoices(ride.id)

      this.editDialog.invoices = rideInvoices || []

      await this.getPassengerCars(ride.passenger.id)

      if (ride.car) {
        this.editDialog.data = {
          ...this.editDialog.data,
          car: ride.car
        }
      }

      if (ride.prebookedWaitingTime) {
        this.editDialog.data = {
          ...this.editDialog.data,
          prebookedWaitingTime: ride.prebookedWaitingTime.time
        }
        if (this.editDialog.stopsData && this.editDialog.stopsData.length) {
          this.editDialog.stopsData[0].prebookedWaitingTime = ride.prebookedWaitingTime.minutes
        }
      }

      if (ride.passenger) {
        this.editDialog.data.billingAddress = ride.billingAddress

        if (!this.editDialog.data.billingAddress) {
          this.handleChangeInvoiceType(this.editDialog.data.passenger.invoiceType)
        }

        this.editDialog.data = {
          ...this.editDialog.data,
          passengerId: ride.passenger.id
        }

        this.getPassengerData(ride.passenger.id)
      }

      if (ride.driver) {
        this.editDialog.data = {
          ...this.editDialog.data,
          driverId: ride.driver.id
        }
      }

      if (ride.mandate) {
        this.editDialog.data = {
          ...this.editDialog.data,
          mandate: ride.mandate.id
        }
      }

      this.editDialog.data = {
        ...this.editDialog.data,
        payOnInvoice: ride.payOnInvoice,
        regions: ride.regions.map(region => region.id),
        tags: ride.tags.map(tag => tag.id),
        status: ride.status && ride.status.id,
        statusSequence: ride.status && ride.status.sequence
      }
      this.nextStatus(false)

      this.showLoading = false

      this.subscribedDrivers = await this.getSubscribedDrivers(rideId)
      this.subscribedDriverIds = this.subscribedDrivers.map(ride => ride.driver.id)
      
      this.driversSubscribeList = this.driversList.filter(driver => !this.subscribedDriverIds.includes(driver.id))
      this.editDialog.subscribeDriverIds = []

      await this.getRideExpenses(ride.id)

      await this.getDriverNotes(ride.id, this.driverNotePerPage)
      await this.getPassengerNotes(this.editDialog.data.passengerId, this.driverNotePerPage)

      const { data: appConfigData } = await this.request('GET', '/me/app-config', {})
      this.appConfig = appConfigData

      await this.getDriverTypes()

      this.editDialog.openPriceChangedModal = () => {
        this.isOpenPriceChangesModal = true
      }

      this.editDialog.closePriceChangedModal = () => {
        this.isOpenPriceChangesModal = false 
      }

      this.editDialog.saveFunctionModal = async (isCloseAfterSave = false) => {
        const newPrice = this.editDialog.data.price
        const oldPrice = this.editDialogCopyCompare.data.price
        const isChangePriceExcludeFromNull = this.isChangePriceData(newPrice, oldPrice, 'quoted') || this.isChangePriceData(newPrice, oldPrice, 'declared') || this.isChangePriceData(newPrice, oldPrice, 'driver') || this.isChangePriceData(newPrice, oldPrice, 'passenger')
        const isChangePriceIncludeFromNull = !lodash.isEqual(newPrice, oldPrice)
        
        const isEnded = this.isSetStatusOrHigher(this.rideStatus.ended)
        const isDeclared = this.isSetStatusOrHigher(this.rideStatus.declared)
        // const isDeclarationApproved = this.isSetStatusOrHigher(this.rideStatus.declaration_approved)

        if (isEnded || isDeclared) {
          this.buttonLoader = true
          if (isEnded) {
            await this.initPriceDeclared()
          }

          if (isDeclared) {
            await this.initPriceDriver()
            await this.initPricePassenger()
          }
          this.buttonLoader = false

          const isChangePriceExcludeFromNull2 = this.isChangePriceData(newPrice, oldPrice, 'quoted') || this.isChangePriceData(newPrice, oldPrice, 'declared') || this.isChangePriceData(newPrice, oldPrice, 'driver') || this.isChangePriceData(newPrice, oldPrice, 'passenger')
          if (isChangePriceExcludeFromNull2) {
            this.editDialog.priceChangedConfirmFunction = () => {
              this.editDialog.closePriceChangedModal()
              this.editDialog.saveFunction(true, isCloseAfterSave)
            }

            this.editDialog.priceChangedCancelFunction = () => {
              this.editDialog.closePriceChangedModal()
              this.editDialog.saveFunction(false, isCloseAfterSave)
            }

            this.editDialog.openPriceChangedModal()
          } else {
            this.editDialog.saveFunction(true, isCloseAfterSave)
          }

        } else if (isChangePriceExcludeFromNull) {
          this.editDialog.priceChangedConfirmFunction = () => {
            this.editDialog.closePriceChangedModal()
            this.editDialog.saveFunction(true, isCloseAfterSave)
          }

          this.editDialog.priceChangedCancelFunction = () => {
            this.editDialog.closePriceChangedModal()
            this.editDialog.saveFunction(false, isCloseAfterSave)
          }

          this.editDialog.openPriceChangedModal()
        } else {
          this.editDialog.saveFunction(isChangePriceIncludeFromNull, isCloseAfterSave)
        }
      }

      this.editDialog.saveFunction = async (isPriceChanged = false, isCloseAfterSave = false) => {
        this.editDialog.closePriceChangedModal()

        try {
          this.buttonLoader = true

          if (this.subscribedDrivers.length) {
            await this.updateRideDrivers()
          }

          let body = {
            data: cleanDefaultFields({ ...this.editDialog.data })
          }

          if (!body.data.regionsSet && !lodash.isEqual(body.data.regions, this.editDialogCopyCompare.data.regions)) {
            body.data.regionsSet = true
          }
          
          if (!body.data.regionsSet) {
            body.data.regions = undefined
          }
          
          if (this.isRemoveElectDriver) {
            body.data.driver = null
            body.data.driverId = null
            body.data.status = this.getStatusByName('subscribed').id
            body.data.statusSequence = this.getStatusByName('subscribed').sequence

            if (this.isEdrivers || this.isDriveme) {
              this.editDialog.data.driver = null
              await this.initMinMinutesQuoted()
              await this.initTariffQuoted()
            }

            this.isRemoveElectDriver = false
          }

          let bodyPrice = { data: {} }

          if (body.data.price) {
            bodyPrice.data = {
              quoted: this.getCorrectPriceBodyFinance(body.data.price, 'quoted'),
              declared: this.getCorrectPriceBodyFinance(body.data.price, 'declared'),
              driver: this.getCorrectPriceBodyFinance(body.data.price, 'driver'),
              passenger: this.getCorrectPriceBodyFinance(body.data.price, 'passenger'),
              type: this.isFixedPriceCheckbox ? 'fixed' : 'regular'
            }
          }

          if (body.data.price) {
            delete body.data.price
          }

          body.data.passenger = body.data.passengerId
          body.data.driver = body.data.driverId || null
          body.data.advanceBookingDiscount = body.data.advanceBookingDiscount || null
          body.data.insurance = this.insuranceEnabled
          body.data.currentDriverType = body.data.driverType
          if (body.data.carPassengerName) {
            body.data.carPassengerName = body.data.carPassengerName.trim()
          }

          if (body.data.car) {
            body.data.car = lodash.isArray(body.data.car) && !body.data.car.length ? null : (body.data.car.id || body.data.car)
          }

          if (body.data.brand) {
            body.data.brand = body.data.brand.id || body.data.brand
          }

          delete body.data.passengerId
          delete body.data.driverId

          if (this.selectedBrandName !== BRAND.EDRIVERS) {
            delete body.data.poNumber
          }

          // if (
          //   body.data.statusSequence === this.rideStatus.draft.sequence &&
          //   body.data.brand &&
          //   this.selectedBrandName !== 'rentabob' &&
          //   body.data.endAt &&
          //   body.data.passenger &&
          //   body.data.startAt &&
          //   this.editDialog.stopsData.length >= 2 &&
          //   this.editDialog.stopsData[0].address &&
          //   this.editDialog.stopsData[this.editDialog.stopsData.length - 1].address
          // ) {
          //   body.data.status = this.getStatusByName('new').id
          //   body.data.statusSequence = this.getStatusByName('new').sequence
          // }

          let updateRide = () => new Promise((resolve, reject) => {
            this.request('PATCH', `/rides/${ride.id}`, body, ({ data }) => {
              if (this.editDialog.data.passengerNote) {
                const noteData = {
                  data: {
                    text: this.editDialog.data.passengerNote,
                    passenger: data.passenger.id,
                    isAborted: false
                  }
                }

                this.request('POST', '/notes', noteData, () => {
                  this.editDialog.data.passengerNote = ''
                })
              }

              if (this.editDialog.data.rideNote) {
                const noteData = {
                  data: {
                    text: this.editDialog.data.rideNote,
                    ride: data.id,
                    isAborted: false
                  }
                }

                this.request('POST', '/notes', noteData, () => {
                  this.editDialog.data.rideNote = ''
                })
              }

              resolve(data)
            }, null, (error) => { this.buttonLoader = false; reject(error) })
          })

          try {
            const stopsDataValid = this.editDialog.stopsData.filter(rideItem => rideItem.address && rideItem.address.trim())

            if (!lodash.isEqual(stopsDataValid, this.editDialogCopyCompare.stopsData)) {
              await this.request('DELETE', `/rides/${this.editDialog.data.id}/stops`)

              let stopsBody = []

              for (let stopDataItem = 0; stopDataItem < stopsDataValid.length; stopDataItem++) {
                stopsBody.push({
                  'key': stopDataItem + 1,
                  'ride': rideId,
                  'index': stopDataItem,
                  'address': stopsDataValid[stopDataItem].address.trim(),
                  'postalCode': '-',
                  'city': stopsDataValid[stopDataItem].city || '-',
                  'country': 'Netherlands',
                  'dateTime': '',
                  'houseNumber': '',
                  'houseNumberExtension': '',
                  'data': stopsDataValid[stopDataItem].data || null,
                  'arriveAt': stopsDataValid[stopDataItem].arriveAt,
                  'departAt': stopsDataValid[stopDataItem].departAt
                })
              }

              const { data: stopsResponse } = await this.request('POST', `/rides/${this.editDialog.data.id}/stops`, { data: stopsBody })
              stopsResponse.forEach(item => {
                if (!item.success && item.errors && item.errors.length) {
                  item.errors.forEach(error => {
                    this.$notify({
                      group: 'topRight',
                      type: 'error',
                      text: `Key ${item.key}: ${error.message}`
                    })
                  })
                }
              })
            }

            const updateRideData = await updateRide()
            this.editDialog.data.statusSequence = updateRideData.status.sequence
            if (this.editDialog.subscribeDriverIds.length) {
              await this.onSubscribeDriver(rideId, this.editDialog.subscribeDriverIds)
            }

            if (isPriceChanged) {
              await this.updatePrice(ride.id, bodyPrice)
            }

            await this.getRidesWithFilters()

            this.$notify({
              group: 'rides',
              type: 'success',
              text: 'Route is opgeslagen'
            })

            if (!isCloseAfterSave) {
              this.openEditDialog(rideId)
            } else {
              this.editDialog.closeFunction()
            }

            this.subscribedDrivers = []
          } catch (error) {
            console.log(error)
          }
        } catch (err) {
          if (isCloseAfterSave) {
            this.editDialog.closeFunction()
          }

          this.buttonLoader = false
        }
      }

      console.log(this.editDialog, this.getStatusByName('declaration_approved').sequence)

      this.buttonLoader = false
      this.declareData.travelTime = this.getTravelTimeByHistory()
      this.editDialogCopyCompare = lodash.cloneDeep(this.editDialog)
      this.checkDeclaredAfterSomeHours()
      this.loadFinanceExpenses()
    },
    async createRide () {
      this.inputPriceChange = {
        quoted: {},
        declared: {},
        driver: {},
        passenger: {}
      }

      this.editDialog.closeFunction = async () => {
        this.subscribedDrivers = []
        this.editDialogCopyCompare = null
        this.isFixedPriceCheckbox = false
        this.insuranceEnabled = false
        this.editDialog.show = false
        this.buttonLoader = false
        this.editDialog.data.tags = null
      }

      this.editDialog.data = {
        passengerId: null,
        price: null
      }
      this.subscribedDriverIds = []
      this.editDialog.data = {
        ...this.editDialog.data,
        passenger: {
          invoiceType: 'private'
        },
        startAt: moment().format('YYYY-MM-DD' + 'T' + 'HH:mm'),
        endAt: moment().format('YYYY-MM-DD' + 'T' + 'HH:mm')
      }
      this.editDialog.stopsData = []
      this.rideExpenses = []
      this.expense = {
        isApproved: true,
        file: FILE
      }
      this.rideNotes = []
      this.passengerNotes = []
      this.driverNotePage = 1
      this.passengerNotePage = 1
      this.driverNoteTotalPage = 0
      this.passengerNoteTotalPage = 0
      this.editDialog.show = true
      this.editDialog.invoices = []
      this.invoiceForPay = null
      this.editDialog.showPaymentMethods = false
      this.showNextStatusButton = false

      const { data: appConfigData } = await this.request('GET', '/me/app-config', {})
      this.appConfig = appConfigData

      this.editDialog.data.price = {
        ...this.editDialog.data.price,
        ...lodash.cloneDeep(this.emptyPriceObject)
      }

      this.editDialog.openPriceChangedModal = () => {
        this.isOpenPriceChangesModal = true
      }

      this.editDialog.closePriceChangedModal = () => {
        this.isOpenPriceChangesModal = false 
      }

      this.editDialog.saveFunctionModal = async (isCloseAfterSave = false) => {
        const newPrice = this.editDialog.data.price
        const oldPrice = this.editDialogCopyCompare.data.price
        const isChangePriceExcludeFromNull = this.isChangePriceData(newPrice, oldPrice, 'quoted') || this.isChangePriceData(newPrice, oldPrice, 'declared') || this.isChangePriceData(newPrice, oldPrice, 'driver') || this.isChangePriceData(newPrice, oldPrice, 'passenger')
        const isChangePriceIncludeFromNull = !lodash.isEqual(newPrice, oldPrice)

        if (isChangePriceExcludeFromNull) {
          this.editDialog.priceChangedConfirmFunction = () => {
            this.editDialog.closePriceChangedModal()
            this.editDialog.saveFunction(true, isCloseAfterSave)
          }

          this.editDialog.priceChangedCancelFunction = () => {
            this.editDialog.closePriceChangedModal()
            this.editDialog.saveFunction(false, isCloseAfterSave)
          }

          this.editDialog.openPriceChangedModal()
        } else {
          this.editDialog.saveFunction(isChangePriceIncludeFromNull, isCloseAfterSave)
        }
      }

      this.editDialog.saveFunction = async (isPriceChanged = false, isCloseAfterSave = false) => {
        this.editDialog.closePriceChangedModal()
        this.buttonLoader = true

        let body = {
          data: { ...this.editDialog.data }
        }

        body.data.passenger = body.data.passengerId
        body.data.driver = body.data.driverId || null
        body.data.currentDriverType = body.data.driverType
        body.data.insurance = this.insuranceEnabled

        if (body.data.carPassengerName) {
          body.data.carPassengerName = body.data.carPassengerName.trim()
        }

        if (body.data.duration) {
          body.data.duration = body.data.duration
        }

        if (body.data.durationWaitingTime) {
          body.data.durationWaitingTime = body.data.durationWaitingTime
        }

        if (body.data.travelTime) {
          body.data.travelTime = body.data.travelTime
        }

        const car = body.data.car
        delete body.data.driverId
        delete body.data.passengerId
        delete body.data.car

        if (this.selectedBrandName !== BRAND.EDRIVERS) {
          delete body.data.poNumber
        }

        let bodyPrice = { data: {} }

        if (body.data.price) {
          bodyPrice.data = {
            quoted: this.getCorrectPriceBodyFinance(body.data.price, 'quoted'),
            declared: this.getCorrectPriceBodyFinance(body.data.price, 'declared'),
            driver: this.getCorrectPriceBodyFinance(body.data.price, 'driver'),
            passenger: this.getCorrectPriceBodyFinance(body.data.price, 'passenger'),
            type: this.isFixedPriceCheckbox ? 'fixed' : 'regular'
          }
        }

        if (body.data.price) {
          delete body.data.price
        }

        if (
          !body.data.status &&
          body.data.brand &&
          // this.selectedBrandName !== 'rentabob' &&
          body.data.endAt &&
          body.data.passenger &&
          body.data.startAt &&
          this.editDialog.stopsData.length >= 2 &&
          this.editDialog.stopsData[0].address &&
          this.editDialog.stopsData[this.editDialog.stopsData.length - 1].address
        ) {
          body.data.status = this.getStatusByName('new').id
        }

        await this.request('POST', '/rides', body, async ({ data }) => {
          if (this.editDialog.data.passengerNote) {
            const noteData = {
              data: {
                text: this.editDialog.data.passengerNote,
                passenger: data.passenger.id,
              }
            }

            await this.request('POST', '/notes', noteData, () => {
              this.editDialog.data.passengerNote = ''
            })
          }

          if (this.editDialog.data.rideNote) {
            const noteData = {
              data: {
                text: this.editDialog.data.rideNote,
                ride: data.id,
              }
            }

            await this.request('POST', '/notes', noteData, () => {
              this.editDialog.data.rideNote = ''
            })
          }

          if (car) {
            const carData = {
              data: {
                car: car.id
              }
            }

            await this.request('PATCH', `/rides/${data.id}`, carData)
          }

          const stopsDataValid = this.editDialog.stopsData.filter(item => item.address && item.address.trim())
          let stopsBody = []

          for (let stopDataItem = 0; stopDataItem < stopsDataValid.length; stopDataItem++) {
            stopsBody.push({
              'key': stopDataItem + 1,
              'ride': data.id,
              'index': stopDataItem,
              'address': stopsDataValid[stopDataItem].address.trim(),
              'postalCode': '-',
              'city': stopsDataValid[stopDataItem].city || '-',
              'country': 'Netherlands',
              'dateTime': '',
              'houseNumber': '',
              'houseNumberExtension': '',
              'data': stopsDataValid[stopDataItem].data || null,
              'arriveAt': stopsDataValid[stopDataItem].arriveAt,
              'departAt': stopsDataValid[stopDataItem].departAt
            })
          }

          const { data: stopsResponse } = await this.request('POST', `/rides/${data.id}/stops`, { data: stopsBody })
          stopsResponse.forEach(item => {
            if (!item.success && item.errors && item.errors.length) {
              item.errors.forEach(error => {
                this.$notify({
                  group: 'topRight',
                  type: 'error',
                  text: `Key ${item.key}: ${error.message}`
                })
              })
            }
          })

          if (this.editDialog.data.imageData != null) {
            let imageData = this.editDialog.data.imageData.replace(/^data:application.+;base64,/, '')

            await this.saveInvoiceFile(data.id, imageData).then(() => {
              this.$notify({
                group: 'topRight',
                type: 'success',
                text: 'Rit is aangemaakt'
              })
              // this.editDialog.show = false
              this.subscribedDrivers = []
              this.getRidesWithFilters()

              this.buttonLoader = false
            })
          } else {
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Rit is aangemaakt'
            })
            // this.editDialog.show = false
            this.subscribedDrivers = []
            await this.getRidesWithFilters()
            this.buttonLoader = false
          }

          if (this.editDialog.subscribeDriverIds.length) {
            await this.onSubscribeDriver(data.id, this.editDialog.subscribeDriverIds)
          }

          if (data && data.status && data.status.sequence >= this.getStatusByName('new').sequence) {
            await this.request('POST', `/rides/${data.id}/publish`, {})
          }

          if (isPriceChanged && data) {
            await this.updatePrice(data.id, bodyPrice)
          }

          // this.editDialog.show = false
          this.subscribedDrivers = []
          await this.getRidesWithFilters()
          this.buttonLoader = false
          if (data) {
            if (!isCloseAfterSave) {
              this.openEditDialog(data.id)
                this.$router.replace({ query: {
                ...this.$route.query,
                rideId: data.id
              } }).catch(err => err)
            } else {
              this.editDialog.closeFunction()
            }
          }
        }, null, () => {
          this.buttonLoader = false
        })
      }
      this.editDialogCopyCompare = lodash.cloneDeep(this.editDialog)
    },
    normaliseEditFields (data) {
      data.passengerId = (data.passenger ? data.passenger.id : null)
      data.driverId = (data.driver ? data.driver.id : null)
      data.status = data.status.id
      data.advanceBookingDiscount = data.discount.advanceBooking ? data.discount.advanceBooking.id : null
      data.duration = data.duration && Boolean(parseFloat(data.duration.minutes)) ? this.formatMinutesToString(data.duration.minutes) : null
      data.durationWaitingTime = data.durationWaitingTime && Boolean(parseFloat(data.durationWaitingTime.minutes)) ? this.formatMinutesToString(data.durationWaitingTime.minutes) : null
      data.travelTime = data.travelTime && Boolean(parseFloat(data.travelTime.minutes)) ? this.formatMinutesToString(data.travelTime.minutes) : null
      return data
    },
    openEditNoteDialog (note) {
      this.noteDialog = true
      this.editNoteObject = note
    },
    editNote () {
      const data = {
        data: {
          text: this.editNoteObject.text
        }
      }

      this.request('PATCH', `/notes/${this.editNoteObject.id}`, data, () => {
        this.noteDialog = false

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'De notitie is bijgewerkt'
        })
      })
    },
    openRemoveNoteDialog (id) {
      this.removeNoteDialog = true
      this.removeNoteId = id
    },
    cancelRemoveNoteDialog () {
      if (confirm('Are you sure you want to close?')) {
        this.removeNoteId = null
        this.removeNoteDialog = false
      }
    },
    deleteNote: function () {
      this.request('DELETE', `/notes/${this.removeNoteId}`, {}, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'De notitie is verwijderd'
        })
        // this.rideNotes = this.rideNotes.filter(item => item.id !== this.removeNoteId)
        this.loadNotes()
        this.removeNoteId = null
        this.removeNoteDialog = false
      })
    },
    async getDriverNotes (id, limit, page = 1, sortDir = 'desc') {
      this.loadingNote = true

      return new Promise((resolve, reject) => {
        this.request('GET', `/notes?ride=${id}&page=${page}&pageSize=${limit}&sort=createdAt&sortDir=${sortDir}`, {}, ({ data }) => {
          this.driverNoteTotalPage = Math.ceil(data.total / this.driverNotePerPage)
          this.rideNotes = data.data
          this.loadingNote = false
          resolve(data)
        }, null, (error) => reject(error))
      })
    },
    async getPassengerNotes (id, limit, page = 1, sortDir = 'desc') {
      this.loadingNote = true

      return new Promise((resolve, reject) => {
        this.request('GET', `/notes?passenger=${id}&page=${page}&pageSize=${limit}&sort=createdAt&sortDir=${sortDir}`, {}, ({ data }) => {
          this.passengerNoteTotalPage = Math.ceil(data.total / this.driverNotePerPage)
          this.passengerNotes = data.data
          this.loadingNote = false
          resolve(data)
        }, null, (error) => reject(error))
      })
    },
    async loadNotes () {
      await this.getDriverNotes(this.editDialog.data.id, this.driverNotePerPage, this.driverNotePage, this.sortDirection)
      await this.getPassengerNotes(this.editDialog.data.passengerId, this.driverNotePerPage, this.passengerNotePage, this.sortDirection)
    },
    async loadRideNotes () {
      await this.getDriverNotes(this.editDialog.data.id, this.driverNotePerPage, this.driverNotePage, this.sortDirection)
    },
    async loadPassengerNotes () {
      await this.getPassengerNotes(this.editDialog.data.passengerId, this.driverNotePerPage, this.passengerNotePage, this.sortDirection)
    },
    getPassengerCars (id) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/cars?passenger=${id}`, {}, ({ data }) => {
          this.cars = data.map(item => {
            return {
              id: item.id,
              brand: item.brand,
              model: item.model,
              licensePlate: item.licensePlate,
              transmissionType: item.transmissionType
            }
          })

          this.$forceUpdate()
          resolve(data)
        }, null, (error) => reject(error))
      })
    },
    getPassenger (id) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/passengers/${id}`, {}, ({ data }) => {
          this.editDialog.data.passenger = data
          this.editDialog.billingAddress = data.invoiceType === 'private' ? data.addressPrivate : data.addressBusiness
          this.editDialog.privateAddress = data.addressPrivate

          if (!this.editDialog.data.id) {
            this.handleChangeInvoiceType(data.invoiceType)

            if (this.isEdrivers) {
              this.editDialog.data.driverType = data.defaultDriverType && data.defaultDriverType.id
            }
          }

          const rideId = this.$route.query && this.$route.query.rideId
          // address prefilling with private address (create ride modal e-drivers)
          if (!rideId && this.brandList.find(item => item.id === this.editDialog.data.brand).name === BRAND.EDRIVERS && data.addressPrivate) {
            const firstAddress = this.editDialog.stopsData[0].address
            const lastAddress = this.editDialog.stopsData[this.editDialog.stopsData.length - 1].address
            this.editDialog.stopsData[0].address = firstAddress ? firstAddress : data.addressPrivate
            this.editDialog.stopsData[this.editDialog.stopsData.length - 1].address = lastAddress ? lastAddress : data.addressPrivate
          }

          if (!rideId && this.brandList.find(item => item.id === this.editDialog.data.brand).name === BRAND.RENTABOB && data.addressPrivate) {
            const firstAddress = this.editDialog.stopsData[0].address
            const lastAddress = this.editDialog.stopsData[this.editDialog.stopsData.length - 1].address
            this.editDialog.stopsData[0].address = firstAddress ? firstAddress : null
            this.editDialog.stopsData[this.editDialog.stopsData.length - 1].address = lastAddress ? lastAddress : data.addressPrivate
          }
          resolve(data)
        }, (loader => { this.loading = loader }), (error) => reject(error))
      })
    },
    // onChangePassengerOrDriverTypeFunction () {
    //   if (this.editDialog.data && this.editDialog.data.price) {
    //     const passengerDiscounts = this.editDialog.data.passenger && this.editDialog.data.passenger.driverTypeDiscounts && this.editDialog.data.passenger.driverTypeDiscounts.length ? this.editDialog.data.passenger.driverTypeDiscounts : null
    //     const currentDiscount =  passengerDiscounts ? passengerDiscounts.find(item => item.driverType.id === this.editDialog.data.driverType) : null

    //     if (passengerDiscounts && this.editDialog.data.driverType && currentDiscount && this.isEdrivers) {
    //       this.setPriceByStatus('discountType', currentDiscount.type, currentDiscount.type)
    //       this.setPriceByStatus('discountAmount', currentDiscount.amount, currentDiscount.amount)
    //     } else {
    //       this.setPriceByStatus('discountType', null, null)
    //       this.setPriceByStatus('discountAmount', null, null)
    //     }
        
    //   }
    // },
    async getPassengerData (id) {
      this.editDialog.data.passengerId = id
      await this.getPassengerCars(id)
      await this.getPassenger(id)
      if (this.isEdrivers && (!this.editDialog.data.poNumber || !this.editDialog.data.id)) {
        this.editDialog.data.poNumber = this.editDialog.data.passenger.poNumber
        this.$forceUpdate()
      }

      if (this.isEdrivers && this.editDialogCopyCompare) {
        this.initDiscountTypeQuoted()
        this.initDiscountAmountQuoted()
      }

      if (this.editDialogCopyCompare) {
        this.$set(this.editDialog.data, 'carPassengerName', this.editDialog.data.passenger.name)
        this.$set(this.editDialog.data, 'carPassengerPhone', this.editDialog.data.passenger.user.phone.number)
        this.$set(this.editDialog.data, 'adminNote', this.editDialog.data.passenger.adminNote)
      }
    },
    getStatusByName (statusName) {
      if (!this.statusesList.length) {
        return {}
      }
      return this.statusesList.find(status => status.alias === statusName)
    },
    isSetStatusOrHigher (status) {
      if (this.statusesList.length && status) {
        const oldStatusObj = this.statusesList.find(
          (item) => item.id === this.editDialogCopyCompare.data.status
        )

        const currentStatusObj = this.statusesList.find(
          (item) => item.id === this.editDialog.data.status
        )

        const oldStatusSequence = oldStatusObj ? oldStatusObj.sequence : -1
        const newStatusSequence = currentStatusObj ? currentStatusObj.sequence : -1

        return oldStatusSequence < status.sequence && newStatusSequence >= status.sequence
      }

      return false
    },
    async setNextStatus (isClicked, currentStatus, nextStatus) {
      if (this.editDialogCopyCompare && this.statusesList && this.statusesList.length) {
        const oldStatusObj = this.statusesList.find(
          (item) => item.id === this.editDialogCopyCompare.data.status
        )

        const oldStatusSequence = oldStatusObj ? oldStatusObj.sequence : -1
        const newStatusSequence = this.getStatusByName(currentStatus).sequence

        const isEnded = oldStatusSequence < this.rideStatus.ended.sequence && newStatusSequence >= this.rideStatus.ended.sequence
        const isDeclared = oldStatusSequence < this.rideStatus.declared.sequence && newStatusSequence >= this.rideStatus.declared.sequence
        // const isDeclarationApproved = oldStatusSequence < this.rideStatus.declaration_approved.sequence && newStatusSequence >= this.rideStatus.declaration_approved.sequence

        if (isClicked && (isEnded || isDeclared)) {
          this.setNextStatusFunction({ isClicked, currentStatus, nextStatus, isEnded, isDeclared })
        } else {
          this.setNextStatusFunction({ isClicked, currentStatus, nextStatus })
        }
      } else {
        this.setNextStatusFunction({ isClicked, currentStatus, nextStatus })
      }
    },
    async setNextStatusFunction ({ isClicked, currentStatus, nextStatus, isEnded = false, isDeclared = false }) {
      if (isClicked) {
        this.nextStatusLoader = true
        const body = {
          data: {
            status: this.getStatusByName(currentStatus).id
          }
        }

        try {
          if (currentStatus === 'retracted') {
            await this.request('PATCH', `/rides/${this.$route.query.rideId}/retract`, { data: { retractionReason: '' } })
          } else {
            const { data: newRideData } = await this.request('PATCH', `/rides/${this.$route.query.rideId}`, body)
            this.editDialog.data.history = newRideData.history
          }

          this.editDialog.data.status = this.getStatusByName(currentStatus).id
          this.editDialog.data.statusSequence = this.getStatusByName(currentStatus).sequence
          this.nextStatusAlias = nextStatus.replace('_', ' ')
          this.nextStatusLoader = false

          if (isEnded || isDeclared) {
            this.editDialog.saveFunctionModal()
          }

          this.getRidesWithFilters()
        } catch (error) {
          this.nextStatusLoader = false
        }
      } else {
        if (this.editDialog.data.status) {
          const currentStatusObj = this.statusesList.find(
            (item) => item.id.toString() === this.editDialog.data.status.toString()
          )

          this.editDialog.data.statusSequence = currentStatusObj.sequence
        }

        this.nextStatusAlias = currentStatus
      }
    },
    nextStatus (isClicked) {
      this.isNextStatus = false

      const currentStatus = this.statusesList.find(
        (item) => item.id.toString() === this.editDialog.data.status.toString()
      )

      if (currentStatus.alias === 'draft') {
        this.setNextStatus(isClicked, 'retracted', 'new')
      } else if (currentStatus.alias === 'retracted') {
        this.setNextStatus(isClicked, 'new', 'underway')
      } else if (currentStatus.alias === 'new') {
        this.setNextStatus(isClicked, 'subscribed', 'waiting')
        this.isNextStatus = true
      } else if (currentStatus.alias === 'subscribed') {
        this.setNextStatus(isClicked, 'elected', 'underway')
        this.isNextStatus = true
      } else if (currentStatus.alias === 'elected') {
        this.setNextStatus(isClicked, 'underway', 'waiting')
      } else if (currentStatus.alias === 'underway') {
        this.setNextStatus(isClicked, 'waiting', 'started')
      } else if (currentStatus.alias === 'waiting') {
        this.setNextStatus(isClicked, 'started', 'break')
      } else if (currentStatus.alias === 'started') {
        this.setNextStatus(isClicked, 'break', 'ended')
      } else if (currentStatus.alias === 'break') {
        this.setNextStatus(isClicked, 'ended', 'declared')
      } else if (currentStatus.alias === 'ended') {
        this.setNextStatus(isClicked, 'declared', 'declaration_approved')
      } else if (currentStatus.alias === 'declared') {
        this.setNextStatus(isClicked, 'declaration_approved', 'invoiced')
        if (isClicked) {
          this.isNextStatus = true
        }
      } else if (currentStatus.alias === 'declaration_approved') {
        this.setNextStatus(isClicked, 'invoiced', 'paid_out')
        // if (!isClicked) {
        //   this.isNextStatus = true
        // }
      } else if (currentStatus.alias === 'invoiced' || currentStatus.alias === 'paid') {
        if (isClicked) {
          this.editDialog.data.status = this.getStatusByName('paid_out').id
          this.isNextStatus = true
        } else {
          this.nextStatusAlias = 'paid out'
        }
      } else {
        this.isNextStatus = true
        this.nextStatusAlias = 'paid out'
      }
    },
    handleChangeStatus () {
      this.nextStatus(false)
    },
    formatStartStopArrive () {
      if (this.editDialog.stopsData && this.editDialog.stopsData.length && this.editDialog.stopsData[0]) {
        this.editDialog.stopsData[0].arriveAt = this.editDialog.data.startAt
        this.$forceUpdate()
      }
    },
    formatEndStopDepart () {
      if (this.editDialog.stopsData && this.editDialog.stopsData.length && this.editDialog.stopsData[this.editDialog.stopsData.length - 1]) {
        this.editDialog.stopsData[this.editDialog.stopsData.length - 1].departAt = this.editDialog.data.endAt
        this.$forceUpdate()
      }
    },
    formatDepartTime (key) {
      const object = moment(this.editDialog.stopsData[key].arriveAt).add(this.editDialog.stopsData[key].prebookedWaitingTime, 'minutes')

      this.editDialog.stopsData[key].departAt = object.format('YYYY-MM-DD' + 'T' + 'HH:mm')

      if (key === 0) {
        this.initStartRideWithPassengerQuoted()
      }

      this.$forceUpdate()
    },
    formatArriveTime (key) {
      const object = moment(this.editDialog.stopsData[key].departAt).subtract(this.editDialog.stopsData[key].prebookedWaitingTime, 'minutes')

      this.editDialog.stopsData[key].arriveAt = object.format('YYYY-MM-DD' + 'T' + 'HH:mm')

      if (key === this.editDialog.stopsData.length - 1) {
        this.initEndRideWithPassengerQuoted()
      }

      this.$forceUpdate()
    },
    formatWaitingTime (key) {
      const duration = moment.duration(moment(this.editDialog.stopsData[key].departAt).diff(moment(this.editDialog.stopsData[key].arriveAt)))

      this.editDialog.stopsData[key].prebookedWaitingTime = duration.asMinutes()
      this.$forceUpdate()
    },
    formatNextArriveTime (key) {
      const travelTime = this.editDialog.stopsData[key].travelTime ? this.editDialog.stopsData[key].travelTime.value : 0
      const object = this.editDialog.stopsData[key].departAt ? moment(this.editDialog.stopsData[key].departAt).add(travelTime, 'seconds') : moment(this.editDialog.stopsData[key].arriveAt).add(travelTime, 'seconds')
      // this.editDialog.data.endAt = this.editDialog.stopsData[key].arriveAt
      this.editDialog.stopsData[key + 1].arriveAt = object.format('YYYY-MM-DD' + 'T' + 'HH:mm')

      if ((key + 1) === this.editDialog.stopsData.length - 1) {
        this.initEndRideWithPassengerQuoted()
      }

      this.$forceUpdate()
    },
    async setEndTime (key) {
      this.editDialog.data.endAt = this.editDialog.stopsData[key].departAt
      await this.onChangeEndAt()
      this.$forceUpdate()
    },
    async setEndTimeFromArrive (key) {
      this.editDialog.data.endAt = this.editDialog.stopsData[key].arriveAt
      await this.onChangeEndAt()
      this.$forceUpdate()
    },
    getTravelTime (key) {
      const origin = this.editDialog.stopsData[key].address
      const destination = this.editDialog.stopsData[key + 1].address
      const departureTime = this.editDialog.stopsData[key].departAt

      googleMaps.getDistanceBetweenAddresses(origin, destination, departureTime, 'pessimistic', (data) => {
        this.editDialog.stopsData[key].travelTime = data.rows[0].elements[0].duration_in_traffic
        this.editDialog.stopsData[key].maxTravelTime = Math.round(parseFloat(data.rows[0].elements[0].duration_in_traffic.value / 60))
        this.editDialog.stopsData[key].minDistance = Math.round(parseFloat(data.rows[0].elements[0].distance.value / 1000))

        this.$forceUpdate()
      }, () => {})
      googleMaps.getDistanceBetweenAddresses(origin, destination, departureTime, 'optimistic', (data) => {
        this.editDialog.stopsData[key].minTravelTime = Math.round(parseFloat(data.rows[0].elements[0].duration_in_traffic.value / 60))
        this.editDialog.stopsData[key].maxDistance = Math.round(parseFloat(data.rows[0].elements[0].distance.value / 1000))
        this.$forceUpdate()
      }, () => {
        this.$notify({
          group: 'topRight',
          type: 'error',
          text: 'Departure time is in the past. Traffic information is only available for future and current times',
          duration: 4000
        })
      })
    },
    setDepartTime (key) {
      const object = moment(this.editDialog.stopsData[key].arriveAt).subtract(this.editDialog.stopsData[key - 1].travelTime.value, 'seconds')

      this.editDialog.stopsData[key - 1].departAt = object.format('YYYY-MM-DD' + 'T' + 'HH:mm')

      if ((key - 1) === 0) {
        this.initStartRideWithPassengerQuoted()
      }

      this.$forceUpdate()
    },
    async setArriveTime (key) {
      this.editDialog.data.startAt = this.editDialog.stopsData[key].arriveAt
      await this.onChangeStartAt()
      this.$forceUpdate()
    },
    async updateWaitingTime (value) {
      // await this.initPrebookedWaitingTimeQuoted()
      this.editDialog.stopsData[0].prebookedWaitingTime = parseInt(value.slice(-2))
    },
    openExpenseModal () {
      this.expense = {
        isApproved: true,
        file: FILE
      }
      this.isOpenExpenseModal = true
    },
    async getRideExpenses (id) {
      return new Promise((resolve, reject) => {
        this.loadingExpense = true
        this.request('GET', `/expenses?ride=${id}`, {}, ({ data }) => {
          this.rideExpenses = data.data
          resolve(data)
          this.loadingExpense = false
        }, null, (error) => {
          this.loadingExpense = false
          reject(error)
        })
      })
      
    },
    addExpense () {
      this.loadingExpense = true

      const data = {
        data: {
          ride: this.editDialog.data.id,
          driverAmount: this.expense.driverAmount,
          passengerAmount: this.expense.passengerAmount,
          note: this.expense.note,
          type: this.expense.type,
          approved: this.expense.isApproved
        }
      }

      if (this.expense.id) {
        this.request('PATCH', `/expenses/${this.expense.id}`, data, () => {
          this.isOpenExpenseModal = false

          if (this.expense.file.content) {
            const expenseFile = {
              data: {
                name: this.expense.file.name,
                content: this.expense.file.content.replace('data:', '').replace(/^.+,/, ''),
                type: 'expense',
                reference: this.expense.id
              }
            }

            this.request('POST', '/files', expenseFile, () => {
              this.$notify({
                group: 'topRight',
                type: 'success',
                text: 'Expense has been updated!'
              })
              this.getRideExpenses(this.editDialog.data.id)
            })
          } else {
            this.getRideExpenses(this.editDialog.data.id)
          }

          this.$refs.fileInput.value = null
          this.loadFinanceExpenses()
        })
      } else {
        this.request('POST', '/expenses', data, ({ data }) => {
          this.isOpenExpenseModal = false

          if (this.expense.file.content) {
            const expenseFile = {
              data: {
                name: this.expense.file.name,
                content: this.expense.file.content.replace('data:', '').replace(/^.+,/, ''),
                type: 'expense',
                reference: data.id
              }
            }

            this.request('POST', '/files', expenseFile, () => {
              this.$notify({
                group: 'topRight',
                type: 'success',
                text: 'Expense has been created!'
              })
              this.getRideExpenses(this.editDialog.data.id)
            })
          } else {
            this.getRideExpenses(this.editDialog.data.id)
          }
          this.$refs.fileInput.value = null
          this.loadFinanceExpenses()
        })
      }
    },
    setPaymentStatus () {
      const status = {
        data: {
          status: this.getStatusByName('paid').id
        }
      }

      this.request('PATCH', `/rides/${this.editDialog.data.id}`, status, () => {
        this.editDialog.show = false
        this.getRidesWithFilters()
      })
    },
    approveAllExpenses (value) {
      this.rideExpenses.forEach((item, key) => {
        this.rideExpenses[key].approved = value

        const data = {
          data: {
            approved: value
          }
        }
        this.request('PATCH', `/expenses/${item.id}`, data)
      })

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: value ? 'All expenses have been approved!' : 'All expenses did not approve!'
      })
    },
    approveExpense (value, id) {
      const data = {
        data: {
          approved: value
        }
      }
      this.request('PATCH', `/expenses/${id}`, data, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Expense has been approved!'
        })
      })
    },
    removeExpense (id) {
      this.request('DELETE', `/expenses/${id}`, {}, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Expense has been deleted!'
        })
        this.getRideExpenses(this.editDialog.data.id)
        this.loadFinanceExpenses()
      })
    },
    editExpense (item) {
      this.isOpenExpenseModal = true
      this.expense = {
        id: item.id,
        note: item.note,
        isApproved: item.approved,
        driverAmount: item.driverAmount,
        passengerAmount: item.passengerAmount ? item.passengerAmount : item.driverAmount,
        type: item.type.id.toString(),
        file: item.file ? {
          ...this.expense.file,
          id: item.file.id,
          name: item.file.originalName,
          url: item.file.url
        } : FILE
      }
      this.$forceUpdate()
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      this.expense.file = FILE
      const files = event.target.files

      if (files[0] !== undefined) {
        createBase64File(files[0]).then((result) => {
          this.expense.file = { ...this.expense.file, content: result, name: files[0].name }
        })
      }
    },
    formatMinutesToString (value) {
      const time = moment.duration(value, 'minutes')
      const days = time.get('days')
      const hours = time.get('hours') + (days * 24)
      const minutes = time.get('minutes')
      const duration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`

      return duration.toString()
    },
    setStatusToNew () {
      this.setNextStatus(true, 'new', 'subscribed')
      this.isNextStatus = true
    },
    setStatusToRetract () {
      this.setNextStatus(true, 'retracted', 'new')
    },
    handleChangeInvoiceType (value) {
      this.editDialog.data.billingAddress =
        value === 'private'
          ? this.editDialog.data.passenger.addressPrivate
          : this.editDialog.data.passenger.addressBusiness
    },
    close () {
      this.driversDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .ride-container {
    padding: 0;

    .remove-invoice-text {
      color: red;
      cursor: pointer;
    }

    .invoice-total {
      display: flex;
      flex-wrap: wrap;

    }

    .subscribe-drivers {
      display: flex;
      height: 100%;
      margin: 0 !important;
    }

    .upload-file {
      position: relative;
      height: 36px;
      margin-bottom: 10px;

      label {
        position: absolute;
        border-radius: 2px;
        padding: 5px 10px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }

      input {
        visibility: hidden;
        width: 100%;
      }
    }

    .finance-label {
      font-size: 15px;
      display: flex;
      align-items: center;
      color: #404040;
    }

    .finance-title {
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      text-transform: uppercase;
    }

    .payment-holder,
    .checkbox-holder {
      border: 2px solid rgba(0,0,0,0.54);
      border-radius: 4px;
      padding: 12px;
    }

    .checkbox-holder {
      height: 58px !important;
      align-items: center;
    }

    .field-wrapper {
      border: 2px solid rgba(0,0,0,0.54);
      border-radius: 4px;
      padding: 12px;
      margin: 0 0 15px 0;

      .custom-label {
        font-size: 1.1rem;
      }
    }

    .ride-stops-holder {
      padding: 34px 0 0 0;
    }

    .driver-note-wrapper {
      .custom-label {
        font-size: 12px;
      }

      .driver-note-holder {
        border-radius: 5px;
        padding: 10px;
        background-color: #F1F1F1;
        margin: 0 0 10px 0;

        .note-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .pagination-holder {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .sort-direction {
      width: 160px;
      margin: 20px 0 auto auto;
    }

    .loader-holder {
      min-height: 300px;
      z-index: 1;
      position: relative;
    }

    .box-card {
      gap: 15px
    }

    .link-holder {
      position: absolute;
      right: 12px;
      top: -27px;
      height: auto !important;
    }

    .text-white {
      color: #ffffff;
    }

    .subscribe-drivers-table {
      td {
        padding: 0 8px !important;
      }
    }

    .action-holder {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 50px;

      &.right-pos {
        justify-content: flex-end;
      }
    }

    .action-holder-travel-time {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;

      &.right-pos {
        justify-content: flex-end;
      }
    }

    .btn-time-left {
      justify-self: flex-start;
    }

    .btn-time-right {
      justify-self: flex-end;
      width: 22px;
      height: 22px;
    }

    .travel-time-value {
      font-size: 12px;
      text-align: center;
    }

    .depart-pos {
      position: absolute;
      right: 0;
      left: 0;
      top: -30px;
      height: auto !important;
    }

    .arrive-pos {
      position: absolute;
      left: 0;
      top: -37px;
      right: 0;
      margin: 0 auto;
      width: 40px;
    }

    .start-arrow {
      position: absolute;
      bottom: -19px;
      height: auto !important;
      left: 85px;
      right: 0;
      width: 40px;
    }

    .duration-item {
      width: 100%;
      max-width: 30%;
    }

    .middle-action {
      flex-direction: column;
    }

    .last-action {
      position: absolute;
      height: auto !important;
      right: 90px;
      top: -30px;
    }

    .first-field {
      margin-bottom: 3px;
    }

    .end-field {
      margin-top: -17px !important;
    }

    .next-arrive {
      bottom: -11px;
      position: absolute;
      right: 0;
      left: 0;
    }
  }

  .note-dialog {
    background-color: white;
    z-index: 1;
    padding: 20px;
    width: 100%;
    max-width: 500px;
  }

  .price-dialog {
    max-width: 700px !important;
  }

  .note-actions {
    display: flex;

    .v-btn {
      color: white;
      width: 100%;
      flex: 1;
    }
  }

  .expense-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5px;
    margin-top: 15px;

    td, th {
      padding: 5px 10px;
      border: 2px solid rgba(0, 0, 0, 0.54);
      text-align: center;
    }

    th {
      color: #ffffff;
      background-color: gray;
    }

    tr:nth-child(odd) {
      td {
        background-color: #E0E0E0;
      }
    }
  }

  .btn-expense {
    width: 200px;
  }

  .export-action {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 15px;

    button {
      flex-grow: 1;
    }
  }

  .btn-expense-action {
    width: 30px !important;
    height: 30px !important;
  }

  .item-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .download-file {
    color: #000000;
    text-decoration: underline;
    font-size: 14px;
  }

  .middle-action {
    width: 15px;
  }

  .down-depart {
    height: 22px !important;
  }

  .align-items-end {
    align-items: end;
  }

  .copy-icon {
    position: absolute;
    right: 19px;
    top: 6px;
  }

  .calculate-label {
    text-wrap: nowrap;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 13px;
    font-weight: bold;
  }
</style>

<style lang="scss">
  .ride-cost-input {
    .v-text-field__suffix {
      font-size: 11px;
      color: grey;
      font-weight: bold;
    }
    min-width: 180px !important;
  }
  .finance-form {
    .v-text-field .v-input__control .v-input__slot {
      min-height: auto !important;
    }

    .v-text-field .v-input__control .v-input__slot input {
      margin-top: 0;
    }
  }

  .bg-input-red .v-input__control {
    background: #ffc9c9 !important
  }
  
  .bg-input-green .v-input__control {
    background: #52e59f !important
  }


  .bg-grey {
    background: rgba(0, 0, 0, 0.1)
  }

  .bg-dark-grey {
    background: rgba(0, 0, 0, 0.2)
  }

  .bg-lightGray {
    background: rgba(0, 0, 0, 0.03)
  }

  .br-div.br-blue, .br-blue .v-input__slot {
    border-color: #8c8cff !important;
  }

  .br-div.br-purple, .br-purple .v-input__slot {
    border-color: #e30ae3 !important;
  }

  .br-div.br-yellow, .br-yellow .v-input__slot {
    border-color: #ffe82a !important;
  }

  .br-div {
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
  }


  .hover-line:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.1s;
  }

  .add-hour-buttons {
    .v-btn {
      min-width: auto;
      padding: 1px 8px;
      font-size: 12px;
      border-radius: 6px;
      height: auto;
      margin: 0 2px 2px 3px;
    }

    display: flex;
    justify-content: flex-end;
  }
</style>