<template>
  <v-dialog
    v-model="dialog"
    full-width
    class="drivers-subscribed-dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        class="ma-0 ml-1 fill-height"
        small
        style="min-width: 50px;"
        v-on="on"
        @click="onBtnClick"
      >
        <v-icon >
          toc
        </v-icon>
      </v-btn
      >
    </template>
    <v-card class="elevation-0 pa-3">
      <v-card-title>
        <span class="headline">Drivers</span>
      </v-card-title>

      <v-card-title class="pa-1">
        <div class="mb-4">
          <v-layout wrap>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border pa-3 d-flex align-center justify-center">
                  <v-range-slider
                    v-model="tariffFilter"
                    :max="50"
                    :min="0"
                    :step="1"
                    thumb-size="28"
                    thumb-label="always"
                    hide-details
                    label="Tariff"
                    @change="onChangeTariff"
                  />
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border pa-3 d-flex align-center justify-center">
                  <v-autocomplete
                    v-model="certificatesFilter"
                    :items="certificatesList"
                    :item-text="(val) => { return val.title + ' id: ' + val.id }"
                    :item-value="val => val"
                    multiple
                    label="Certificate"
                  />
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border pa-3 d-flex align-center justify-center">
                  <v-dialog
                    ref="dialog"
                    v-model="availableDateModal"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="availableDate"
                        label="Available date"
                        readonly
                        clearable
                        hide-details
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="availableDate"
                      :first-day-of-week="1"
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="availableDateModal = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border pa-3 d-flex align-center justify-center">
                  <div>
                    <p class="mb-1">Available</p>
                    <div class="d-flex">
                      <v-checkbox
                        v-model="availableFilter.daytime.selected"
                        v-bind="{disabled: disabledAvailability }"
                        label="Daytime"
                        class="ma-0"
                        hide-details
                      />
                      <v-checkbox
                        v-model="availableFilter.evening.selected"
                        v-bind="{disabled: disabledAvailability}"
                        label="Evening"
                        hide-details
                        class="ma-0 ml-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border pa-3 d-flex align-center justify-center">
                  <v-autocomplete
                    v-model="typesFilter"
                    :items="typesList"
                    :item-text="(val) => { return val.title + ' id: ' + val.id }"
                    label="Type id"
                    item-value="id"
                    clearable
                  />
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border px-3 d-flex align-center justify-center">
                  <v-autocomplete
                    v-model="regionsFilter"
                    :items="regionList"
                    :item-text="(val) => { return `${val.name} id: ${val.id}`}"
                    multiple
                    label="Region"
                    item-value="id"
                    style="width: 100%;"
                  />
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              lg3
              class="pl-3 mb-3">
              <div class="d-flex px-1">
                <div class="border px-3 d-flex align-center justify-center">
                  <v-select
                    v-model="brandsFilter"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="loading"
                    :items="brands"
                    item-text="name"
                    item-value="id"
                    multiple
                    label="Brand"
                    clearable
                    style="width: 100%;"
                  />
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>

        <div style="max-width: 100%;">
          <v-data-table
            :headers="headers"
            :items="items"
            :total-items="totalItems"
            :pagination.sync="pagination"
            class="subscribe-drivers-table"
            hide-actions >
            <template
              slot="items"
              slot-scope="props">
              <td>
                <div>
                  <div>
                    <v-checkbox
                      v-if="!props.item.isSubscribed && !props.item.isInvited && props.item.brands.some(item => item.id === rideBrandId)"
                      v-model="props.item.isSelected"
                      class="mt-0"
                      color="pink"
                      hide-details
                    />
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <router-link
                    :to="`/tables/drivers/${props.item.id}` "
                    target="_blank"
                  >
                    {{ props.item.name }}
                  </router-link>
                </div>
              </td>
              <td>
                <div>{{ props.item.user.email }}</div>
              </td>
              <td>
                <div v-if="props.item.user.phone">{{ props.item.user.phone.number }}</div>
              </td>
              <td>
                <div>{{ props.item.address }}</div>
              </td>
              <td>
                <div>{{ props.item.city }}</div>
              </td>
              <td>
                <div>{{ props.item.available }}</div>
              </td>
              <td>
                <div>{{ props.item.brands && props.item.brands.map(item => item.name).join(',') }}</div>
              </td>
              <td>
                <div v-if="props.item.type">{{ props.item.type.title }}</div>
              </td>
              <td>
                <div>{{ props.item.repeatWithPassenger ? 'yes' : 'no' }}</div>
              </td>
              <td>
                <div v-if="props.item.certificates.length > 0">
                  <div
                    v-for="(certificate, key) in props.item.certificates"
                    :key="key">
                    {{ certificate.certificate.title }}
                  </div>
                </div>
              </td>
              <td>
                <div style="font-size: 13px">
                  <div v-if="props.item.tariffs.driveme">
                    <span><b>driveme:</b>&nbsp;{{props.item.tariffs.driveme}}</span>
                  </div>
                  <div v-if="props.item.tariffs.edrivers">
                    <span><b>edrivers:</b>&nbsp;{{props.item.tariffs.edrivers}}</span>
                  </div>
                  <div v-if="props.item.tariffs.rentabob">
                    <span><b>rentabob:</b>&nbsp;{{props.item.tariffs.rentabob}}</span>
                  </div>
                </div>
              </td>
              <td>
                <div>{{ props.item.reviewScore.created }}</div>
              </td>
              <td>
                <div>{{ props.item.reviewScore.averageRating }}</div>
              </td>
              <td>
                <div>{{ props.item.experience.hours }}</div>
              </td>
              <td>
                <div>{{ props.item.experience.years }}</div>
              </td>
              <td>
                <div>{{ props.item.licenseSince }}</div>
              </td>
              <td>
                <div>{{ props.item.experience.hoursThisYear }}</div>
              </td>
              <td>
                <div>{{ props.item.adminNote }}</div>
              </td>
              <td>
                <div v-if="props.item.brands.some(item => item.id === rideBrandId)">
                  <div class="d-flex">
                    <v-btn
                      v-if="props.item.isSubscribed || props.item.isInvited"
                      :loading="buttonLoader"
                      @click="onElectDriver({ rideId, driverId: props.item.id })"
                    >
                      Elect
                    </v-btn>
                    <v-btn
                      v-else
                      :loading="buttonLoader"
                      @click="onSubscribeAndElect({ rideId, driverId: props.item.id })"
                    >
                      Elect
                    </v-btn>

                    <v-btn
                      v-if="props.item.isSubscribed || props.item.isInvited"
                      :loading="buttonLoader"
                      color="warning"
                      @click="openDeleteDialogSubscription({ rideId, subscriptionId: props.item.subscriptionId })"
                    >
                      Delete
                    </v-btn>

                    <v-btn
                      v-if="props.item.isInvited"
                      :loading="buttonLoader"
                      color="primary"
                      @click="onConfirmSubscription"
                    >
                      Confirm
                    </v-btn>

                    <v-btn
                      v-if="!props.item.isInvited && !props.item.isSubscribed"
                      :loading="subscribeDriverButtonLoader"
                      color="pink"
                      class="subscribe-drivers"
                      dark
                      @click="onSubscribeDriver(rideId, [props.item.id], true); dialog = false"
                    >
                      Invite
                    </v-btn>
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-card-title>

      <v-card-actions class="pa-3 justify-space-between">
        <div class="d-flex">
          <v-btn
            v-bind="{disabled: !items.some(item => item.isSelected)}"
            color="primary"
            dark
            @click="onSubscribeDriver(rideId, items.filter(item => item.isSelected).map(item => item.id), true); dialog = false">
            Invite
          </v-btn>
          <v-btn
            v-bind="{disabled: !items.some(item => item.isSelected)}"
            color="pink"
            dark
            @click="onSubscribeDriver(rideId, items.filter(item => item.isSelected).map(item => item.id), false); dialog = false">
            Subscribe
          </v-btn>
        </div>
        <v-btn
          :disabled="loading"
          color="secondary"
          @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import request from '../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'
import moment from 'moment'
import DriverSchedule from '../../../helpers/driver-schedule'
export default {
  mixins: [request, DriverSchedule],
  props: {
    headers: {
      type: Array,
      default: () => { return [] }
    },
    regionList: {
      type: Array,
      default: () => { return [] }
    },
    regionsFilterParent: {
      type: Array,
      default: () => { return [] }
    },
    subscribedDrivers: {
      type: Array,
      default: () => { return [] }
    },
    platformCost: {
      type: Object,
      default: () => { return {} }
    },
    publicTransport: {
      type: Object,
      default: () => { return {} }
    },
    rideId: {
      type: Number,
      default: () => { return null }
    },
    onElectDriver: {
      type: Function,
      default: () => {}
    },
    onSubscribeDriver: {
      type: Function,
      default: () => {}
    },
    openDeleteDialogSubscription: {
      type: Function,
      default: () => {}
    },
    subscribeDriverButtonLoader: {
      type: Boolean,
      default: false
    },
    buttonLoader: {
      type: Boolean,
      default: false
    },
    availableDateParent: {
      type: String,
      default: ''
    },
    brands: {
      type: Array,
      default: () => []
    },
    rideBrandId: {
      type: Number,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      regionsFilter: this.regionsFilterParent,
      brandsFilter: [this.rideBrandId],
      pagination: {
        rowsPerPage: 100,
        page: 1
      },
      totalItems: 0,
      TEXT_FIELD_MAX_LENGTH,
      items: [],
      tariffFilter: [0, 50],
      typesList: [],
      typesFilter: [],
      certificatesList: [],
      certificatesFilter: [],
      availableFilter: {
        daytime: {
          selected: false,
          value: DriverSchedule.data().AVAILABILITY_TYPES.DAYTIME
        },
        evening: {
          selected: false,
          value: DriverSchedule.data().AVAILABILITY_TYPES.EVENING
        }
      },
      availableTypeFilter: [],
      availableDate: moment(this.availableDateParent).format('YYYY-MM-DD'),
      availableDateModal: false,
      disabledAvailability: false
    }
  },
  computed: {
    changeFilters () {
      const { regionsFilter, typesFilter, certificatesFilter, subscribedDrivers, availableTypeFilter, availableDate, brandsFilter } = this
      return { regionsFilter, typesFilter, certificatesFilter, subscribedDrivers, availableTypeFilter, availableDate, brandsFilter }
    }
  },
  watch: {
    'availableFilter.daytime.selected': {
      deep: true,
      handler (value) {
        if (value && !this.availableTypeFilter.includes(DriverSchedule.data().AVAILABILITY_TYPES.DAYTIME)) {
          this.availableTypeFilter = [
            ...this.availableTypeFilter,
            DriverSchedule.data().AVAILABILITY_TYPES.DAYTIME
          ]
        }

        if (!value) {
          this.availableTypeFilter = this.availableTypeFilter.filter(type => type !== DriverSchedule.data().AVAILABILITY_TYPES.DAYTIME)
        }
      }
    },
    'availableFilter.evening.selected': {
      deep: true,
      handler (value) {
        if (value && !this.availableTypeFilter.includes(DriverSchedule.data().AVAILABILITY_TYPES.EVENING)) {
          this.availableTypeFilter = [
            ...this.availableTypeFilter,
            DriverSchedule.data().AVAILABILITY_TYPES.EVENING
          ]
        }

        if (!value) {
          this.availableTypeFilter = this.availableTypeFilter.filter(type => type !== DriverSchedule.data().AVAILABILITY_TYPES.EVENING)
        }
      }
    },
    availableDate: {
      deep: true,
      handler (value) {
        if (!value) {
          this.disabledAvailability = true
          this.availableFilter.daytime.selected = false
          this.availableFilter.evening.selected = false
        } else {
          this.availableDateModal = false
          this.disabledAvailability = false
          this.requestData(this.getFilterProps())
        }
      }
    },
    changeFilters: {
      deep: true,
      handler () {
        this.requestData(this.getFilterProps())
      }
    }
  },
  methods: {
    async onSubscribeAndElect ({ rideId, driverId }) {
      const body = {
        data: {
          isAborted: false, // multiple request
          ride: rideId,
          driver: driverId,
          confirm: false
        }
      }

      await this.request('POST', '/ride-drivers', body)
      await this.onElectDriver({ rideId, driverId })
    },
    onBtnClick () {
      let filterProps = this.brandsFilter.length ? {} : {
        brands: this.brands.map(item => item.id).join(',')
      }
      const availableTime = moment(this.availableDateParent).hours()

      if (availableTime >= 4 && availableTime < 18) {
        this.availableFilter.daytime.selected = true
        filterProps = {
          ...filterProps,
          availableDay: this.availableDateParent ? moment(this.availableDateParent).format('YYYY-MM-DD') : undefined,
          availableTypes: this.AVAILABILITY_TYPES.DAYTIME
        }
      } else {
        this.availableFilter.evening.selected = true
        filterProps = {
          ...filterProps,
          availableDay: this.availableDateParent ? moment(this.availableDateParent).format('YYYY-MM-DD') : undefined,
          availableTypes: this.AVAILABILITY_TYPES.EVENING
        }
      }

      if (this.regionsFilter) {
        filterProps = { ...filterProps, regions: this.regionsFilter && this.regionsFilter.length ? this.regionsFilter.join(',') : undefined }
      }

      this.requestData({ ...this.getFilterProps(), ...filterProps })
    },
    requestData (query) {
      let body = query ? { params: query } : {}
      this.request('GET', `/drivers?pageSize=200`, body, ({ data }) => {
        if (data.data && data.data.length) {
          let subscribedDriversDrivers = {}
          let invitedDriversDrivers = {}
          this.subscribedDrivers.forEach(item => {
            if (item.confirmedAt) {
              subscribedDriversDrivers = { ...subscribedDriversDrivers, [item.driver.id]: item.id }
            } else {
              invitedDriversDrivers = { ...invitedDriversDrivers, [item.driver.id]: item.id }
            }
          })

          data.data.map((item) => {
            item.isSelected = false
            if (Object.keys(subscribedDriversDrivers).includes(item.id.toString())) {
              item.isSubscribed = true
              item.subscriptionId = subscribedDriversDrivers[item.id]
            }

            if (Object.keys(invitedDriversDrivers).includes(item.id.toString())) {
              item.isInvited = true
            }
          })
        }
        this.items = data.data
      }, (loader) => { this.loading = loader })
      let getTypesList = new Promise((resolve) => {
        this.request('GET', '/driver-types?pageSize=9999', {}, ({ data: driverTypesData }) => {
          this.typesList = driverTypesData.data
          resolve()
        })
      })
      let getCertificatesList = new Promise((resolve) => {
        this.request('GET', '/driver-certificates?pageSize=9999', {}, ({ data: driverCertsData }) => {
          this.certificatesList = driverCertsData.data.map(item => ({ ...item, obtainedAt: '' }))
          resolve()
        })
      })
      Promise.all([getTypesList, getCertificatesList]).then(() => {})
    },
    close () {
      this.dialog = false
    },
    getFilterProps () {
      let filterProps = {
        regions: this.regionsFilter && this.regionsFilter.length ? this.regionsFilter.join(',') : undefined,
        type: this.typesFilter,
        certificate: this.certificatesFilter && this.certificatesFilter.length ? this.certificatesFilter.map((elem) => { return elem.id }).join(',') : undefined,
        tariffFrom: this.tariffFilter[0],
        tariffTo: this.tariffFilter[1],
        brands: this.brandsFilter && this.brandsFilter.length ? this.brandsFilter.join(',') : null,
        availableDay: this.availableDate ? moment(this.availableDate).format('YYYY-MM-DD') : undefined,
        availableTypes: this.availableTypeFilter.length ? this.availableTypeFilter.join(',') : undefined
      }
      return filterProps
    },
    onChangeTariff () {
      this.requestData(this.getFilterProps())
    },
    onConfirmSubscription () {
      // TODO confirm invited driver
      alert('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>

  .v-card__actions {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
  }
</style>
