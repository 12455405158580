<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-btn
      v-if="stopsData.length > 0"
      class="my-4 mx-0"
      icon
      @click="swapStops"
    >
      <v-icon>swap_vert</v-icon>
    </v-btn>
    <v-timeline
      :class="`dot-before-${stopsData.length-2}-height`"
      align-top
      dense
      class="timeline-primary"
    >
      <v-timeline-item
        color="teal lighten-3"
        small
      >
        <template v-slot:icon>
          <v-btn
            id="passenger-ride-add-stop-btn"
            flat
            icon
            @click="addStop(0)">
            <v-icon
              v-if="webFontsDisabled === false"
              color="#25992e"
            >
              add_circle
            </v-icon>
            <span
              v-if="webFontsDisabled"
            >
              +
            </span>
          </v-btn>
        </template>
        <v-layout
          :class="showMenu[0] && $vuetify.breakpoint.xsOnly && showMenu[0].value ? 'focused-field' : ''"
          class="ride-date-time-input mb-3">
          <v-flex
            v-if="stopsData.length > 0"
            :class="stopsData.length > 2 ? 'stops-item' : ''"
            xs12>
            <strong>
              <v-combobox
                ref="nextFocus0"
                :id="`nextFocus0`"
                :items="addressEntries"
                :loading="addressLoading[0] != null && addressLoading[0].value"
                :value="stopsData[0].address"
                :menu-props="{ 'value': (addressEntries.length > 0 && showMenu[0] && showMenu[0].value), 'return-value': 'address', 'maxHeight': '240px', 'contentClass': 'v-list-medium' }"
                :return-object="true"
                :clearable="(showMenu[0] && showMenu[0].value) || !$vuetify.breakpoint.xsOnly"
                append-icon=""
                item-text="address"
                item-value="address"
                label="Van"
                no-filter
                no-data-text
                class="underline-input"
                @change="(e) => onChangeInput(e, 0)"
                @focus="(e) => onFocus(e, 0)"
                @blur="(e) => onBlur(e, 0)"
                @input.native="val => autocomplete(val.srcElement.value, 0)"
                @click:clear="clearInput(0)"
              />
            </strong>
          </v-flex>
        </v-layout>
      </v-timeline-item>

      <div
        v-for="(stop, index) in stopsData"
        :key="index">
        <v-timeline-item
          v-if="index > 0"
          color="teal lighten-3"
          small
        >
          <template
            v-slot:icon>
            <v-btn
              v-if="index + 1 < stopsData.length"
              flat
              icon
              @click="addStop(index + 1)">
              <v-icon
                v-if="webFontsDisabled === false"
                color="#25992e"
              >
                add_circle
              </v-icon>
              <span
                v-if="webFontsDisabled"
              >
                +
              </span>
            </v-btn>
          </template>
          <v-layout
            :class="showMenu[index] && showMenu[index].value && $vuetify.breakpoint.xsOnly ? 'focused-field' : ''"
            class="ride-date-time-input mb-3">
            <v-flex
              xs12
              class="stops-item">
              <strong>
                <v-combobox
                  :ref="`nextFocus${index}`"
                  :id="`nextFocus${index}`"
                  :value="stopsData[index].address"
                  :items="addressEntries"
                  :loading="addressLoading[index] && addressLoading[index].value"
                  :label="index + 1 < stopsData.length ? 'Tussenstop' : 'Naar'"
                  :menu-props="{ 'value': (addressEntries.length > 0 && showMenu[index] && showMenu[index].value), 'return-value': 'address', 'maxHeight': '240px', 'contentClass': 'v-list-medium' }"
                  :return-object="true"
                  :clearable="(showMenu[index] && showMenu[index].value) || !$vuetify.breakpoint.xsOnly"
                  append-icon=""
                  item-text="address"
                  item-value="address"
                  no-filter
                  no-data-text
                  class="underline-input"
                  @change="(e) => onChangeInput(e, index)"
                  @focus="(e) => onFocus(e, index)"
                  @blur="(e) => onBlur(e, index)"
                  @input.native="val => autocomplete(val.srcElement.value, index)"
                  @click:clear="clearInput(index)"
                />
              </strong>
              <div
                v-if="index + 1 < stopsData.length && (!showMenu[index] || !showMenu[index].value)"
                class="remove-button">
                <v-btn
                  id="remove-stop"
                  flat
                  icon
                  color="red"
                  @click="removeStop(index)">
                  <v-icon size="20">remove_circle</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-timeline-item>
      </div>
    </v-timeline>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import Request from '../helpers/request'
import { googleMaps } from '../helpers/google'
import SmartTimeout from 'smart-timeout'
import { BRAND } from '../constants/common'
import lodash from 'lodash'

const timeout = SmartTimeout

export default {
  components: { 'datetime': Datetime },
  mixins: [Request],
  props: {
    onStopsUpdated: {
      type: Function,
      default: () => { return {} }
    },
    onCheckCurrentLocation: {
      type: Function,
      default: () => { return {} }
    },
    stops: {
      type: Array,
      default: () => { return [] }
    },
    type: {
      type: String,
      default: 'driver'
    },
    webFontsDisabled: {
      type: Boolean,
      default: false
    },
    brandName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      addressEntries: [],
      addressLoading: [],
      showMenu: [ ],
      search: [],
      firstStopPrevious: '',
      openMenuBlock: false,
      currentValue: '',
      stopsData: []
    }
  },
  computed: {
    stopForWatch () {
      return this.stopsData[0] != null ? this.stopsData[0].address : ''
    }
  },
  watch: {
    stops: {
      handler (val) {
        if (!lodash.isEqual(val, this.stopsData)) {
          this.stopsData = lodash.cloneDeep(this.stops)
        }
      },
      deep: true
    },
    stopForWatch (to, from) {
      this.firstStopPrevious = from
    }
  },
  async beforeMount () {
    this.stopsData = await lodash.cloneDeep(this.stops)
    this.checkStopsNumber()
    let newStops = []

    newStops = this._.filter(this.stopsData, (val, index) => { if (index !== 0 && index !== this.stopsData.length - 1) { if (val.address != null && val.address.length > 0) return true } else { return true } })

    if (!newStops.filter(stop => stop.address).length) {
      this.autocomplete('', 0, true)
    }
  },
  methods: {
    onChangeStops () {
      const updatedVal = this.stopsData.map((stop, index) => {
        if (stop.address) {
          const filteredItemsByCity = this.addressEntries.filter(item => item.city && item.city !== '-')
          const existedItem = filteredItemsByCity.find(item => item.address === stop.address)
          if (existedItem) {
            stop.city = existedItem.city
          }
        }

        if (this.brandName === BRAND.EDRIVERS) {
          stop.waitingTime = stop.waitingTime || 0
        }
        return stop
      })

      this.$emit('onChange', updatedVal)

      if (this.$props.onStopsUpdated) {
        this.$props.onStopsUpdated(updatedVal)
      }
    },
    async onChangeInput (item, index) {
      if (item.place_id && this.addressEntries.map(item => item.place_id).includes(item.place_id)) {
        await this.setStopByPlaceId(index, item.place_id)
      } else {
        this.stopsData[index].address = item.address
        this.onChangeStops()
      }
      this.openMenu(index, false)
    },
    async setStopByPlaceId (index, placeId) {
      const res = await googleMaps.getAddressesByPlaceIds([placeId])
      if (res && res.length) {
        this.stopsData[index] = res[0]
        this.onChangeStops()
      }
    },
    swapStops () {
      if (this.stopsData && this.stopsData.length > 1) {
        const firstAddress = this.stopsData[0].address
        const lastAddress = this.stopsData[this.stopsData.length - 1].address
        this.stopsData[0].address = lastAddress
        this.stopsData[this.stopsData.length - 1].address = firstAddress
        this.onChangeStops()
      }
    },
    onBlur (e, index) {
      if (e && e.srcElement !== document.activeElement) {
        this.openMenu(index, false)
      }
    },
    onFocus (e, index) {
      if (index === 0 && (!this.stopsData[0] || !this.stopsData[0].address)) {
        if (this.$props.onCheckCurrentLocation) {
          this.$props.onCheckCurrentLocation()
        }
      }

      if (e) {
        this.autocomplete(this.stopsData[index].address || '', index)
        this.openMenu(index)
      }
    },
    clearInput () {
      this.openMenuBlock = true
      setTimeout(() => { this.openMenuBlock = false }, 15)
    },
    nextFocus (index) {
      this.showMenu[index].value = false

      timeout.clear('requestTimer')
      this.addressLoading[index].value = false

      if (index + 1 < this.stopsData.length) {
        let refName = 'nextFocus' + (index + 1)
        this.$refs[refName][0].focus()
      }
    },
    openMenu (index, close = true, blur = false) {
      if (!this.openMenuBlock) {
        if (!close && index === 0 && !this.stopsData[this.stopsData.length - 1].address) {
          this.fillLastAddress(this.stopsData[0].address)
        }

        setTimeout(() => {
          this.showMenu[index].value = close

          if (!close && !blur) {
            if (index === 0) {
              this.$refs[`nextFocus${index}`].blur()
            } else {
              this.$refs[`nextFocus${index}`][0].blur()
            }
            this.$vuetify.breakpoint.xsOnly && this.$store.dispatch('setToolbar', !close)
          }
        }, 5)
        this.$vuetify.goTo(0)
      }
    },
    addStop (index, noFocus = false) {
      if (index === 0 & this.stopsData.length > 0) {
        index = 1
      }

      this.addLocationStop(index)

      !noFocus && this.$refs[`nextFocus${index}`] && this.$refs[`nextFocus${index}`][0].focus()
    },
    removeAddressProps (index) {
      this.showMenu.splice(index, 1)
      this.addressLoading.splice(index, 1)
    },
    addAddressProps (index) {
      this.showMenu.splice(index, 0, { value: false })
      this.addressLoading.splice(index, 0, { value: false })
    },
    removeStop (index) {
      this.stopsData.splice(index, 1)
      this.removeAddressProps(index)
      this.onChangeStops()
    },
    addLocationStop (index) {
      this.stopsData.splice(index, 0, { address: null })
      this.addAddressProps(index)
      this.onChangeStops()
    },
    checkStopsNumber () {
      if (this.stopsData.length === 0) {
        this.addLocationStop(0)
        this.addLocationStop(1)
      } else {
        Object.keys(this.stopsData).map(key => {
          this.addAddressProps(key)
        })
      }
    },
    autocomplete (val, index, noLoader = false) {
      let body = {
        params: {
          search: val
        }
      }

      !noLoader && (this.addressLoading[index].value = true)

      // this.openMenu(index)

      timeout.clear('requestTimer')
      timeout.set('requestTimer', () => {
        this.request('GET', '/stops/address-search', body, ({ data }) => {
          const neededAddressesCount = 10

          if (neededAddressesCount > 0) {
            googleMaps.getAddressesFromGooglePlaces({ address: val,
              successCB: (googleApiAddresses) => {
                const filteredPredictions = googleApiAddresses ? googleApiAddresses
                  .filter(pred => {
                  // Need to exclude similar addresses in future interations
                    return pred
                  })
                  .slice(0, neededAddressesCount) : []
                this.addressEntries = filteredPredictions.map(item => ({ address: item.address || item.description || '', place_id: item.place_id || '' }))

                !noLoader && (this.addressLoading[index].value = false)
              },
              errorCB: () => {
                this.addressEntries = data.data.filter(item => item.address)
                !noLoader && (this.addressLoading[index].value = false)
              } })
          } else {
            this.addressEntries = data.data.filter(item => item.address)
            !noLoader && (this.addressLoading[index].value = false)
          }
        })
      }, 350)
    },
    fillLastAddress (val, from) {
      if (this.stopsData[this.stopsData.length - 1].address == null || this.stopsData[this.stopsData.length - 1].address === '' || this.stopsData[this.stopsData.length - 1].address === this.firstStopPrevious) {
        this.stopsData[this.stopsData.length - 1].address = val

        if (this.type === 'driver') {
          this.stopsData.length === 2 && this.addStop(0, true)
        }
        this.onChangeStops()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ride-date-time-input {
    margin-top: -60px !important;
}
.remove-button {
    position: absolute;
    right: 0;
    margin-top: -26px;
    /deep/ button {
      margin-right: -6px;
      margin-top: 20px;
    }
}

.remove-button-admin {
    position: absolute;
    right: 0;
    margin-top: -26px;
    margin-right: -95px;
}

.focused-field {
  position: fixed;
  height: 100%;
  background: white;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 5001;

  > div {
    position: fixed;
    top: 0px;
    left: 15px;
    right: 5px;
  }
}
.stops-item {
  height: 110px;
}
.timeline-primary {
  &::before{
    height: calc(100% - 210px) !important;
  }
}
</style>
