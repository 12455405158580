<template>
  <v-combobox
    :disabled="loading"
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    class="selected-tags"
    item-value="id"
    item-text="name"
    hide-selected
    label="Select tags"
    multiple
    small-chips
    outline
    hide-details
  >
    <template v-slot:no-data>
      <v-list-tile>
        <v-btn
          :disabled="loading"
          class="subheading"
          @click="createTagClick">
          <span class="create-tag-btn-txt">Create</span>
        </v-btn>
        <v-chip
          :color="currentRandomColor"
          :text-color="invertColor(currentRandomColor)"
          label
          small
          @click="selectColor"
        >
          {{ search }}
        </v-chip>
      </v-list-tile>
    </template>
    <template v-slot:selection="{ item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        :color="item.backgroundColor"
        :selected="selected"
        :text-color="getItemTextColor(item)"
        label
        small
      >
        <span class="pr-2">
          {{ item.name }}
        </span>
        <v-icon
          small
          @click="parent.selectItem(item)"
        >close</v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-tile-content>
        <v-text-field
          v-if="editing.id === item.id"
          v-model="editing.name"
          autofocus
          flat
          hide-details
          solo
          @click.stop
        />
        <v-chip
          v-else
          :color="item.backgroundColor"
          :text-color="getItemTextColor(item)"
          label
          small
        >
          {{ item.name }}
        </v-chip>
      </v-list-tile-content>
      <v-spacer/>
      <v-list-tile-action
        v-if="editing.id === item.id"
        @click.stop>
        <v-layout
          center
          align-center>

          <label for="color-picker">
            <v-chip
              :color="item.backgroundColor"
              :text-color="getItemTextColor(item)"
              label
              small
            />
          </label>

          <!--  -->
          <v-text-field
            id="color-picker"
            v-model="editing.backgroundColor"
            class="color-picker"
            type="color"
            hide-details
            solo
          />
        <!--  -->
        </v-layout>

      </v-list-tile-action>
      <v-list-tile-action @click.stop>
        <v-btn
          icon
          @click.stop.prevent="edit(item)"
        >
          <v-icon>{{ editing.id !== item.id ? 'edit' : 'check' }}</v-icon>
        </v-btn>
      </v-list-tile-action>
      <v-list-tile-action
        v-if="editing.id !== item.id"
        @click.stop>
        <v-btn
          icon
          @click.stop.prevent="deleteTagClick(item)"
        >
          <v-icon>remove</v-icon>
        </v-btn>
      </v-list-tile-action>
    </template>
  </v-combobox>
</template>

<script>
import request from '../../helpers/request'

export default {
  mixins: [request],
  props: {
    onChangeSelected: {
      type: Function,
      default: () => {}
    },
    onItemsChanged: {
      type: Function,
      default: () => {}
    },
    selectedTags: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      editing: { name: null, id: null },
      currentRandomColor: '',
      index: -1,
      items: [],
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0
    }
  },
  watch: {
    model (val) {
      this.$props.onChangeSelected(val.map(item => item.id))
    },
    items (val) {
      if (this.$props.onItemsChanged) {
        this.$props.onItemsChanged(val)
      }
    },
    search (val, prev) {
      if (!this.currentRandomColor) {
        this.currentRandomColor = this.generateRandomColor()
      }
    }
  },
  async beforeMount () {
    await this.init()
    this.currentRandomColor = this.generateRandomColor()
  },
  methods: {
    async init () {
      this.loading = true

      try {
        const tags = await this.fetchTags()

        if (this.$props.selectedTags && this.$props.selectedTags.length) {
          this.model = tags.filter(tag => this.$props.selectedTags.includes(tag.id))
        }

        if (this.$props.selectedTags && this.$props.selectedTags.length) {
          this.items = [
            { header: 'Select a tag or create one' },
            ...tags.filter(tag => !this.$props.selectedTags.includes(tag.id))
          ]
        } else {
          this.items = [
            { header: 'Select a tag or create one' },
            ...tags
          ]
        }
      } catch (e) {
        throw new Error(e)
      }

      this.loading = false
    },
    openColorPicker (tag) {
      console.log('🚀 ~ file: index.vue ~ line 188 ~ openColorPicker ~ tag', tag)
    },
    removeFilterTag (tag) {
      this.model = this.model.filter(item => item.id !== tag.id)
    },
    generateRandomColor () {
      let R = Math.floor(Math.random() * 256)
      let G = Math.floor(Math.random() * 256)
      let B = Math.floor(Math.random() * 256)

      let brightness = (299 * R + 587 * G + 114 * B) / 1000

      if (brightness > 125 || brightness < 130) {
        const generatedColor = `#${((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1)}`

        if (this.items.find(item => item.backgroundColor === generatedColor)) {
          return this.generateRandomColor()
        }
        return generatedColor
      } else {
        return this.generateRandomColor()
      }
    },
    padZero (str, len) {
      len = len || 2
      var zeros = new Array(len).join('0')
      return (zeros + str).slice(-len)
    },
    invertColor (hex, bwEnabled) {
      if (hex && hex.indexOf('#') === 0) {
        hex = hex.slice(1)
      }

      if (!hex || hex.length !== 6) {
        return '#000000'
      }

      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }

      let r = parseInt(hex.slice(0, 2), 16)
      let g = parseInt(hex.slice(2, 4), 16)
      let b = parseInt(hex.slice(4, 6), 16)

      if (!bwEnabled) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#000000'
          : '#FFFFFF'
      }
      // invert color components
      r = (255 - r).toString(16)
      g = (255 - g).toString(16)
      b = (255 - b).toString(16)
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
    },
    getItemTextColor (item) {
      return item.fontColor || this.invertColor(item.backgroundColor)
    },
    async createTagClick () {
      if (this.items.find(item => item.name === this.search)) {
        return
      }

      this.loading = true

      try {
        await this.createTag({ name: this.search, backgroundColor: this.currentRandomColor })
        const tags = await this.fetchTags()

        this.items = [
          { header: 'Select a tag or create one' },
          ...tags
        ]

        this.currentRandomColor = this.generateRandomColor()
        this.search = ''
        this.loading = false
      } catch (error) {
        this.loading = false
        throw new Error(error)
      }
    },
    selectColor () {
      console.log('select color')
    },
    async edit (item) {
      if (!this.editing.id) {
        this.editing = item
        return
      }

      this.loading = true

      try {
        await this.updateTag(item.id, {
          ...item,
          fontColor: this.invertColor(item.backgroundColor)
        })
        const tags = await this.fetchTags()

        this.items = [
          { header: 'Select a tag or create one' },
          ...tags
        ]

        this.editing = { id: null, name: '' }

        this.loading = false
      } catch (error) {
        this.loading = false

        throw new Error(error)
      }
    },
    async deleteTagClick (item) {
      this.loading = true

      try {
        await this.deleteTag(item.id)
        const tags = await this.fetchTags()

        this.items = [
          { header: 'Select a tag or create one' },
          ...tags
        ]
        this.loading = false
      } catch (error) {
        this.loading = false

        throw new Error(error)
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    fetchTags () {
      return new Promise((resolve, reject) => {
        this.request('GET', `/tags`, { params: { pageSize: 9999 } }, ({ data }) => {
          resolve(data.data)
        }, null, reject)
      })
    },
    createTag (data) {
      return new Promise((resolve, reject) => {
        this.request('POST', `/tags`, { data }, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    updateTag (id, data) {
      return new Promise((resolve, reject) => {
        this.request('PATCH', `/tags/${id}`, { data }, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    deleteTag (id) {
      return new Promise((resolve, reject) => {
        this.request('DELETE', `/tags/${id}`, {}, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 .subheading {
   cursor: pointer;
 }

 .create-tag-btn-txt {
   font-size: 14px;
 }

 .color-picker {
   visibility: hidden;
 }
</style>
