<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :heading="$t('message.rideOverview')"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <v-layout
              row
              justify-space-between
              wrap>
              <brands-filter
                v-if="brandList.length"
                :brands-id="filters.brandsFilter"
                :brand-list="brandList"
                :on-change-brands="onChangeBrands"
              />
              <div class="d-inline-flex">
                <v-checkbox
                  v-model="filters.isDeleted"
                  :label="`Deleted`"
                  class="mx-3"
                />
                <v-checkbox
                  v-model="filters.hasInvoice"
                  :label="`Has invoice`"
                  class="mx-3"
                  @change="item => { if (item) filters.needsInvoice = !item }"
                />
                <v-checkbox
                  v-model="filters.needsInvoice"
                  :label="`Needs invoice`"
                  class="mx-3"
                  @change="item => { if (item) filters.hasInvoice = !item }"
                />
                <v-checkbox
                  v-model="filters.statusesScheduledFilter"
                  :label="`Scheduled`"
                  class="mx-3"
                  @change="(val) => { updateStatusesGroupCheckbox(val, statusesFilterDictionary.scheduled.value) }"
                />
                <v-checkbox
                  v-model="filters.statusesActiveFilter"
                  :label="`Active`"
                  class="mx-3"
                  @change="(val) => { updateStatusesGroupCheckbox(val, statusesFilterDictionary.active.value) }"
                />
                <v-checkbox
                  v-model="filters.statusesArchiveFilter"
                  :label="`Archive`"
                  class="mx-3"
                  @change="(val) => { updateStatusesGroupCheckbox(val, statusesFilterDictionary.archive.value) }"
                />
              </div>
            </v-layout>

            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6
                md4>
                <v-autocomplete
                  v-if="regionList.length"
                  v-model="filters.regionsFilter"
                  :items="regionList"
                  :item-text="(val) => { return `${val.name} id: ${val.id}`}"
                  multiple
                  clearable
                  class="mr-3"
                  label="Region"
                  item-value="id"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4>
                <v-menu
                  ref="startAtFrom"
                  v-model="startAtFromFilterMenu"
                  :close-on-content-click="false"
                  :return-value.sync="filters.startAtFromFilter"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.startAtFromFilter"
                      v-bind="attrs"
                      label="Start at from"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="filters.startAtFromFilter"
                    :first-day-of-week="1"
                    no-title
                    scrollable
                    range
                    @change="() => { $refs.startAtFrom.save(filters.startAtFromFilter); startAtFromFilterMenu = false }"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4>
                <v-menu
                  ref="startAtTo"
                  v-model="startAtToFilterMenu"
                  :close-on-content-click="false"
                  :return-value.sync="filters.startAtToFilter"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.startAtToFilter"
                      v-bind="attrs"
                      label="Start at to"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="filters.startAtToFilter"
                    :first-day-of-week="1"
                    no-title
                    scrollable
                    range
                    @change="() => { $refs.startAtTo.save(filters.startAtToFilter); startAtToFilterMenu = false }"
                  />
                </v-menu>
              </v-flex>
            </v-layout>

            <v-layout
              row
              wrap>
              <vFlex xs3>
                <v-select
                  :items="sorting"
                  v-model="filters.sortFilter"
                  :disabled="loading"
                  item-value="name"
                  item-text="title"
                  background-color="white"
                  color="white"
                  class="bordered-input"
                />
              </vFlex>
              <vFlex xs3>
                <v-select
                  :items="statusesList"
                  v-model="filters.statusesFilter"
                  :menu-props="{ closeOnContentClick: true }"
                  :disabled="loading"
                  clearable
                  item-value="alias"
                  item-text="title"
                  label="Toon alles"
                  multiple
                  background-color="white"
                  class="bordered-input"
                  @change="(val) => { updateStatusesSelect(val) }"
                />
              </vFlex>
              <vFlex xs3>
                <v-select
                  :items="Object.values(statusesFilterDictionary)"
                  v-model="filters.statusesGroupFilter"
                  :menu-props="{ closeOnContentClick: true }"
                  :disabled="loading"
                  clearable
                  item-value="value"
                  item-text="text"
                  label="Toon groepen"
                  multiple
                  background-color="white"
                  class="bordered-input"
                  @change="(val) => { updateStatusesGroupSelect(val) }"
                />
              </vFlex>
              <vFlex xs3>
                <drivers-input
                  :disabled="loading"
                  v-model="filters.driverFilter"
                  clearable
                  hide-details/>
              </vFlex>
              <vFlex xs3>
                <passengers-input
                  :disabled="loading"
                  v-model="filters.passengerFilter"
                  :brand-list="[filters.brandsFilter]"
                  clearable
                  hide-details/>
              </vFlex>
            </v-layout>
            <v-layout
              row
              wrap>
              <v-flex xs3>
                <v-btn
                  color="primary"
                  @click="$emit('createRide')">Create</v-btn>
              </v-flex>
              <v-icon
                v-if="currentView === VIEW.TABLE"
                @click="setCurrentView(VIEW.ROW)"
              >
                table_rows_icon
              </v-icon>
              <v-icon
                v-else
                @click="setCurrentView(VIEW.TABLE)"
              >
                table_chart_icon
              </v-icon>
              <v-text-field
                v-model="filters.search"
                class="mr-3"
                label="Search"
                outline
                @input="debouncedSearch"/>

              <tags-select
                :selected-tags="filters.tagsFilter"
                :on-change-selected="onChangeSelectedTags"/>
            </v-layout>

            <v-layout
              v-if="loading"
              row
              class="mt-5 pt-5 pb-5 p-relative"
              wrap>
              <rotate-square2 />
            </v-layout>

            <div v-else-if="currentView === VIEW.TABLE">
              <v-btn
                color="primary"
                class="mr-3 mb-3"
                @click="scrollRight"
              >-></v-btn>
              <v-data-table
                :headers="headers"
                :items="ridesList"
                class="elevation-1"
                hide-actions
              >
                <template v-slot:headers="props">
                  <tr>
                    <th
                      v-for="header in props.headers"
                      :key="header.text"
                      :class="['column sortable', filters.sortDirFilter === 'desc' ? 'desc' : 'asc', header.value === filters.sortFilter ? 'active' : '']"
                      @click="changeTableSort(header)"
                    >
                      <v-icon
                        v-if="header.customSortable"
                        small>arrow_upward</v-icon>
                      {{ header.text }}
                    </th>
                  </tr>
                </template>
                <template v-slot:items="props">
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    {{ props.item.id }}
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    <span
                      v-for="(tag, index) in props.item.tags"
                      :key="index">
                      <v-chip
                        :color="tag.backgroundColor"
                        :text-color="tag.fontColor"
                        label
                        small
                      >
                        {{ tag.name }}
                      </v-chip>
                    </span>
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    {{ props.item.brand.name }}
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    {{ gFunc.momentDate(props.item.startAt) }}
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    {{ gFunc.momentTime(props.item.startAt) }}
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    <span v-if="props.item.prebookedWaitingTime">{{ props.item.prebookedWaitingTime.time }}</span>
                    <span v-else>
                      ...
                    </span>
                  </td>
                  <td>
                    <a
                      v-if="props.item.driver"
                      :href="`/tables/drivers/${props.item.driver.id}`"
                    >
                      <span class="mr-1">
                        {{ props.item.driver.name }}
                      </span>
                    </a>
                    <span v-else>...</span>
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    <span v-if="props.item.stats && props.item.stats.subscriptions">
                      {{ props.item.stats.subscriptions }}
                    </span>
                    <span v-else>
                      0
                    </span>
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    <div
                      v-for="(region, index) in props.item.regions"
                      :key="index"
                    >
                      {{ region.name }},
                    </div>
                  </td>
                  <td
                    class="c-pointer address-cell"
                    @click="$emit('openEditDialog', props.item)">
                    <span v-if="props.item.stops && props.item.stops.length">
                      <span
                        v-for="(addressItem, index) in removeCountryFromAddress(props.item.stops[0].address).split(',')"
                        :key="index"
                      >
                        {{ addressItem }} <i v-if="index !== removeCountryFromAddress(props.item.stops[0].address).split(',').length - 1">,</i>
                        <br>
                      </span>
                    </span>
                    <span v-else>
                      ...
                    </span>
                  </td>
                  <td
                    class="c-pointer address-cell"
                    @click="$emit('openEditDialog', props.item)">
                    <span v-if="props.item.stops && props.item.stops.length">
                      <span
                        v-for="(addressItem, index) in removeCountryFromAddress(props.item.stops[props.item.stops.length - 1].address).split(',')"
                        :key="index"
                      >
                        {{ addressItem }} <i v-if="index !== removeCountryFromAddress(props.item.stops[props.item.stops.length - 1].address).split(',').length - 1">,</i>
                        <br>
                      </span>
                    </span>
                    <span v-else>
                      ...
                    </span>
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    {{ props.item.endAt && gFunc.momentTime(props.item.endAt) }}
                  </td>
                  <td>
                    <a
                      v-if="props.item.passenger"
                      :href="`/tables/passengers/${props.item.passenger.id}`"
                    >
                      <span class="mr-1">
                        {{ props.item.passenger.name }}
                      </span>
                    </a>

                    <span v-else>...</span>
                  </td>
                  <td
                    class="c-pointer"
                    @click="$emit('openEditDialog', props.item)">
                    {{ props.item.status.alias }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :disabled="showLoading"
                          class="mr-2"
                          v-on="on"
                          @click="$emit('openEditDialog', props.item, true)"
                        >
                          file_copy
                        </v-icon>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>

                    <v-tooltip
                      v-if="filters.isDeleted"
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="mr-2"
                          dark
                          v-on="on"
                          @click="$emit('restoreRide', props.item)"
                        >
                          restore
                        </v-icon>
                      </template>
                      <span>Restore</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="mr-2"
                          dark
                          v-on="on"
                          @click="$emit('openDeleteDialog', props.item)"
                        >
                          delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>

            </div>

            <v-layout
              v-else
              row
              wrap>
              <app-card
                v-for="(ride, index) in ridesList"
                :key="index"
                :heading="`Ride ${ride.id} Overview`"
                :footer="true"
                col-classes="xs4"
                custom-classes="mb-0"
              >
                <template slot="footer">
                  <v-btn
                    :disabled="showLoading"
                    color="primary"
                    class="mr-3"
                    @click="$emit('openEditDialog', ride)"
                  >Edit</v-btn>
                  <v-btn
                    v-if="filters.isDeleted"
                    color="orange"
                    @click="$emit('restoreRide', ride)">Restore</v-btn>
                  <v-btn
                    v-else

                    color="orange"
                    @click="$emit('openDeleteDialog', ride)">Delete</v-btn>
                </template>
                <div
                  v-if="ride.tags.length"
                  class="my-2">
                  <span
                    v-for="(tag, index) in ride.tags"
                    :key="index">
                    <v-chip
                      :color="tag.backgroundColor"
                      :text-color="tag.fontColor"
                      label
                      small
                    >
                      {{ tag.name }}
                    </v-chip>
                  </span>
                </div>
                <div class="my-2">
                  Brand: {{ ride.brand.name }}</div>
                <div class="my-2">
                  Start at: {{ gFunc.momentDate(ride.startAt) }} - {{ gFunc.momentTime(ride.startAt) }}</div>
                <div class="my-2">
                  Duration: {{ ride.duration ? ride.duration.time : 'Not set' }}</div>
                <div class="my-2">
                  <div>Status: {{ ride.status.alias }}</div>
                </div>
                <div
                  v-if="ride.passenger != null"
                  class="my-2"
                >
                  Passenger:
                  <router-link
                    :to="`/tables/passengers/${ride.passenger.id}`"
                  >
                    {{ ride.passenger.name }} id: {{ ride.passenger.id }}
                  </router-link>
                </div>

                <div
                  v-if="ride.driver != null"
                  class="my-2"
                >
                  Driver:
                  <router-link
                    :to="`/tables/drivers/${ride.driver.id}`"
                  >
                    {{ ride.driver.name }} {{ ride.driver.name }} id: {{ ride.driver.id }}
                  </router-link>
                </div>

                <div
                  v-else
                  class="my-2">
                  No driver yet
                </div>
                <div class="my-2">
                  Remark: {{ ride.remark }}
                </div>
                <div class="my-2">
                  Admin note: {{ ride.adminNote }}
                </div>
                <div
                  v-if="ride.status.alias === 'subscribed' && ride.stats && ride.stats.subscriptions "
                  class="my-2"
                >
                  Subscriptions: {{ ride.stats.subscriptions }}
                </div>

                <div v-if="ride.regions.length">
                  Regions:
                  <div
                    v-for="(region, index) in ride.regions"
                    :key="index"
                  >
                    {{ region.name }},
                  </div>
                </div>

                <div
                  v-if="Boolean(ride.passenger.reference)"
                  class="my-2"
                >
                  Reference: {{ ride.passenger.reference }}
                </div>
                <div
                  v-if="ride.stops.length > 0"
                  class="my-3">
                  <div class="my-2"><b>Stops:</b></div>
                  <div
                    v-for="(stop, index) in ride.stops"
                    :key="index">
                    {{ index +1 }} stop: {{ removeCountryFromAddress(stop.address) }}
                  </div>
                </div>
              </app-card>
            </v-layout>
            <v-btn
              v-if="totalRides > filters.pageSize && !loading"
              color="primary"
              @click="loadMoreClick">Load more</v-btn>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import RideOverviewMixin from './RideOverviewMixin.vue'
import StatusList from '../../../mixins/StatusList.vue'
import TagsSelect from 'Components/TagsSelect'
import BrandsFilter from 'Components/BrandsFilter'
import { removeCountryFromAddress } from '../../../helpers/string'
import moment from 'moment-timezone'
import PassengersInput from '../../../modules/components/PassengersInput.vue'
import DriversInput from '../../../modules/components/DriversInput.vue'


const PAGE_SIZE_INIT = 200
const VIEW = {
  TABLE: 'table',
  GRID: 'crid'
}

export default {
  components: { TagsSelect, BrandsFilter, PassengersInput, DriversInput },
  mixins: [RideOverviewMixin, StatusList],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    statusesList: {
      type: Array,
      default: () => { return [] }
    },
    getRidesWithFilters: {
      type: Function,
      default: () => { return [] }
    },
    ridesList: {
      type: Array,
      default: () => { return [] }
    },
    totalRides: {
      type: Number,
      default: 0
    },
    brandList: {
      type: Array,
      default: () => { return [] }
    },
    regionList: {
      type: Array,
      default: () => { return [] }
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    filterProps: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      moment,
      filters: {
        pageSize: PAGE_SIZE_INIT,
        sortFilter: null,
        sortDirFilter: null,
        regionsFilter: null,
        startAtFromFilter: null,
        startAtToFilter: null,
        isDeleted: false,
        hasInvoice: false,
        needsInvoice: false,
        brandsFilter: null,
        tagsFilter: null,
        statusesFilter: null,
        statusesGroupFilter: null,
        statusesScheduledFilter: null,
        statusesActiveFilter: null,
        statusesArchiveFilter: null,
        passengerFilter: null,
        driverFilter: null,
        search: ''
      },
      statusesFilterDictionary: {
        scheduled: {
          text: 'Scheduled',
          value: 'scheduled'
        },
        active: {
          text: 'Active',
          value: 'active'
        },
        archive: {
          text: 'Archive',
          value: 'archive'
        }
      },
      sorting: [
        {
          title: 'Start at',
          name: 'startAt',
          dir: 'desc'
        },
        {
          title: 'Status',
          name: 'status',
          dir: 'desc'
        },
        {
          title: 'ID',
          name: 'id',
          dir: 'desc'
        }
      ],
      webFontsDisabled: false,
      startAtFromFilterMenu: false,
      startAtToFilterMenu: false,
      currentView: VIEW.TABLE,
      VIEW,
      headers: [
        {
          text: 'NR',
          align: 'start',
          value: 'id',
          sortable: false,
          customSortable: true
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: false
        },
        {
          text: 'Brand',
          value: 'brand',
          sortable: false
        },
        {
          text: 'Date',
          value: 'startAt',
          sortable: false,
          customSortable: true
        },
        {
          text: 'Time',
          value: 'startAt',
          sortable: false,
          customSortable: true
        },
        {
          text: 'Wait',
          value: 'prebookedWaitingTime',
          sortable: false
        },
        {
          text: 'Driver',
          value: 'driver',
          sortable: false
        },
        {
          text: 'Subscr',
          value: 'stats',
          sortable: false
        },
        {
          text: 'Region',
          value: 'regions',
          sortable: false
        },
        {
          text: 'Start address',
          value: 'stops',
          sortable: false
        },
        {
          text: 'End address',
          value: 'stops',
          sortable: false
        },
        {
          text: 'End time',
          value: 'endtAt',
          sortable: false
        },
        {
          text: 'Customer',
          value: 'passenger',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          customSortable: true
        },
        {
          text: '',
          value: '',
          sortable: false
        }
      ]
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.updateParams()
      }
    },
    statusesList: {
      deep: true,
      handler () {
        this.beforeMountFunction()
      }
    }
  },
  beforeMount () {
    this.beforeMountFunction()
  },
  mounted () {
    this.webFontsDisabled = this.isWebFontsDisabled()
    this.updateStatusesGroupSelect(this.filters.statusesGroupFilter, true)

    if (this.webFontsDisabled) {
      const materialIcons = document.getElementsByClassName('material-icons')

      if (materialIcons.length) {
        for (let i = 0; i < materialIcons.length; i++) {
          const el = materialIcons[i]
          if (el.innerHTML === 'arrow_drop_down') {
            el.innerHTML = '&#709;'
          }
        }
      }
    }
  },
  methods: {
    removeCountryFromAddress,
    updateStatusesGroupCheckbox (val, statusGroup) {
      this.filters.statusesGroupFilter = this.filters.statusesGroupFilter ? this.filters.statusesGroupFilter : []
      if (val) {
        this.filters.statusesGroupFilter.push(statusGroup)
      } else {
        this.filters.statusesGroupFilter = this.filters.statusesGroupFilter.filter(item => item !== statusGroup)
      }
      const statusesGroupFilter = this.filters.statusesGroupFilter && this.filters.statusesGroupFilter.length && this.filters.statusesGroupFilter.map(item => this.getStatusesForGroup(item)).join(',').split(',')
      this.filters.statusesFilter = statusesGroupFilter && statusesGroupFilter.length ? statusesGroupFilter : null
    },
    updateStatusesGroupSelect (val, isCallFromCode = false) {
      this.filters.statusesScheduledFilter = val && val.length && val.includes(this.statusesFilterDictionary.scheduled.value)
      this.filters.statusesActiveFilter = val && val.length && val.includes(this.statusesFilterDictionary.active.value)
      this.filters.statusesArchiveFilter = val && val.length && val.includes(this.statusesFilterDictionary.archive.value)

      if (!isCallFromCode) {
        const statusesGroupFilter = val && val.length && val.map(item => this.getStatusesForGroup(item)).join(',').split(',')
        this.filters.statusesFilter = statusesGroupFilter && statusesGroupFilter.length ? statusesGroupFilter : null
      }
    },
    updateStatusesSelect (val) {
      if (this.filters.statusesGroupFilter && this.filters.statusesGroupFilter.length) {
        this.filters.statusesGroupFilter = this.filters.statusesGroupFilter.filter(item => {
          const statusesGroup = this.getStatusesForGroup(item).split(',')
          let isContain = false
          for (var i = 0; i < statusesGroup.length; i++) {
            if (!val.some(itemVal => itemVal.indexOf(statusesGroup[i]) > -1)) {
              isContain = true
            }
          }

          return !isContain
        })

        this.updateStatusesGroupSelect(this.filters.statusesGroupFilter, true)
      }
    },
    beforeMountFunction () {
      if (this.statusesList) {
        const updatedProps = {}
        const _filters = { ...this.filters }

        if (this.$route.query.pageSize) {
          _filters.pageSize = parseInt(this.$route.query.pageSize)
          updatedProps.pageSize = parseInt(this.$route.query.pageSize)
        }

        if (this.$route.query.search) {
          _filters.search = this.$route.query.search
          updatedProps.search = this.$route.query.search
        }

        if (this.$route.query.sort) {
          _filters.sortFilter = this.$route.query.sort
          updatedProps.sort = this.$route.query.sort
        } else {
          _filters.sortFilter = this.sorting[0].name
          updatedProps.sort = this.sorting[0].name
        }

        if (this.$route.query.sortDir) {
          _filters.sortDirFilter = this.$route.query.sortDir
          updatedProps.sortDir = this.$route.query.sortDir
        } else {
          _filters.sortDirFilter = this.sorting[0].dir
          updatedProps.sortDir = this.sorting[0].dir
        }

        if (this.$route.query.passenger) {
          _filters.passengerFilter = this.$route.query.passenger
          updatedProps.passenger = this.$route.query.passenger
        }

        if (this.$route.query.driver) {
          _filters.driverFilter = this.$route.query.driver
          updatedProps.driver = this.$route.query.driver
        }

        if (this.$route.query.tags) {
          _filters.tagsFilter = this.$route.query.tags.split(',').map(tag => parseInt(tag))
          updatedProps.tags = this.$route.query.tags
        }

        if (this.$route.query.brands) {
          _filters.brandsFilter = this.$route.query.brands.split(',').map(brand => parseInt(brand))
          updatedProps.brands = this.$route.query.brands
        }

        if (this.$route.query.status) {
          _filters.statusesFilter = this.$route.query.status.split(',')
          updatedProps.status = this.$route.query.status
        }

        if (this.$route.query.statusGroup) {
          _filters.statusesGroupFilter = this.$route.query.statusGroup.split(',')
          updatedProps.statusGroup = this.$route.query.statusGroup
        }

        if (this.$route.query.regions) {
          _filters.regionsFilter = this.$route.query.regions.split(',').map(region => parseInt(region))
          updatedProps.regions = this.$route.query.regions
        }

        if (this.$route.query.startAtFrom) {
          _filters.startAtFromFilter = this.$route.query.startAtFrom
          updatedProps.startAtFrom = this.timeStamp(this.$route.query.startAtFrom)
        }

        if (this.$route.query.startAtTo) {
          _filters.startAtToFilter = this.$route.query.startAtTo
          updatedProps.startAtTo = this.timeStamp(this.$route.query.startAtTo)
        }

        if (this.$route.query.deleted) {
          _filters.isDeleted = Boolean(parseInt(this.$route.query.deleted))
          updatedProps.deleted = this.$route.query.deleted
        }

        if (this.$route.query.hasInvoice) {
          _filters.hasInvoice = Boolean(parseInt(this.$route.query.hasInvoice))
          updatedProps.hasInvoice = this.$route.query.hasInvoice
        }

        if (this.$route.query.needsInvoice) {
          _filters.needsInvoice = Boolean(parseInt(this.$route.query.needsInvoice))
          updatedProps.needsInvoice = this.$route.query.needsInvoice
        }

        this.filters = _filters

        this.$emit('updateFilterProps', updatedProps)
      }
    },
    scrollRight () {
      var table = document.getElementsByClassName('v-table__overflow')[0]
      table.scrollLeft = table.offsetWidth
    },
    changeTableSort (headerColumn) {
      if (!headerColumn.customSortable) {
        return null
      }

      this.filters = {
        ...this.filters,
        sortFilter: headerColumn.value,
        sortDirFilter: this.filters.sortDirFilter === 'desc' ? 'asc' : 'desc'
      }
    },
    onChangeBrands (brandsIds) {
      this.filters.brandsFilter = brandsIds
    },
    onChangeSelectedTags (selectedTagIds) {
      this.filters.tagsFilter = selectedTagIds
    },
    debouncedSearch: _debounce(function (value) {
      this.filters.search = value
      this.$props.getRidesWithFilters({ search: value })
    }, 300),
    setCurrentView (view) {
      this.currentView = view
    },
    updateParams () {
      let params = {}

      let updatedRouteQuery = { ...this.$route.query }

      if (this.filters.pageSize) {
        params.pageSize = this.filters.pageSize
        updatedRouteQuery = { ...updatedRouteQuery, pageSize: this.filters.pageSize }
      }

      if (this.filters.search) {
        params.search = this.filters.search
        updatedRouteQuery = { ...updatedRouteQuery, search: this.filters.search }
      } else {
        delete updatedRouteQuery.search
        delete params.search
      }

      if (this.filters.sortFilter) {
        params.sort = this.filters.sortFilter
        updatedRouteQuery = { ...updatedRouteQuery, sort: this.filters.sortFilter }
      }

      if (this.filters.sortDirFilter) {
        params.sortDir = this.filters.sortDirFilter
        updatedRouteQuery = { ...updatedRouteQuery, sortDir: this.filters.sortDirFilter }
      }

      if (this.filters.driverFilter) {
        const driverId = this.filters.driverFilter.id || this.filters.driverFilter
        params.driver = driverId
        updatedRouteQuery = { ...updatedRouteQuery, driver: driverId }
      } else {
        delete updatedRouteQuery.driver
        delete params.driver
      }

      if (this.filters.passengerFilter) {
        const passengerId = this.filters.passengerFilter.id || this.filters.passengerFilter
        params.passenger = passengerId
        updatedRouteQuery = { ...updatedRouteQuery, passenger: passengerId }
      } else {
        delete updatedRouteQuery.passenger
        delete params.passenger
      }

      if (this.filters.tagsFilter && this.filters.tagsFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, tags: this.filters.tagsFilter.join(',') }
        params.tags = this.filters.tagsFilter.join(',')
      } else {
        delete updatedRouteQuery.tags
        delete params.tags
      }

      if (this.filters.statusesFilter && this.filters.statusesFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, status: this.filters.statusesFilter.join(',') }
        params.status = this.filters.statusesFilter.join(',')
      } else {
        delete updatedRouteQuery.status
        delete params.status
      }

      if (this.filters.statusesGroupFilter && this.filters.statusesGroupFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, statusGroup: this.filters.statusesGroupFilter.join(',') }
        params.statusGroup = this.filters.statusesGroupFilter.join(',')
      } else {
        delete updatedRouteQuery.statusGroup
        delete params.statusGroup
      }

      if (this.filters.brandsFilter && this.filters.brandsFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, brands: this.filters.brandsFilter.join(',') }
        params.brands = this.filters.brandsFilter.join(',')
      } else {
        delete updatedRouteQuery.brands
        delete params.brands
      }

      if (this.filters.regionsFilter && this.filters.regionsFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, regions: this.filters.regionsFilter.join(',') }
        params.regions = this.filters.regionsFilter.join(',')
      } else {
        delete updatedRouteQuery.regions
        delete params.regions
      }

      if (this.filters.startAtFromFilter) {
        updatedRouteQuery = { ...updatedRouteQuery, startAtFrom: this.filters.startAtFromFilter }
        params.startAtFrom = this.timeStamp(this.filters.startAtFromFilter)
      } else {
        delete updatedRouteQuery.startAtFrom
        delete params.startAtFrom
      }

      if (this.filters.startAtToFilter) {
        updatedRouteQuery = { ...updatedRouteQuery, startAtTo: this.filters.startAtToFilter }
        params.startAtTo = this.timeStamp(this.moment(this.filters.startAtToFilter).set('hours', 24))
      } else {
        delete updatedRouteQuery.startAtTo
        delete params.startAtTo
      }

      if (this.filters.isDeleted) {
        updatedRouteQuery = { ...updatedRouteQuery, deleted: this.filters.isDeleted ? 1 : 0 }
        params.deleted = this.filters.isDeleted ? 1 : 0
      } else {
        delete updatedRouteQuery.deleted
        delete params.deleted
      }

      if (this.filters.hasInvoice) {
        updatedRouteQuery = { ...updatedRouteQuery, hasInvoice: this.filters.hasInvoice ? 1 : 0 }
        params.hasInvoice = this.filters.hasInvoice ? 1 : 0
      } else {
        delete updatedRouteQuery.hasInvoice
        delete params.hasInvoice
      }

      if (this.filters.needsInvoice) {
        updatedRouteQuery = { ...updatedRouteQuery, needsInvoice: this.filters.needsInvoice ? 1 : 0 }
        params.needsInvoice = this.filters.needsInvoice ? 1 : 0
      } else {
        delete updatedRouteQuery.needsInvoice
        delete params.needsInvoice
      }

      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)

      this.$store.dispatch('setAdminRideOverviewFilters', updatedRouteQuery)

      this.$emit('updateFilterProps', params)
    },
    loadMoreClick () {
      this.filters.pageSize = this.filters.pageSize + PAGE_SIZE_INIT
      this.updateParams()
    },
    getStatusesForGroup (groupAlias) {
      if (this.statusesList.length) {
        let statuses = []
        switch (groupAlias) {
          case this.statusesFilterDictionary.scheduled.value:
            statuses = this.statusesList.filter(item => item.sequence > this.rideStatus.retracted.sequence && item.sequence <= this.rideStatus.elected.sequence && item.sequence != this.rideStatus.failed.sequence)
            break
          case this.statusesFilterDictionary.active.value:
            statuses = this.statusesList.filter(item => item.sequence > this.rideStatus.elected.sequence && item.sequence < this.rideStatus.ended.sequence)
            break
          case this.statusesFilterDictionary.archive.value:
            statuses = this.statusesList.filter(item => item.sequence >= this.rideStatus.ended.sequence && item.sequence <= this.rideStatus.declared.sequence)
            break
          default:
            break
        }

        return statuses.map(item => item.alias).join(',')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper1 {
    overflow-x: scroll;
    height: 20px;
  }

  .div1 {
    width: 1000px;
    height: 20px;
  }

  .address-cell {
    white-space: nowrap;
  }
</style>
