<template>
  <v-dialog
    v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        color="secondary"
        class="ma-0 white--text full-width"
        v-on="on"
      >
        <v-icon class="mr-2">
          toc
        </v-icon>
        Sent messages
      </v-btn>
    </template>
    <v-card class="elevation-0 pa-3">
      <v-card-title>
        <span class="headline">Sent messages</span>
      </v-card-title>

      <v-card-title class="pa-1">
        <div class="pa-3 mb-4 full-width">
          <v-layout wrap>
            <!-- <v-flex
              xs12
              sm6
              lg3>
              <v-text-field
                v-model="filters.createdAtFilter"
                type="datetime-local"
                class="text-field-mobile custom-date-field"
                label="Arrive at:"
                outline
                hide-details
              />
            </v-flex> -->
          </v-layout>
        </div>

          <v-data-table
            :pagination.sync="pagination"
            :headers="headers"
            :items="items"
            class="elevation-1 full-width"
            hide-actions
            hide-default-header
            hide-default-footer>
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', filters.sortIsDescFilter ? 'desc' : 'asc', header.value === filters.sortFilter ? 'active' : '']"
                  @click="changeTableSort(header)"
                >
                  <v-icon
                    v-if="header.customSortable"
                    small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>

            <template
              slot="items"
              slot-scope="props">
              <td class="text-center">
                <div>
                  <router-link 
                    target="_blank"
                    :to="`/tables/messages?messageId=${props.item.id}`"
                  >
                    {{ props.item.id }}
                  </router-link>
                </div>
              </td>
              <td class="text-center">
                <div>{{ props.item.messageConfig.type }}</div>
              </td>
              <td class="text-center">
                <div>{{ props.item.recipient }}</div>
              </td>
              <td class="text-center">
                <div>{{ props.item.messageConfig.transport }}</div>
              </td>
              <td class="text-center">
                <div>{{ props.item.transportMessageStatus }}</div>
              </td>
              <td class="text-center">
                <div>{{ gFunc.momentDateTime(props.item.createdAt)  }}</div>
              </td>
              <td class="text-center">
                <div>{{ gFunc.momentDateTime(props.item.modifiedAt)  }}</div>
              </td>
              <td class="text-center">
                <div
                  v-for="(item, index) in props.item.history"
                  :key="index">
                  {{ gFunc.momentDateTime(item.occurredAt) }} &nbsp;<b> : </b>&nbsp;{{ item.description }}
                </div>
              </td>
            </template>
          </v-data-table>
      </v-card-title>

      <v-card-actions class="pa-3 justify-end">
        <v-btn
          color="secondary"
          @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import request from '../../../helpers/request'

export default {
  mixins: [request],
  props: {
    rideId: {
      type: Number,
      default: () => { return null }
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      items: [],
      headers: [ { text: 'Id ', sortable: false, customSortable: true, value: "id", align: "center" }, { text: 'Type', sortable: false, align: "center" },{ text: 'Recipient', sortable: false, align: "center" }, { text: 'Transport', sortable: false, align: "center" }, { text: 'Status', sortable: false, align: "center" }, { text: 'Created At', customSortable: true, value: "createdAt", align: "center" }, { text: 'Modified At', customSortable: true, value: "modifiedAt", align: "center" }, { text: 'History', sortable: false, align: "center" } ],
      filters: {
        sortFilter: null,
        sortIsDescFilter: false,
        createdAtFilter: null,
        modifiedAtFilter: null,
      }
    }
  },
  computed: {
    pagination () {
      return {
        rowsPerPage: 100,
        page: 1,
        sortBy: this.filters.sortFilter,
        descending: this.filters.sortIsDescFilter
      }
    }
  },
  async beforeMount () {
    const { data } = await this.request('GET', `/rides/${this.rideId}/sent-messages`)
    this.items = data
  },
  watch: {
    filters: {
      async handler (val) {
        let body = { params: {} }
        
        if (this.filters.createdAtFilter) {
          body.params.createdAt = this.gFunc.timeStamp(this.filters.createdAtFilter)
        }

        if (this.filters.modifiedAtFilter) {
          body.params.modifiedAt = this.gFunc.timeStamp(this.filters.modifiedAtFilter)
        }

        const { data } = await this.request('GET', `/rides/${this.rideId}/sent-messages`, body)
        this.items = data
      },
      deep: true
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
    changeTableSort (headerColumn) {
      if (!headerColumn.customSortable) {
        return null
      }

      this.filters = {
        ...this.filters,
        sortFilter: headerColumn.value,
        sortIsDescFilter: !this.filters.sortIsDescFilter
      }
    }
  }
}
</script>